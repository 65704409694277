<!--
  运行页另存为
-->
<template>
  <div class="nextSaveReport">
    <div class="nextSaveReport_center">
      <div class="center_header">
        <div class="header_left">
          {{ $t("button_partner_workspace_saveas") }}
        </div>
        <div class="header_right" @click="nextSaveReportCancel">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-chahao"></use>
          </svg>
        </div>
      </div>
      <div class="center_center">
        <el-form ref="form" :model="form" label-width="">
          <el-form-item :label="$t('label.nameofreport')">
            <el-input v-model="form.reportLabel"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.remarkofreport')">
            <el-input
              type="textarea"
              :rows="4"
              placeholder
              v-model="form.reportNote"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('vue_label_report_savelocation')">
            <el-select
              :placeholder="$t('vue_label_report_selectfolder')"
              v-model="form.location"
            >
              <el-option
                v-for="(item, index) in allFolderDatas"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="center_footer">
        <el-button @click="nextSaveReportCancel" :loading="loadings">{{
          $t("button_partner_cancel")
        }}</el-button>
        <el-button @click="nextSaveReportConfirm" :loading="loadings">{{
          $t("label.ems.confirm")
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getFolder, saveCopyReport } from "./api.js";

export default {
  props: ["nextSaveReportTo", "reportNameNote"],
  data() {
    return {
      form: {
        reportLabel: "",
        reportNote: "",
        location: "", //默认选中的保存位置（不用管，默认）
      },
      allFolders: [],
      allFolderDatas: [],
      loadings: false,
    };
  },
  watch: {
    nextSaveReportTo: function () {
      this.getFolders();
      this.form.reportLabel =
        this.reportNameNote.reportLabel +
        this.$i18n.t("vue_label_norm_duplicate");
      this.form.reportNote = this.reportNameNote.description;
    },
  },
  methods: {
    // 获取报表文件夹
    async getFolders() {
      var params = {
        searchKeyWord: "",
      };
      let dataObject = await getFolder(params);
      let data = dataObject.data;
      this.allFolders = [];
      data.topFolderList.map((item) => {
        this.allFolders.push(item);
      });
      data.noTopFolderList.map((item) => {
        this.allFolders.push(item);
      });
      this.form.location = this.allFolders.find(
        (item) => item.name === this.$i18n.t("message.myselfreport")
      ).id;
      // 对所有可选择的文件夹做权限：可编辑或者是不可编辑但是是我的个人自定义或未归档
      this.allFolderDatas = this.allFolders.filter(
        (item) =>
          item.isedit === "true" ||
          (item.isedit === "false" &&
            (item.name === this.$i18n.t("message.myselfreport") ||
              item.name === this.$i18n.t("label.notfiledpublicreport")))
      );
    },
    // 另存为
    async nextSaveReportConfirm() {
      this.loadings = true;
      let params = {
        description: this.form.reportNote,
        id: this.reportNameNote.reportId,
        name: this.form.reportLabel,
        reportCode: "",
        reportFolderId: this.form.location,
        saveAs: true,
      };
      await saveCopyReport(params);
      this.$emit("nextSaveReportCancel");
      this.$message.success(
        this.$i18n.t("vue_label_report_notice_copy_success")
      );
      this.$router.push({
        path: "/reportObject/reportList",
      });
    },
    // 取消弹框
    nextSaveReportCancel() {
      this.$emit("nextSaveReportCancel");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/bouncedForm.scss";

.nextSaveReport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.4);
  min-height: 500px;
  overflow: auto;
  .nextSaveReport_center {
    width: 540px;
    background: white;
    position: relative;
    margin: 5% auto; /*水平居中*/
    top: 70px; /*偏移*/
    transform: translateY(-50% -50%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    .center_header {
      padding: 17px 20px 15px 20px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dcdcdc;
      .header_left {
        color: #080707;
      }
      .header_right {
        cursor: pointer;
        img,
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    .center_center {
      padding: 8px 20px;
    }
    .center_footer {
      height: 60px;
      padding: 0 20px;
      border-top: 1px solid #dcdcdc;
      background: #f4f6f9;
      border-radius: 0 0 8px 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-button {
        padding: 7px 14px;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
      }
      .el-button:first-child {
        color: #5780b3;
        border: 1px solid #dcdcdc;
        background: #fff;
      }
      .el-button:last-child {
        color: #fff;
        background: #1b5297;
        border-color: #1b5297;
      }
    }
  }
}
</style>