<!--
  生成的图表
-->
<template>
  <div class="generateChart data_echart">
    <!-- 设置图标 -->
    <div class="set_img" @click="isShowComponentsEditorAlert = true" v-if="!appreport">
      <svg class="icon" v-if="isShowComponentsEditorAlert" aria-hidden="true">
        <use href="#icon-sett2"></use>
      </svg>
      <svg class="icon" v-if="!isShowComponentsEditorAlert&&imgFlag" @mouseover="changeDeleteMouse"  aria-hidden="true">
        <use href="#icon-sett"></use>
      </svg>
      <svg class="icon" v-if="!isShowComponentsEditorAlert&&!imgFlag" @mouseleave="changeDeleteMouse" aria-hidden="true">
        <use href="#icon-sett2"></use>
      </svg>
    </div>

    <!-- 图表组件卡片，判断当前报表对应的图表是否有数据，决定是否显示图表组件 -->
    <dashboardCard
      v-if="JSON.stringify(chartInfoDatas) !== '{}'"
      :DashboardData="chartInfoDatas"
      useLocation="report"
      :appreport="'app'"
    />

    <!-- 添加到组件模板弹框 -->
    <componentEditor
      ref="ComponentEditorRef"
      :isShowComponentsEditorAlert="isShowComponentsEditorAlert"
      :sourceType="sourceType"
      :dashboardy="dashboardy"
      :chartInfo="chartInfo"
      :linkageArr="linkageArr"
      :reportInfo="reportInfo"
      :chartInfos="chartInfos"
      useLocation="report"
      :temReportId="temReportId"
      @cancel="componentEditorCancel"
      @affirm="componentEditorAffirm"
    />

    <!-- 图表全屏组件 -->
    <chartFullScreen
      :chartFullScreenTo="chartFullScreenTo"
      :DashboardDataFullScreen="DashboardDataFullScreen"
      @chartFullScreenClose="chartFullScreenClose"
    />
  </div>
</template>

<script>
import * as Cpn from "../../../homePage/components/index";
import ComponentEditor from "../../../dashboardObject/dashboardIndex/components/ComponentEditor";
import mixin from "@/mixin/homepageAndDashboardMixin.js";
import chartFullScreen from "@/components/FullScreen/chartFullScreen"; // 图表全屏组件

export default {
  props: {
    reportInfo: {
      // 在报表编辑页添加图表时直接传过来图表信息
      type: Object,
      default: () => {},
    },
    chartInfoDatas: {
      // 根据报表获取的图表数据
      type: Object,
      default: () => {},
    },
    chartInfos: {
      // 根据报表获取的图表已选择的数据
      type: Object,
      default: () => {},
    },
    temReportId: {
      // 报表id
      type: String,
      default: "",
    },
    appreport: {
      // 移动端报表
      type: String,
      default: "",
    },
  },
  components: {
    ComponentEditor,
    chartFullScreen,
    ...Cpn,
  },
  mixins: [mixin],
  data() {
    return {
      chooseTo: false,
      chartFullScreenTo: false, // 控制图表全屏
      imgFlag:true,//svg图标切换
    };
  },
  mounted() {
    // 控制图表全屏展示
    this.$bus.$on("clickChartFullScreen", this.clickChartFullScreenfn);
  },
  methods: {
    // $bus性能优化
    clickChartFullScreenfn(e){
      this.DashboardDataFullScreen = e;
      this.chartFullScreenTo = true;
  },
    // svg鼠标移入移除事件
    changeDeleteMouse(){
      this.imgFlag=!this.imgFlag;
    }
  },
  destroyed() {
    this.$bus.$off("clickChartFullScreen",this.clickChartFullScreenfn);
  },
};
</script>

<style lang="scss" scoped>
.generateChart {
  height: 100%;
  // padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  .set_img {
    position: absolute;
    right: 35px;
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    margin: 9px 15px 0;
    cursor: pointer;
    svg{
    width: 20px;
    height: 20px;
    }
  }
  ::v-deep .head-bar-right{
    padding: 9px 5px 0 5px!important;
  }
  .box-card {
    padding: 0 !important;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  ::v-deep .el-card__body {
    padding: 0;
  }
  ::v-deep .chart_div {
    // height: 100% !important;
    flex: 1;
    margin: 10px 10px 0 15px;
  }
}
</style>