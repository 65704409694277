<!-- 左侧顶部，tab切换 -->
<template>
  <div class="tab_switch">
    <!-- tab页切换：概要 -->
    <div
      class="each_tab"
      @click="changeTab(1)"
      :class="{ tabActive: tabCur === 1 }"
      v-if="!runTo"
    >
      <svg class="icon" aria-hidden="true">
        <use href="#icon-zhaiyao"></use>
      </svg>
      <div>{{ $t("label.outline") }}</div>
    </div>
    <!-- tab页切换：筛选器 -->
    <div
      v-if="!runTo || filterTo"
      class="each_tab"
      @click="changeTab(2)"
      :class="{ tabActive: tabCur === 2 }"
    >
      <svg class="icon screenImg" aria-hidden="true">
        <use href="#icon-shaixuan"></use>
      </svg>
      <div>{{ $t("label.weixin.report.filters") }}</div>
      <div class="filter_number">{{ filterNumber }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabCur: {
      // tab选项
      type: Number,
      default: 1,
    },
    filterNumber: {
      // 筛选项tab后，显示添加的筛选项数量（时间范围+过滤条件）
      type: Number,
      default: 0,
    },
    runTo: {
      // 是否运行
      type: Boolean,
      default: false,
    },
    filterTo: {
      // 是否显示过滤器
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    // 切换tab
    changeTab(index) {
      if (this.runTo) return; // 运行状态，默认只有过滤器
      this.$emit("changeTab", index);
    },
  },
};
</script>

<style lang='scss' scoped>
.tab_switch {
  height: 39px;
  display: flex;
  justify-content: space-between;
  .each_tab {
    padding: 0 13px;
    width: 50%;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #fff;
    cursor: pointer;
    color: #6b6d70;
    font-size: 13px;
    img,
    svg {
      width: 13px;
      height: 10px;
      margin-right: 5px;
    }
    .screenImg {
      width: 18px;
      height: 14px;
      margin-right: 5px;
    }
  }
  .each_tab:last-child {
    .filter_number {
      margin-left: 5px;
      width: 20px;
      height: 20px;
      background-color: #0070d2;
      color: #fff;
      font-weight: bold;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 18px;
      height: 14px;
    }
  }
  .tabActive {
    border-bottom: 3px solid #006dcc;
    font-weight: bold;
  }
}
</style>