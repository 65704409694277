<!--
  高级筛选条件气泡
-->
<template>
  <div>
    <svg
      aria-hidden="true"
      :class="
        imgSrcData == 'add_ac' || imgSrcData == 'add_active'
          ? 'add-img'
          : 'edit-img'
      "
      v-popover:popoverRef
      @click="addAdvancedScreening"
    >
      <use :href="'#icon-' + imgSrcData"></use>
    </svg>
    <el-popover
      placement="right"
      width
      trigger="click"
      ref="popoverRef"
      popper-class="popover_filter"
      @hide="hideFilterPopover"
    >
      <div class="advancedScreening">
        <div class="advancedScreening_header">
          <div>{{ $t("advancedconditions") }}</div>
          <svg class="icon" aria-hidden="true" @click="advancedScreeningCancel">
            <use href="#icon-chahao"></use>
          </svg>
        </div>
        <div class="advancedScreening_center">
          <div>
            {{ $t("vue_label_report_notice_filter") }}
          </div>
          <div class="word_img">
            <div class="left_word">
              <div>
                {{ $t("vue_label_report_advanced") }}
              </div>
              <el-input
                v-model="form.condition"
                :placeholder="$t('advancedconditions')"
              ></el-input>
            </div>
            <img src="@/assets/images/example.png" />
          </div>
        </div>
        <div class="advancedScreening_footer">
          <el-button
            class="footer_left"
            v-show="removeLogic"
            @click="clickRemoveLogic"
            >{{ $t("label.Remove") }}</el-button
          >
          <div class="footer_right">
            <el-button @click="advancedScreeningCancel">{{
              $t("button_partner_cancel")
            }}</el-button>
            <el-button type="primary" @click="advancedScreeningApply">{{
              $t("label.apply")
            }}</el-button>
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      // 显示图片
      type: String,
      default: "",
    },
    removeLogic: {
      // 是否显示移除按钮
      type: Boolean,
      default: false,
    },
    formCalculate: {
      // 已设置的高级筛选条件
      type: String,
      default: "",
    },
    screeningTo: {
      // 表示气泡的显示隐藏
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        condition: "", // 输入的高级筛选条件
      },
      imgSrcData: this.imgSrc, // 显示的图片
    };
  },
  methods: {
    // 添加、修改高级筛选条件
    addAdvancedScreening() {
      this.$emit("showSeniorPopoper");
      this.form.condition = this.formCalculate; // 筛选条件的回显
      if (this.imgSrcData === "add_ac") {
        this.imgSrcData = "add_active";
      }
    },
    // 监听气泡关闭
    hideFilterPopover() {
      this.$emit("hideSeniorPopoper");
      if (this.imgSrcData === "add_active") {
        this.imgSrcData = "add_ac";
      }
    },
    //移除逻辑
    clickRemoveLogic() {
      this.$refs.popoverRef.doClose();
      this.$emit("clickRemoveLogic");
    },
    // 关闭气泡
    advancedScreeningCancel() {
      this.$refs.popoverRef.doClose();
    },
    // 应用
    advancedScreeningApply() {
      if(this.form.condition.indexOf('）')>-1||this.form.condition.indexOf('（')>-1){
        this.$message.error(this.$i18n.t("c1063"))
      }else{
        this.$refs.popoverRef.doClose();
        this.$emit("advancedScreeningApply", this.form.condition);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-img {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  margin-right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.edit-img {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  margin-right: 8px;
  width: 12px;
  height: 12px;
}
.advancedScreening {
  word-break: break-word; // 英文换行
  width: 545px;
  min-height: 345px;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  .advancedScreening_header {
    padding: 16px 20px;
    color: #080707;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
  .advancedScreening_center {
    padding: 21px;
    font-size: 12px !important;
    color: #080707 !important;
    line-height: 19px;
    .word_img {
      margin-top: 21px;
      display: flex;
      justify-content: space-between;
      .left_word {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ::v-deep .el-input {
          width: 230px;
          .el-input__inner {
            border: 1px solid #006dcc;
            border-radius: 4px;
            height: 32px;
          }
        }
      }
      svg,
      img {
        width: 254px;
        height: 110px;
      }
    }
  }
  .advancedScreening_footer {
    padding-right: 20px;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      padding: 7px 14px;
      border-radius: 2px;
    }
    // .el-button--default {
    //   color: #1b5297;
    //   background-color: #fff;
    //   border: 1px solid #dcdcdc;
    // }
    .footer_left {
      position: absolute;
      left: 21px;
    }
    .footer_right {
      display: flex;
      // .el-button--primary {
      //   color: #fff;
      //   background-color: #1b5297;
      //   border-color: #1b5297;
      // }
      .el-button--primary.is-disabled {
        background-color: #c9c7c5 !important;
        border-color: #c9c7c5 !important;
      }
    }
  }
}
</style>