<!--
 * @Author       : liyb
 * @Date         : 2021-09-13 13:02:08
 * @LastEditors  : liyb
 * @LastEditTime : 2021-09-22 14:47:45
 * @Description  : file content
 * @FilePath     : \lightning-web\src\views\report\editReport\displayFields\fieldTypeSearch.vue
-->
/* 
  根据字段类型搜索
  开发人员：李仪波 
*/
<template>
  <div class="fieldTypeSearch_div">
    <el-radio-group v-model="form.type" size="mini" @change="changeType">
      <el-radio-button label="all">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('chatter.group.showAll')"
          placement="top"
        >
          <span>{{ $t("label.quickbooks.view.all") }}</span>
        </el-tooltip>
      </el-radio-button>
      <!-- 其他字段类型选择：根据当前id是否是选择的id，决定图片如何显示 -->
      <el-radio-button
        v-for="(item, index) in allTypeImgs"
        :key="index"
        :label="item.id"
        ><el-tooltip
          class="item"
          effect="dark"
          :content="$t(item.tooltipContent)"
          placement="top"
        >
          <svg class="icon" aria-hidden="true">
            <use
              :href="`#${form.type === item.id ? item.img_ed : item.img}`"
            ></use>
          </svg>
        </el-tooltip>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import allTypeImgs from "@/config/enumCode/filedType.js";

export default {
  data() {
    return {
      form: {
        type: "all", // 选择的字段类型
      },
      allTypeImgs: allTypeImgs,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 切换选择类型
    changeType(value) {
      let types = [];
      // 不是全部，则根据value过滤出对应的字段类型集合
      if (value !== "all") {
        types = allTypeImgs.find((item) => item.id === value).types;
      }
      this.$emit("changeType", types, value);
    },
  },
};
</script>

<style lang='scss' scoped>
.fieldTypeSearch_div {
  width: 100%;
  img {
    width: 13px;
    height: 13px;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #1b5297;
    border-color: #1b5297;
  }
  ::v-deep .el-radio-button--mini .el-radio-button__inner {
    height: 30px;
    padding: 7px 17px;
  }
}
</style>