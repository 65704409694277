<template>
  <div class="fieldList_div">
    <el-tree
      :data="treeData"
      :props="defaultProps"
      @node-click="handleNodeClick"
      highlight-current
      :default-expand-all="true"
      :filter-node-method="filterNode"
      ref="tree"
      @node-drag-start="handleDragStart"
      @node-drag-enter="handleDragEnter"
      @node-drag-leave="handleDragLeave"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
      draggable
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
    >
      <span class="span_content" slot-scope="{ node, data }">
        <!-- 文件夹图标 -->
        <svg
          class="icon"
          v-if="data.fieldList && data.id === nodeId"
          aria-hidden="true"
        >
          <use href="#icon-folder_white"></use>
        </svg>
        <svg
          class="icon"
          v-if="data.fieldList && data.id != nodeId"
          aria-hidden="true"
        >
          <use href="#icon-folder"></use>
        </svg>
        <!-- 字段图标 -->
        <svg v-else class="icon" aria-hidden="true">
          <use
            :href="`#${icons(data.schemefieldType, data.id, data.fieldList)}`"
          ></use>
        </svg>
        <span class="label_span" v-if="!keyWord">{{ node.label }}</span>
        <span
          class="label_span"
          v-if="keyWord"
          v-html="
            node.label.replace(
              keyWord,
              `<span style='background-color: #FFF03F;color: #080707'>${keyWord}</span>`
            )
          "
        ></span>
      </span>
    </el-tree>
  </div>
</template>

<script>
import allTypeImgs from "@/config/enumCode/filedType.js";

export default {
  props: {
    treeData: {
      // 字段数据
      type: Array,
      default: () => [],
    },
    keyWord: {
      // 搜索的关键词，用于高亮显示
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultProps: {
        children: "fieldList",
        label: "label_name",
      },
      nodeId: "", // 选择的字段id
    };
  },
  computed: {
    // 根据字段类型匹配对应的图片显示
    icons() {
      return (type, id) => {
        let img = "";
        allTypeImgs.map((item) => {
          if (item.types.indexOf(type) > -1) {
            img = id === this.nodeId ? item.img_ed : item.img;
          }
        });
        // 根据type没有匹配到，说明属于文本类型字段
        if (!img) {
          img = id === this.nodeId ? allTypeImgs[0].img_ed : allTypeImgs[0].img;
        }
        return img;
      };
    },
  },
  created() {
    /* 
      将选中的字段id清空，有几种情况需要清空：
        1.字段列表数据重新加载；2.切换字段类型；
    */
    this.$bus.$on("clearNodeId", this.ebFn);
  },
  destroyed() {
    this.$bus.$off("clearNodeId", this.ebFn);
  },
  mounted() {},
  methods: {
    ebFn(){
      this.nodeId = "";
    },
    handleNodeClick(data) {
      this.nodeId = data.id;
    },
    // 根据搜索内容过滤出符合条件的label
    filterNode(value, data) {
      if (!value) return true;
      return data.label_name.indexOf(value) !== -1;
    },
    handleDragStart(node, ev) {
      let dt = ev.dataTransfer;
      ev.dataTransfer.effectAllowed = "copy";
      dt.setData("text/plain", JSON.stringify(node.data));
    },
    handleDragEnter() {},
    handleDragLeave() {},
    handleDragOver() {},
    handleDragEnd() {},
    handleDrop() {},
    allowDrop() {
      return false;
    },
    allowDrag() {
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.fieldList_div {
  ::v-deep .el-tree {
    background-color: #f9f9fa;
    .span_content {
      display: flex;
      align-items: center;
      svg,
      img {
        width: 12px;
        height: 10px;
        margin-right: 3px;
      }
      .label_span {
        color: #080707;
      }
    }
  }
  ::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #0070d2;
    .label_span {
      color: #fff;
    }
  }
}
</style>