<!--
 * @Author: pengyd
 * @Date: 2021-10-18 10:21:43
 * @LastEditors: pengyd
 * @LastEditTime: 2021-10-20 14:21:19
 * @Description: file content
 * @FilePath: \lightning-web\src\views\report\editReport\components\sameMonthTable.vue
-->
<!-- 
    文件说明：同环比报表table表格
    创建时间：2021-9-15
    开发人员：张凤
 -->
<template>
  <div class="sameTable">
    <el-table
      style="width: 100%"
      :cell-style="rowClass"
      height="100%"
      border
      :data="tableData"
    >
      <!-- 日期字段 -->
      <el-table-column
        :fixed="appReport?'left':false"
        :prop="dateFieldId"
        class-name="data-left"
        :label="dateFieldName"
        min-width="140"
      >
      </el-table-column>
      <!-- 数值和金额字段 -->
      <el-table-column
        v-if="summaryFieldName"
        :prop="summaryFieldID"
        :label="summaryFieldName"
        min-width="140"
      >
      </el-table-column>
      <!-- 环比 -->
      <el-table-column
        prop="monthRatio"
        :label="$t('label_ring_ratio')"
        min-width="140"
      >
      </el-table-column>
      <!-- 去年同期-->
      <el-table-column
        prop="lastYearSum"
        :label="summaryFieldName + $t('label_report_last_year_same')"
        min-width="140"
      >
      </el-table-column>
      <!-- 去年同比 -->
      <el-table-column
        prop="yearRatio"
        :label="$t('label_report_year_last_year')"
        min-width="140"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    // 区分移动端报表
    appReport: {
      type: String,
      default: "",
    },
    tableData: {
      // 同环比报表数据
      type: Array,
      default: () => [],
    },
    listCheckedFields: {
      // 列数据
      type: Array,
      default: () => [],
    }, // 数值金额字段id,默认记录数
    chooseStatisticaLindicators: {
      type: String,
      default: "",
    },
    // 数值类型id
    gatherfieldname: {
      type: String,
      default: "",
    },
    // 数值金额进行汇总字段name
    summaryFieldName: {
      type: String,
      default: "",
    },
    // 数值金额进行汇总字段id
    summaryFieldID: {
      type: String,
      default: "",
    },
    // 日期字段name
    dateFieldName: {
      type: String,
      default: "",
    },
    // 日期字段id
    dateFieldId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    rowClass() {
      return "text-align: right;";
    },
  },
};
</script>
<style lang="scss" scoped>
.sameTable {
  height: 105%;
}
::v-deep .data-left {
  text-align: left !important;
}
</style>