/* 
  展示字段组件
  开发人员：李仪波 
*/
<template>
  <div class="displayFields_div">
    <!-- 搜索框 -->
    <div class="top_search">
      <el-input
        v-model="form.keyWord"
        :placeholder="$t('label_tabpage_contentz')"
        clearable
        size="mini"
      ></el-input>
      <div class="all_butt">
        <el-button type="text">
          <i class="el-icon-search"></i>
          <i class="el-icon-caret-bottom"></i>
        </el-button>
        <el-button
          icon="el-icon-close"
          type="text"
          @click="closeDisplayFields"
        ></el-button>
      </div>
    </div>
    <!-- 字段类型搜索 -->
    <field-type-search @changeType="changeType" />
    <!-- 字段列表展示 -->
    <field-list
      class="fieldList_div_content"
      ref="fieldListRef"
      :treeData="treeDataArr"
      :keyWord="form.keyWord"
    />
  </div>
</template>

<script>
import fieldTypeSearch from "../displayFields/fieldTypeSearch";
import fieldList from "../displayFields/fieldList";
import allTypeImgs from "@/config/enumCode/filedType.js";

export default {
  components: {
    fieldTypeSearch,
    fieldList,
  },
  props: {
    treeData: {
      // 字段数据
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        keyWord: "", // 输入的搜索内容
      },
      treeDataArr: [], // 组件重新定义数据，做完处理再传过去
      fieldTypeSearchTo: false, // 控制字段类型搜索是否显示
    };
  },
  created() {},
  mounted() {},
  watch: {
    // 通过监听treeData，给定义的数据附初始值
    treeData: {
      handler() {
        this.treeDataArr = JSON.parse(JSON.stringify(this.treeData));
        this.$bus.$emit("clearNodeId");
      },
      immediate: true,
    },
    // 监听输入的搜索内容，调用tree的过滤方法
    "form.keyWord": function (val) {
      this.$refs.fieldListRef.$refs.tree.filter(val);
    },
  },
  methods: {
    // 关闭展示字段
    closeDisplayFields() {
      this.$emit("closeDisplayFields");
    },
    // 筛选字段类型
    changeType(types, value) {
      this.$bus.$emit("clearNodeId");
      this.treeDataArr = []; // 先清空数据
      // 如果是全部，则不需处理
      if (value === "all") {
        this.treeDataArr = JSON.parse(JSON.stringify(this.treeData));
        return;
      }
      // 根据类型过滤出满足条件的字段集合
      this.treeData.map((item) => {
        let object = {
          fieldList: [],
          id: item.id,
          label_name: item.label_name,
        };
        // 如果是文本类型字段，需要满足：不属于其他四种类型字段
        if (value === "text") {
          object.fieldList = item.fieldList.filter(
            (item1) =>
              allTypeImgs[1].types.indexOf(item1.schemefieldType) === -1 &&
              allTypeImgs[2].types.indexOf(item1.schemefieldType) === -1 &&
              allTypeImgs[3].types.indexOf(item1.schemefieldType) === -1 &&
              allTypeImgs[4].types.indexOf(item1.schemefieldType) === -1
          );
        } else {
          // 非文本类型，直接筛选就可以
          object.fieldList = item.fieldList.filter(
            (item1) => types.indexOf(item1.schemefieldType) > -1
          );
        }
        if (object.fieldList.length > 0) {
          this.treeDataArr.push(object);
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.displayFields_div {
  width: 300px;
  background-color: #f9f9fa;
  .top_search {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-bottom: 1px solid #d9dbdd;
    .all_butt {
      display: flex;
      .el-button {
        font-size: 14px;
        color: #706e6b;
        ::v-deep i {
          font-weight: bold;
        }
        // &:hover {
        //   color: #004487;
        // }
      }
    }
  }
  .fieldTypeSearch_div {
    margin-top: -1px;
  }
  .fieldList_div {
    margin-top: 5px;
    height: calc(100% - 55px);
    overflow-y: auto;
  }
  .fieldList_div_content {
    height: calc(100% - 85px);
  }
}
</style>