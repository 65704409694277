<!-- 顶部名称和操作按钮 -->
<template>
  <div class="editReport_top">
    <div class="top_div">
      <!-- 报表名称及对象关系 -->
      <div class="left_title">
        <div class="call-back" >
          <svg class="icon" aria-hidden="true" @click="backReportList" >
            <use href="#icon-leftBlack"></use>
          </svg>
        </div>
        <strong class="surface_name">{{ temName }}</strong>
        <el-popover
          placement="bottom"
          width
          trigger="hover"
          popper-class="myPopove"
        >
          <span class="object_relation" slot="reference">{{
            $t("label.view.relationship")
          }}</span>
          <div class="value_box">
            <div
              class="each_data"
              v-for="(item, index) in allObjectDatas.filter((data) => {
                return data !== '';
              })"
              :key="index"
            >
              <div class="data_value">{{ item }}</div>
              <div
                class="right_line"
                v-show="
                  index !== allObjectDatas.length - 1 &&
                  allObjectDatas[index + 1] !== ''
                "
              ></div>
            </div>
          </div>
        </el-popover>
        <div>
          （{{ $t("label.currency") }}：{{ $store.getters.getCurrencyCode }}）
        </div>
      </div>
      <!-- 编辑页按钮 -->
      <div class="right_butts_edit" v-if="!runTo">
        <!-- 图表按钮 -->
        <el-button
          v-if="chooseRowFileds.length > 0 && !sameMonthReportFlag"
          size="mini"
          class="charts_butt"
          @click="clickChartButt"
        >
          <svg
            class="icon"
            v-if="imgFlag && !generateChartTo"
            @mouseover="changeDeleteMouse"
            aria-hidden="true"
          >
            <use href="#icon-tubiao"></use>
          </svg>
          <svg
            class="icon"
            v-if="!imgFlag && !generateChartTo"
            @mouseleave="changeDeleteMouse"
            aria-hidden="true"
          >
            <use href="#icon-tubiao1"></use>
          </svg>
          <svg class="icon" v-if="generateChartTo" aria-hidden="true">
            <use href="#icon-tubiao1"></use>
          </svg>
        </el-button>
        <el-button
          size="mini"
          @click="clickSave"
          v-if="(temReportId && folderPermissions) || !temReportId"
        >
          {{ $t("label.save") }}
        </el-button>
        <el-button size="mini" @click="clickQuit">{{
          $t("label.close")
        }}</el-button>
        <!-- 判断用户是否有运行权限 -->
        <el-button
          type="primary"
          size="mini"
          @click="clickRun"
          v-if="reportOperation.run"
          >{{ $t("label.runreport") }}</el-button
        >
      </div>
      <!-- 运行页按钮 -->
      <div class="right_butts_run" v-else>
        <!-- 图表按钮 -->
        <el-button
          v-if="chooseRowFileds.length > 0 && !sameMonthReportFlag"
          size="mini"
          class="charts_butt"
          @click="clickChartButt"
        >
          <svg
            class="icon"
            v-if="imgFlag && !generateChartTo"
            @mouseover="changeDeleteMouse"
            aria-hidden="true"
          >
            <use href="#icon-tubiao"></use>
          </svg>
          <svg
            class="icon"
            v-if="!imgFlag && !generateChartTo"
            @mouseleave="changeDeleteMouse"
            aria-hidden="true"
          >
            <use href="#icon-tubiao1"></use>
          </svg>
          <svg class="icon" v-if="generateChartTo" aria-hidden="true">
            <use href="#icon-tubiao1"></use>
          </svg>
        </el-button>
        <!-- 过滤器按钮 -->
        <el-button
          size="mini"
          class="filter_butt"
          v-if="!sameMonthReportFlag"
          @click="filterTo = !filterTo"
        >
          <svg
            class="icon"
            v-if="filterImgFlag && !filterTo"
            @mouseover="filterMouse"
            aria-hidden="true"
          >
            <use href="#icon-shaixuan"></use>
          </svg>
          <svg
            class="icon"
            v-if="!filterImgFlag && !filterTo"
            @mouseleave="filterMouse"
            aria-hidden="true"
          >
            <use href="#icon-shaixuan1"></use>
          </svg>
          <svg class="icon" v-if="filterTo" aria-hidden="true">
            <use href="#icon-shaixuan1"></use>
          </svg>
        </el-button>
        <!-- 运行的操作按钮 -->
        <el-dropdown v-if="folderPermissions">
          <el-button-group>
            <el-button
              :disabled="isOptimization"
              type="primary"
              size="mini"
              @click="clickEdit"
              >{{ $t("pagecreator_page_button_edit") }}</el-button
            >
            <el-button
              type="primary"
              size="mini"
              class="el-dropdown__caret-button"
              ><i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
          </el-button-group>
          <el-dropdown-menu slot="dropdown">
            <!-- 保存 -->
            <el-dropdown-item
              class="each_item"
              @click.native="clickSave"
              v-if="(temReportId && folderPermissions) || !temReportId"
            >
              {{ $t("label.save") }}
            </el-dropdown-item>
            <!-- 导出 -->
            <el-dropdown-item
              class="each_item"
              @click.native="clickExport"
              v-if="
                temReportId && reportOperation.export && !sameMonthReportFlag
              "
            >
              {{ $t("label.reportexport") }}
            </el-dropdown-item>
            <!-- 删除 -->
            <el-dropdown-item
              class="each_item"
              @click.native="runRunDelete"
              v-if="temReportId && folderPermissions"
            >
              {{ $t("component_setup_tabs_label_delete") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 运行顶部总记录数 -->
    <div class="bottom_div" v-if="runTo && !sameMonthReportFlag">
      <div class="summary_div">
        <div>{{ $t("label.recordamount") }} :</div>
        <div class="bold">{{ totalrecords }}</div>
      </div>
      <div
        v-for="(item, index) in SummaryTypeData"
        :key="index"
        class="summary_div"
      >
        <div v-if="item.label">{{ item.label }}{{ item.summaryWay }} :</div>
        <div class="bold" v-if="item.label">
          {{ item.value ? item.value : "0" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 统计汇总字段
    SummaryAndMatrixMapTotal: {
      type: Object,
      default: () => {},
    },
    temName: {
      // 报表名称
      type: String,
      default: "",
    },
    sameMonthReportFlag: {
      // 是否未同比环比报表
      type: Boolean,
      default: false,
    },
    isshowcharts: {
      // 是否显示图表按钮
      type: Boolean,
      default: false,
    },
    temReportId: {
      // 报表id
      type: String,
      default: "",
    },
    allObjectDatas: {
      // 表头业务对象数据
      type: Array,
      default: () => [],
    },
    runTo: {
      // 是否点击运行
      type: Boolean,
      default: false,
    },
    folderPermissions: {
      // 报表所在文件夹是否可编辑
      type: Boolean,
      default: true,
    },
    chooseRowFileds: {
      type: Array,
      default: () => [],
    },
    reportOperation: {
      // 报表权限信息
      type: Object,
      default: () => {},
    },
    totalrecords: {
      // 总记录数
      type: Number,
      dedault: 0,
    },
    isOptimization: {
      // 是否是优化过的报表
      type: Boolean,
      default: false,
    },
    listAvgFields: {
      // 平均值
      type: Array,
      default: () => [],
    },
    listMaxFields: {
      // 最大值
      type: Array,
      default: () => [],
    },
    listMinFields: {
      // 最小值
      type: Array,
      default: () => [],
    },
    gatherFieldList: {
      // 总和及记录数
      type: Array,
      default: () => [],
    },
    // 是否导出保存过报表
    iscloneReport: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      generateChartTo: "", //false 控制是否显示图表
      filterTo: false, // 是否显示过滤器
      imgFlag: true, //svg图标切换
      filterImgFlag: true, //svg图标切换
      SummaryType: [], //统计类型字段
    };
  },
  watch: {
    // 监听运行状态过滤器的显示隐藏
    filterTo: {
      handler() {
        this.$emit("getFilterTo", this.filterTo);
      },
    },
    // 监听图表的显示隐藏
    generateChartTo: {
      handler() {
        this.$emit("getGenerateChartTo", this.generateChartTo);
      },
    },
  },
  computed: {
    // 报表权限信息
    SummaryTypeData: function () {
      let summaryList = this.listAvgFields
        .concat(this.listMaxFields)
        .concat(this.listMinFields)
        .concat(this.gatherFieldList);
      let summaryData = []; //处理后的数据
      let summaryItem = {};
      summaryList.map((item) => {
        let sumType = "";
        if (item.summaryWay == "avg") {
          sumType = this.$i18n.t("label_average_value"); //"的平均值";
        } else if (item.summaryWay == "max") {
          sumType = this.$i18n.t("label.maximum"); //"的最大值";
        } else if (item.summaryWay == "min") {
          sumType = this.$i18n.t("label.minimun"); // "的最小值";
        } else if (item.summaryWay == "sum") {
          sumType = this.$i18n.t("label.summation"); //"的总和";
        }
        summaryItem = {};
        // 矩阵式报表取值不同
        if (item.summaryWay != "count") {
          if (
            this.SummaryAndMatrixMapTotal &&
            this.SummaryAndMatrixMapTotal.listLengthData
          ) {
            this.SummaryAndMatrixMapTotal.listLengthData.map((sumItem) => {
              if (sumItem.schemefieldnamealias == item.schemefield_name_alias) {
                summaryItem = {
                  label: item.labelName,
                  summaryWay: sumType,
                  value: sumItem.total,
                };
              }
            });
          } else {
            summaryItem = {
              label: item.labelName,
              summaryWay: sumType,
              value: this.SummaryAndMatrixMapTotal[item.schemefield_name_alias],
            };
          }
        }
        if (summaryItem.label) {
          summaryData.push(summaryItem);
        }
      });
      return summaryData;
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 返回按钮：跳转至报表列表页
    backReportList(){
      // 导出过报表并且没有保存报表离开页面时要重新保存
      if(this.iscloneReport=='save'){
        this.$emit("exportCloneReport")
      }
      this.$router.push({
        path: `/reportObject/reportList`,
        query: {
          type: 'reportDetail'
        },
      });
    },
    //svg鼠标进入和移除事件切换图标
    filterMouse() {
      this.filterImgFlag = !this.filterImgFlag;
    },
    changeDeleteMouse() {
      this.imgFlag = !this.imgFlag;
    },
    // 点击图表按钮
    clickChartButt() {
      this.generateChartTo = !this.generateChartTo;
      if (this.generateChartTo) {
        // 若是点击展开图表，则需要请求图表接口获取报表对应的图表数据
        this.$emit("clickChartButt");
      }
    },
    clickSave() {
      // 保存
      this.$emit("clickSave");
    },
    clickRun() {
      this.$emit("clickRun");
    },
    clickEdit() {
      // 编辑
      this.$emit("clickEdit");
    },
    runRunCommitB() {
      // 另存为
      this.$emit("runRunCommitB");
    },
    runRunDelete() {
      // 删除
      this.$emit("runRunDelete");
    },
    clickExport() {
      // 导出
      this.$emit("clickExport");
    },
    clickQuit() {
      // 退出
      this.$emit("clickQuit");
    },
  },
};
</script>

<style lang='scss' scoped>
.editReport_top {
  .top_div {
    padding: 0 20px 0 13px;
    width: 100%;
    height: 61px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
    .call-back{
      svg{
        margin-right: 6px;
        width: 18px;
        height: 16px;
        margin-top: 1px;
        display: block;
      }
    }
    .left_title {
      color: #222222;
      display: flex;
      align-items: center;
      .surface_name {
        font-size: 16px;
        padding-right: 10px;
      }
      .object_relation {
        padding: 5px;
        background: #e0e0e0;
        color: #222222;
        border-radius: 10px;
        cursor: pointer;
      }
      .value_box {
        width: 500px;
      }
      .slash {
        margin: 4px 7px 0 0;
      }
      .objectText {
        width: 110px;
        height: 50px;
        margin-top: 5px;
      }
    }
    .right_butts_edit {
      display: flex;
      background: #ffffff;
      color: #1b5297;
      img {
        width: 45px;
        height: 30px;
        cursor: pointer;
      }
    }
    .charts_butt {
      width: 30px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 17px;
        height: 17px;
        display: inline-block;
      }
    }
  }
  .right_butts_run {
    display: flex;
    align-items: center;
    color: #409eff;
    ::v-deep .el-button-group .el-button--primary:last-child {
      height: 28px;
    }
    .filter_butt {
      width: 30px;
      height: 28px;
      margin-right: 10px;
      svg {
        width: 17px;
        height: 17px;
        margin-left: -8px;
        margin-top: -2px;
      }
    }
    ::v-deep .el-dropdown {
      ::v-deep .el-button {
        color: #409eff;
        // background: #ffffff;
        border: 1px solid #dcdcdc;
        height: 28px;
      }
    }
  }
}
.bottom_div {
  padding: 0 20px 0 13px;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  height: 37px;
  line-height: 37px;
  flex-wrap: wrap;
  align-items: center;
  vertical-align: middle;
  div {
    color: #16325c;
  }
  div:first-child {
    font-size: 12px;
  }
  .bold {
    font-weight: bold;
    margin-left: 4px;
  }
  .summary_div {
    display: flex;
    margin-right: 25px;
  }
}

// 运行页按钮下拉菜单样式
.dropdown_menu {
  padding: 3px 0;
}
.each_item {
  font-size: 12px !important;
  min-width: 70px;
  padding: 5px 0 5px 15px;
  line-height: 20px;
}
</style>
