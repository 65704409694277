<!--
  编辑报表--选择完数据源之后
  关于权限问题：
    运行页
      1.编辑&筛选器&图表&保存&删除
      受文件夹编辑权限控制
      2.导出
      受导出权限控制
    编辑页
      1.运行
      受运行报表权限控制
      2.保存
      受文件夹编辑权限控制
  关于报表的拖拽问题：
    1.列表式报表，普通列可进行拖拽，改变普通列顺序；
    2.汇总式报表：
      不显示详细信息行时，可拖拽分组列，改变分组列顺序；
      显示详细信息行时，所有列均可拖拽，包含四种情况：
        普通列 -> 普通列；普通列 -> 组行；组行 -> 组行；组行 -> 普通列；
    3.矩阵式报表：
      上面的统计表不可拖拽；
      下面的详表除了第一列的序号列之外，其他列均可拖拽，改变普通列顺序；
-->
<template>
  <div class="body">
    <div class="editReport">
      <!-- 顶部名称和操作按钮 -->
      <topNameButts
        :allObjectDatas="allObjectDatas"
        :runTo="runTo"
        :isshowcharts="isshowcharts"
        :folderPermissions="folderPermissions"
        :chooseRowFileds="chooseRowFileds"
        :reportOperation="reportOperation"
        :totalrecords="totalrecords"
        :temName="temName"
        :SummaryAndMatrixMapTotal="SummaryAndMatrixMapTotal"
        :listAvgFields="listAvgFields"
        :listMaxFields="listMaxFields"
        :listMinFields="listMinFields"
        :gatherFieldList="gatherFieldList"
        :sameMonthReportFlag="sameMonthReportFlag"
        :temReportId="$route.params.reportId ? $route.params.reportId : ''"
        @clickSave="clickSave"
        @clickRun="clickRun"
        @clickEdit="clickEdit"
        @runRunCommitB="runRunCommitB"
        @runRunDelete="runRunDelete"
        @clickExport="clickExport"
        @clickQuit="clickQuit"
        @clickChartButt="getChartReport"
        @getFilterTo="getFilterTo"
        @getGenerateChartTo="getGenerateChartTo"
        @exportCloneReport="exportCloneReport"
        :isOptimization="isOptimization"
        :iscloneReport="iscloneReport"
        ref="topNameButtsRef"
      />
      <div :class="['editReport_bottom', { editReport_bottom_run: runTo }]">
        <!-- 编辑状态，控制是否展示数据源字段 -->
        <div
          class="display_field"
          v-show="
            !runTo &&
            !sameMonthReportFlag &&
            !displayFieldsTo &&
            !sameMonthReportFlag
          "
          @click="displayFieldsTo = true"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('component_setup_label_displayfield')"
            placement="right"
          >
            <div class="display_field_center">
              {{ $t("label.field") }}
              <i class="el-icon-arrow-down"></i>
            </div>
          </el-tooltip>
        </div>
        <!-- 展示字段组件 -->
        <display-fields
          v-show="displayFieldsTo"
          :treeData="allSelectFileds"
          @closeDisplayFields="displayFieldsTo = false"
        />
        <!-- 左侧操作：编辑状态或是选择运行状态下已点击过滤按钮 -->
        <div
          class="left_chooses"
          v-show="
            (!runTo && !sameMonthReportFlag) ||
            (filterTos && !sameMonthReportFlag)
          "
        >
          <!-- tab选项 -->
          <leftTop
            :tabCur="tabCur"
            :filterNumber="filterNumber"
            :runTo="runTo"
            :filterTo="filterTos"
            @changeTab="changeTab"
          />
          <!--
            选择行列-概要：
              1.tabCur为1，编辑状态下
           -->
          <profile
            ref="profileRef"
            v-show="tabCur === 1 && !runTo"
            :chooseRowFileds="chooseRowFileds"
            :choosedColumnFileds="choosedColumnFileds"
            :allSelectFileds="allSelectFileds"
            :groupIds="groupIds"
            :listCheckedFields="listCheckedFieldsArr"
            :judgeNumber="judgeNumber"
            :formulaCur="formulaCur"
            :expressionSummary="expressionSummary"
            :expressionLine="expressionLine"
            :statisticsDatas="statisticsDatas"
            :indexData="indexData"
            @changeRowData="changeRowData"
            @changeColData="changeColData"
            @changeReportColData="changeReportColData"
            @statisticsCurs="statisticsCurs"
            @cancelSummary="cancelSummary"
            @clickImg="clickImg"
            @deleteRowData="deleteRowData"
            @deleteColData="deleteColData"
            @hideFormula="hideFormula"
            @deleteReportColData="deleteReportColData"
            @clickLineFormula="clickLineFormula"
            @deleteLineFormula="deleteLineFormula"
            @clickSummaryFormula="clickSummaryFormula"
            @deleteSummaryFormula="deleteSummaryFormula"
            @dragReportColumnsBus="dragReportColumnsBus"
          />
          <!--
            过滤器-选择报表标准：
              1.tabCur为2；
              2.过滤器按钮显示，运行状态下；
          -->
          <filterSet
            v-show="tabCur == 2 || (filterTos && runTo)"
            :middleRoleTo="middleRoleTo"
            :middleRole="middleRole"
            :formula="formula"
            :allRoles="allRoles"
            :currentRole="currentRole"
            :subordinateRoles="subordinateRoles"
            :choosedTimeRanges="choosedTimeRanges"
            :durationLists="durationLists"
            :allSelectFileds="allSelectFileds"
            :formCalculate="formCalculate"
            :choosedFilterFileds="choosedFilterFileds"
            :screenCondition="screenCondition"
            :timeRanges="timeRanges"
            :allObjects="allObjects"
            @getRoleTreeAndDurationList="getRoleTreeAndDurationList"
            @changePerson="changePerson"
            @changeTimeRange="changeTimeRange"
            @deleteTimeRange="deleteTimeRange"
            @changeFilterFiled="changeFilterFiled"
            @clickCondition="clickCondition"
            @hideFilterPopver="hideFilterPopver"
            @applyCondition="applyCondition"
            @deleteFilterFiled="deleteFilterFiled"
            @hideTimeRangePopver="hideTimeRangePopver"
            @changeDemonstration="changeDemonstration"
            @hideFormula="hideFormula"
            @advancedScreeningApply="advancedScreeningApply"
            @formulaCancel="formulaCancel"
            @applyTimeRange="applyTimeRange"
            @getNextRoles="getNextRoles"
            ref="filterSetRef"
          />
        </div>
        <!-- 同比环比表左侧筛选组件,在编辑状态下并且是同比环比表的情况下显示 -->
        <sameMonthReport
          v-if="sameMonthReportFlag && !runTo"
          :listSummaryField="listSummaryField"
          :allSelectFileds="allSelectFileds"
          :allObjectDatas="allObjectDatas"
          :timeRanges="timeRanges"
          :sameMonthStarttime="sameMonthStarttime"
          :sameMonthEndtime="sameMonthEndtime"
          :chooseDimension="chooseDimension"
          :chooseStatisticaLindicators="chooseStatisticaLindicators"
          :gatherfieldname="gatherfieldname"
          :gatherFieldList="gatherFieldList"
          @changeObjectOne="changeObjectOne"
          @changeObjectTwo="changeObjectTwo"
          @changeStartTime="changeStartTime"
          @changeEndTime="changeEndTime"
        />
        <!-- 右侧：操作按钮、图表、表格，在运行状态若是不点击过滤按钮则宽度占100% -->
        <div :class="classObject">
          <!-- 右侧顶部，刷新提示信息及详细信息、自动更新预览按钮 -->
          <rightTop
            v-show="!sameMonthReportFlag"
            :showRefresh="showRefresh"
            :runTo="runTo"
            :isshowRowCount="isshowRowCount"
            :isshowDetailedInfor="isshowDetailedInfor"
            :runShowDataTo="runShowDataTo"
            :chooseRowFileds="chooseRowFileds"
            @changeDetailButt="changeDetailButt"
            @clickRefresh="clickRefresh"
            @changeLineCountButt="getReportDataByDetails"
            @getAutomaticUpdate="getAutomaticUpdate"
            ref="rightTopRef"
          /><!-- 不显示自动更新预览，点击刷新前的遮盖层 -->
          <div
            :class="{
              table_mask:
                !automaticUpdates && showRefresh && !sameMonthReportFlag,
            }"
          ></div>
          <!-- 图表、所有类型的报表 -->
          <div
            class="content_table"
            v-loading="loading"
            :element-loading-text="$t('label.report.loading')"
            element-loading-spinner="el-icon-loading"
          >
            <div class="table_echart_content">
              <div class="table_echart">
                <!-- 当显示详表时，矩阵式报表的统计表与图表一行； -->
                <div
                  style="height: 100%"
                  :class="{
                    table_echart_div: isMatrixDetailTable,
                  }"
                  v-show="!detailToTopTo"
                >
                  <!-- 图表：高度默认100%，不显示详表且显示图表时高度为50% -->
                  <generateChart
                    v-loading="reportEchartsLoading"
                    :element-loading-text="$t('label.report.loading')"
                    element-loading-spinner="el-icon-loading"
                    :class="{
                      data_table_active:
                        !isMatrixDetailTable &&
                        generateChartTos &&
                        chooseRowFileds.length > 0,
                    }"
                    v-if="
                      generateChartTos &&
                      chooseRowFileds.length > 0 &&
                      !sameMonthReportFlag
                    "
                    :reportInfo="reportInfoToChart"
                    :chartInfoDatas="chartInfoDatas"
                    :chartInfos="chartInfos"
                    :temReportId="
                      $route.params.reportId ? $route.params.reportId : ''
                    "
                  />
                  <!-- 列表式、汇总式、矩阵式的统计表：
                    高度默认为100%，不显示详表且显示图表时高度为50%
                  -->
                  <div
                    class="data_table"
                    :class="[
                      {
                        data_table_active:
                          !isMatrixDetailTable &&
                          generateChartTos &&
                          chooseRowFileds.length > 0,
                      },
                      {
                        data_table_width:
                          isMatrixDetailTable &&
                          generateChartTos &&
                          chooseRowFileds.length > 0,
                      },
                    ]"
                  >
                    <!--  列表式报表：行分组等于0，列分组等于0 ,页数大于1且在运行页显示分页-->
                    <listTypeTable
                      v-if="
                        transversegroupList.length === 0 &&
                        lengthwaysgroupList.length === 0 &&
                        !sameMonthReportFlag
                      "
                      v-loading="reportLoading"
                      :class="runTo && pageCount > 1 ? 'listTypeTable-box' : ''"
                      :element-loading-text="$t('label.report.loading')"
                      element-loading-spinner="el-icon-loading"
                      :tableData="tableData"
                      :listCheckedFields="listCheckedFields"
                      :expressionLine="expressionLine"
                      :cellClass="cellClass"
                      :judgeNumber="judgeNumber"
                      :isMapTaskRel="isMapTaskRel"
                      :mapTaskRel="mapTaskRel"
                      :isNameFiled="isNameFiled"
                      :changeMicrometerLevel="changeMicrometerLevel"
                      :chooseRowFileds="chooseRowFileds"
                      :choosedColumnFileds="choosedColumnFileds"
                      :groupIds="groupIds"
                      :indexData="indexData"
                      :dateGroupings="dateGroupings"
                      :statisticsDatas="statisticsDatas"
                      :sortDatas="sortDatas"
                      :cardViewjump="cardViewjump"
                      :getCheckboxFiled="getCheckboxFiled"
                      :runTo="runTo"
                      :TabularPagenum="TabularPagenum"
                      @clickImg="clickImg"
                      @loadMoreData="loadMoreData"
                      @sortingOrder="sortingOrder"
                      @deleteGroupRow="deleteGroupRow"
                      @deleteGroupColumn="deleteGroupColumn"
                      @deleteColumn="deleteColumn"
                      @clickColOperation="clickColOperation"
                      @statisticsCurs="statisticsCurs"
                      ref="listTypeTableRef"
                    />
                    <!-- 列表式报表：在运行页列表式显示分页 -->
                    <div
                      class="listTablepaging"
                      v-if="
                        transversegroupList.length === 0 &&
                        lengthwaysgroupList.length === 0 &&
                        !sameMonthReportFlag &&
                        runTo &&
                        pageCount > 1
                      "
                    >
                      <el-pagination
                        :page-size="2000"
                        @current-change="handleCurrentChange"
                        :pager-count="5"
                        layout="prev, pager, next"
                        :total="totalrecords"
                      >
                      </el-pagination>
                    </div>

                    <!-- 汇总式：行分组大于0，列分组等于0 -->
                    <summaryTypeTable
                      ref="summaryTypeTableRef"
                      v-loading="reportLoading"
                      :element-loading-text="$t('label.report.loading')"
                      element-loading-spinner="el-icon-loading"
                      v-if="
                        transversegroupList.length > 0 &&
                        lengthwaysgroupList.length === 0 &&
                        !sameMonthReportFlag
                      "
                      :transversegroupList="transversegroupList"
                      :lengthwaysgroupList="lengthwaysgroupList"
                      :TabularPagenum="TabularPagenum"
                      :detailButt="
                        $refs.rightTopRef
                          ? $refs.rightTopRef.form.detailButt
                          : false
                      "
                      :showRefresh="showRefresh"
                      :tableData="tableData"
                      :runTo="runTo"
                      :listCheckedFields="listCheckedFields"
                      :expressionLine="expressionLine"
                      :expressionSummary="expressionSummary"
                      :listAvgFields="listAvgFields"
                      :listMaxFields="listMaxFields"
                      :listMinFields="listMinFields"
                      :gatherFieldList="gatherFieldList"
                      :objectSpanMethod="objectSpanMethod"
                      :cellClass="cellClass"
                      :changeMicrometerLevel="changeMicrometerLevel"
                      :isNameFiled="isNameFiled"
                      :cardViewjump="cardViewjump"
                      :judgeNumber="judgeNumber"
                      :isMapTaskRel="isMapTaskRel"
                      :mapTaskRel="mapTaskRel"
                      @loadMoreData="loadMoreData"
                      :chooseRowFileds="chooseRowFileds"
                      :choosedColumnFileds="choosedColumnFileds"
                      :groupIds="groupIds"
                      :indexData="indexData"
                      :dateGroupings="dateGroupings"
                      :statisticsDatas="statisticsDatas"
                      :sortDatas="sortDatas"
                      :getCheckboxFiled="getCheckboxFiled"
                      @clickImg="clickImg"
                      @sortingOrder="sortingOrder"
                      @deleteGroupRow="deleteGroupRow"
                      @deleteGroupColumn="deleteGroupColumn"
                      @deleteColumn="deleteColumn"
                      @clickColOperation="clickColOperation"
                      @statisticsCurs="statisticsCurs"
                    />

                    <!-- 矩阵式报表：行分组大于0，列分组大于0 -->
                    <matrixTable
                      v-loading="reportLoading"
                      :element-loading-text="$t('label.report.loading')"
                      element-loading-spinner="el-icon-loading"
                      v-if="
                        lengthwaysgroupList.length > 0 &&
                        transversegroupList.length > 0 &&
                        !sameMonthReportFlag
                      "
                      :transversegroupList="transversegroupList"
                      :rowColumnData="rowColumnData"
                      :TabularPagenum="TabularPagenum"
                      @loadMoreData="loadMoreData"
                      :runTo="runTo"
                      :chooseColumnTypes="chooseColumnTypes"
                      :lengthwaysgroupList="lengthwaysgroupList"
                      :expressionSummary="expressionSummary"
                      :isHavaMoreColumns="isHavaMoreColumns"
                      :objectSpanMethod="objectSpanMethod"
                      :cellClass="cellClass"
                      :changeMicrometerLevel="changeMicrometerLevel"
                      :isNameFiled="isNameFiled"
                      :cardViewjump="cardViewjump"
                      :judgeNumber="judgeNumber"
                      :isMapTaskRel="isMapTaskRel"
                      :mapTaskRel="mapTaskRel"
                      :chooseRowFileds="chooseRowFileds"
                      :choosedColumnFileds="choosedColumnFileds"
                      :groupIds="groupIds"
                      :indexData="indexData"
                      :dateGroupings="dateGroupings"
                      :statisticsDatas="statisticsDatas"
                      :sortDatas="sortDatas"
                      :detailButt="
                        $refs.rightTopRef
                          ? $refs.rightTopRef.form.detailButt
                          : false
                      "
                      :getCheckboxFiled="getCheckboxFiled"
                      @clickImg="clickImg"
                      @sortingOrder="sortingOrder"
                      @deleteGroupRow="deleteGroupRow"
                      @deleteGroupColumn="deleteGroupColumn"
                      @deleteColumn="deleteColumn"
                      @clickColOperation="clickColOperation"
                      @statisticsCurs="statisticsCurs"
                      @cellclick="cellclick"
                      ref="matrixTableRef"
                    />
                    <!-- 同环比报表 -->
                    <sameMonthTable
                      ref="sameMonthTableRef"
                      v-if="sameMonthReportFlag"
                      v-loading="reportLoading"
                      :tableData="tableData"
                      :listCheckedFields="listCheckedFields"
                      :summaryFieldName="summaryFieldName"
                      :summaryFieldID="summaryFieldID"
                      :dateFieldName="dateFieldName"
                      :dateFieldId="dateFieldId"
                      :chooseStatisticaLindicators="chooseStatisticaLindicators"
                    />
                  </div>
                </div>
                <!-- 矩阵式报表的详表：行分组大于0，列分组大于0，显示详细信息行 -->
                <matrixDetailTable
                  v-if="
                    transversegroupList.length > 0 &&
                    lengthwaysgroupList.length > 0 &&
                    !sameMonthReportFlag &&
                    $refs.rightTopRef
                      ? $refs.rightTopRef.form.detailButt
                      : false
                  "
                  v-loading="loadingDetail"
                  ref="matrixDetailTableRef"
                  :element-loading-text="$t('label.report.loading')"
                  element-loading-spinner="el-icon-loading"
                  :loadingDetail="loadingDetail"
                  :cellArr="cellArr"
                  :listTableDetail="listTableDetail"
                  :AlllistTableDetail="AlllistTableDetail"
                  :DetailPagenum="DetailPagenum"
                  @loadMoreData="loadMoreData"
                  :listCheckedFields="listCheckedFields"
                  :groupIds="groupIds"
                  :expressionLine="expressionLine"
                  :isNameFiled="isNameFiled"
                  :changeMicrometerLevel="changeMicrometerLevel"
                  :cardViewjump="cardViewjump"
                  :judgeNumber="judgeNumber"
                  :isMapTaskRel="isMapTaskRel"
                  :mapTaskRel="mapTaskRel"
                  :chooseRowFileds="chooseRowFileds"
                  :choosedColumnFileds="choosedColumnFileds"
                  :indexData="indexData"
                  :dateGroupings="dateGroupings"
                  :statisticsDatas="statisticsDatas"
                  :sortDatas="sortDatas"
                  :getCheckboxFiled="getCheckboxFiled"
                  :detailToTopTo="detailToTopTo"
                  @clickImg="clickImg"
                  @sortingOrder="sortingOrder"
                  @deleteGroupRow="deleteGroupRow"
                  @deleteGroupColumn="deleteGroupColumn"
                  @deleteColumn="deleteColumn"
                  @clickColOperation="clickColOperation"
                  @statisticsCurs="statisticsCurs"
                  @removeConditions="removeConditions"
                  @setDetailToTop="setDetailToTop"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 编辑汇总公式 -->
      <summaryFormula
        v-show="summaryFormulaTo"
        :summaryTree="summaryTree"
        :formulaHeader="formulaHeader"
        :summaryFormulaId="summaryFormulaId"
        :expressions="expressions"
        :summaryFormulaTo="summaryFormulaTo"
        :formulaInfos="formulaInfos"
        @summaryFormulaCancel="summaryFormulaCancel"
        @lineFormulaApply="lineFormulaApply"
        @summaryFormulaApply="summaryFormulaApply"
      />
      <!-- 保存报表 -->
      <saveReport
        ref="saveReportRef"
        :saveReportTo="saveReportTo"
        @saveReportCancel="saveReportCancel"
        @saveReportConfirm="saveReportConfirm"
      ></saveReport>
      <!-- 保存并关闭弹框 -->
      <saveClone
        :saveCloneTo="saveCloneTo"
        @saveCloneCancel="saveCloneCancel"
        @saveCloneClone="saveCloneClone"
        @saveAndClose="clickSave"
      ></saveClone>
      <!-- 导出弹框 -->
      <export-report
        ref="exportReportRef"
        :exportReportTo="exportReportTo"
        @exportBoxCancels="exportBoxCancels"
        :reportId="$route.params.reportId"
        :totalPages="totalPages"
        :totalSize="totalrecords"
        :cloneParamsObject="cloneParamsObject"
        :iscloneReport="iscloneReport"
        :exportReportMax="exportReportMax"
        @exportCloneReport="exportCloneReport"
        @firstSaveExportReport="firstSaveExportReport"
      />
      <!-- 另存为弹框 -->
      <nextSaveReport
        v-show="nextSaveReportTo"
        :nextSaveReportTo="nextSaveReportTo"
        :reportNameNote="reportNameNote"
        @nextSaveReportCancel="nextSaveReportCancel"
      ></nextSaveReport>
      <!-- 删除弹框 -->
      <deleteReport
        :deleteReportTo="deleteReportTo"
        :reportInfos="reportNameNote"
        @deleteReportCancel="deleteReportCancel"
      ></deleteReport>
    </div>
  </div>
</template>

<script>
import exportReport from "../reportList/components/exportReport"; // 导出报表弹框
import deleteReport from "../reportList/components/deleteReport"; // 删除报表弹框
import { mergeTableRow, mergeTableCoulmn } from "@/config/table/mergeTableRow";
import FILTERVALUEINPUTTYPE from "@/config/enumCode/inputType";
import operator from "@/config/enumCode/operator.js"; // 过滤条件的字段可操作项
import * as types from "@/store/mutations-types";
import reportFolderPermissionsMin from "@/mixin/reportFolderPermissions.js"; // 关于报表文件夹权限：编辑，删除
import * as componentsIndex from "./components/index";
import * as ReportObjectApi from "../api.js"; // 请求api
import { setValueTypeConversion } from "@/config/filter/commaProcessing";
import sameMonthReport from "../editReport/components/sameMonthReport"; //同环比概要
import sameMonthTable from "../editReport/components/sameMonthTable"; //同环比表格图表
import { mapState } from "vuex";

export default {
  components: {
    ...componentsIndex,
    exportReport,
    deleteReport,
    sameMonthReport,
    sameMonthTable,
  },
  mixins: [reportFolderPermissionsMin],
  data() {
    return {
      pageSize: "1", //页数
      pageCount: "", //页数总和
      dateFieldId: "", //日期字段的id
      dateFieldName: "", //日期字段的name
      summaryFieldName: "", //数值金额进行汇总字段name
      summaryFieldID: "", //数值金额进行汇总字段id
      chooseDimension: "", //日期字段id
      chooseStatisticaLindicators: "", //数值，金额字段id
      sameMonthStarttime: "", //同环比的开始时间
      sameMonthEndtime: "", //同环比的结束时间
      AllFiledlistSummaryField: [], //对象的数值和金额类型字段。
      listSummaryField: [], //对象的数值和金额类型字段。
      sameMonthReportFlag: false, //是否是同比环比表
      reportEchartsLoading: false, //图表的loading
      reportLoading: false, //报表数据的loading
      reportName: "", //报表名字
      isshowcharts: "", //是否显示图表按钮
      isshowRowCount: "", //是否显示行计数按钮
      isshowDetailedInfor: "", //是否显示详细信息行按钮
      screenCondition: "", //筛选条件名称
      formula: false, // 是否有应用的公式
      formCalculate: "", // 公式内容
      durationLists: [], // 持续时间下拉内容
      allSelectFileds: [], // 报表的数据源对象
      allFiledDatas: [], // 可筛选的下拉内容的即字段列表
      groupIds: [], // 所有分组字段id集合
      chooseColumnTypes: [], // 列分组的字段内容
      transversegroupList: [],
      lengthwaysgroupList: [],
      chooseRowFileds: [], // 行分组字段集合
      choosedColumnFileds: [], // 列分组字段集合
      rowColumnData: [], // 矩阵式表格，表格内容
      timeRanges: [], // 选择的时间范围字段信息
      choosedTimeRanges: {}, // 选择的时间范围筛选条件
      choosedFilterFileds: [], // 选择的过滤条件字段信息集合
      tabCur: 1, // tab选项
      listCheckedFields: [], // 选择的报表列表头字段集合
      listCheckedFieldsArr: [], // 本地选择的报表列字段集合
      tableData: [], // 列表表格、汇总表格
      TabulartableData: [], //列表式报表表格数据
      alltransversegroupList: [], //行分组数据
      MatrixtableData: [], //矩阵式报表
      MatrixtlengthwaysgroupList: [], //矩阵式报表行数据
      SummarytableData: [], //汇总式报表数据
      SummaryAndMatrixMapTotal: {}, //汇总式和矩阵式的合计字段
      TabularPagenum: 1, //列表式页数
      myreportType: "", //报表类型
      formulaCur: "", // 选择的行级汇总公式
      summaryFormulaTo: false, // 汇总公式弹框
      formulaHeader: "", // 汇总公式弹框标题
      summaryFormulaId: "", // 汇总公式apiname
      roleid: "", // 选中角色id
      allRoles: [], // 所有角色
      currentRole: {}, // 当前角色
      subordinateRoles: [], // 下级角色
      middleRoleTo: false, // 是否显示中间角色
      middleRole: {}, // 中间角色
      allObjects: {}, // 数据源对象
      mainobjectcolumnid: [], // 主对象id集合
      saveReportTo: false, // 保存报表弹框
      totalrecords: 0, // 记录数
      matrixCounts: 0, // 矩阵式报表的统计数
      loading: true, // 表格loading，一进来就加载
      loadingDetail: false, // 矩阵式报表的详表loading
      listTableDetail: [], // 矩阵式报表的详细表数据
      AlllistTableDetail: [], //矩阵式报表详细信息行的全部数据
      DetailPagenum: 1, //矩阵的详细信息行滚动加载次数
      expressions: "", // 行级公式
      formulaInfos: "", // 行级公式信息
      summaryTree: [], // 行级汇总公式左侧树数据
      expressionLine: [], // 行级公式
      expressionSummary: [], // 汇总公式
      conditionVals:
        '{"data":[],"filter":"","relatedObjIdC":"","relatedObjIdD":""}', // 过滤条件
      indexData: {}, // clickImg事件传入子组件数据
      transversedatetypeone: "month", // 一级行分组的日期分组依据
      transversedatetypetwo: "month", // 二级行分组的日期分组依据
      transversedatetypethree: "month", // 三级行分组的日期分组依据
      lengthwaysdatetypeone: "month", // 一级列分组的日期分组依据
      lengthwaysdatetypetwo: "month", // 二级列分组的日期分组依据
      tableCheckId: "", //点击下拉三角返回的id
      detailConditions: "{}", // 矩阵式报表点击统计表的单元格选中内容
      cellArr: [], // 选中的单元格数据
      gatherfieldname: [], // 求和字段id集合
      avgfield: [], // 平均值字段id集合
      maxfield: [], // 最大值字段id集合
      minfield: [], // 最小值字段id集合
      gatherFieldList: [], // 求和字段集合
      listAvgFields: [], // 平均值字段集合
      listMaxFields: [], // 最大值字段集合
      listMinFields: [], // 最小值字段集合
      refreshTo: false, // 是否需要点击刷新
      showRefresh: false, // 是否显示点击刷新按钮
      allObjectDatas: [], // 表头业务对象数据
      isApply: false, // 时间范围筛选器是否在被使用
      tbhbexpression: "", // 同比环比计算的数据
      exportReportTo: false, // 导出弹框
      exportReportMax:0,//报表导出最大值
      saveCloneTo: false, // 保存并关闭弹框
      totalPages: "", // 报表导出显示的总页数
      nextSaveReportTo: false, // 另存为弹框
      deleteReportTo: false, // 删除弹框
      runTo: false, // 是否点击运行
      reportDataTC: [], //报表添加表格图表：回显数据
      folderPermissions: true, // 报表所在文件夹是否可编辑,
      mapTaskRel: {}, // 需要根据api值获取数据
      chartInfos: {}, // 报表中要添加的图表数据
      chartInfoDatas: {}, // 根据报表获取的图表数据
      runShowDataTo: false, // 运行状态下是否仅显示的2000条数据
      pageSource: "", // 页面上一页来源，主要为了区分是否首次新建报表
      isHavaMoreColumns: false, // 矩阵式报表，如果列超出限制，需要在合计前加一列，全部数据展示为...
      paramsObject: {}, // 获取报表数据的入参，params
      cloneParamsObject: {}, // 获取报表数据的入参，params
      iscloneReport:'',//导出时是否保存了报表
      tempId: "", // 用于存储其他页面传来的报表id（因为第二次请求需要清空id）
      tempReporttypecustomid: "", // 用于存储其他页面传来的报表Reporttypecustomid，在最后保存时传过去
      tempDataSize: "50", // 定义一个全局的dataSize，用在两个地方：获取报表数据的数据量以及获取矩阵式报表下面详表的数据量
      temName: "", // 报表名称
      dateGroupings: {
        // 获取数据传给子组件日期分组依据
        transversedatetypeone: "",
        transversedatetypetwo: "",
        transversedatetypethree: "",
        lengthwaysdatetypeone: "",
        lengthwaysdatetypetwo: "",
      },
      statisticsDatas: {
        // 统计数据信息
        gatherfieldname: "",
        avgfield: "",
        maxfield: "",
        minfield: "",
      },
      sortDatas: {
        // 排序字段信息
        orderfieldapi: "",
        orderfieldtype: "",
        ordertype: "",
      },
      sortDatasObject: {
        // 本地排序字段信息
        orderfieldapi: "",
        orderfieldtype: "",
        ordertype: "",
      },
      reportNameNote: {
        // 另存为时的报表名称和备注
        reportId: "",
        reportLabel: "",
        description: "",
        reportfolderid: "",
      },
      reportInfoToChart: {
        groupList: "", // 获取分组的下拉选项
        gatherFieldList: "", // 总和
        listAvgFields: "", // 平均值
        listMaxFields: "", // 最大值
        listMinFields: "", // 最小值
        lengthwaysgroupone: "", // 分组一的id
        lengthwaysgrouptwo: "", // 分组二的id
        transversegroupone: "", // 分组一的id
        transversegrouptwo: "", // 分组二的id
        transversegroupthree: "", // 分组三的id
        lengthwaysdatetypeone: "", // 分组一的日期分组依据
        lengthwaysdatetypetwo: "", // 分组二的日期分组依据
        transversedatetypeone: "", // 分组一的日期分组依据
        transversedatetypetwo: "", // 分组二的日期分组依据
        transversedatetypethree: "", // 分组三的日期分组依据
        reportTC: "", //表格图表数据
        DashboardListSummaryField: "", //统计字段
        DashboardListCheckedFields: "", // 排序字段
      },
      isFirstRequest: true, // 是否为报表数据接口的第一次请求，默认值为true
      displayFieldsTo: false, // 控制是否展示字段列表
      filterTos: false, // 运行状态下是否展示过滤器
      generateChartTos: false, // 是否展示图表
      detailButts: false, // 是否展示详细信息行
      automaticUpdates: false, // 是否开启自动更新
      detailToTopTo: false, // 详细信息表是否置顶
      isOptimization: this.$route.query.isOptimization
        ? JSON.parse(this.$route.query.isOptimization)
        : false, //是否是已优化的报表
    };
  },
  created() {
    document.title = this.$setTitle(this.$i18n.t("label.visualpage.btype"));
    this.getFolders(); // 获取报表文件夹列表
    // 监听页面刷新
    window.addEventListener("beforeunload", this.beforeunloadFn);
    if (this.$route.params.reportId != undefined) {
      /**
       * 如果有报表ID
       * （即列表页，通过点击编辑报表进来时，携带ID的情况）
       */
      this.tempId = this.$route.params.reportId;
      this.runTo = this.$route.params.type === "run" ? true : false;
      if (this.runTo) {
        // 通过名称进来运行页时直接传空，后端会根据报表类型取对应数据，如果有统计信息则取全部，没有则仅取前2000条
        this.tempDataSize = "";
      }
    } else {
      /**
       * 如果从数据源新建报表时，会带上 allObjects 参数，用于选择分组方式的参数请求
       */
      let sourceData = JSON.parse(sessionStorage.getItem("sourceData"));
      this.allObjects = sourceData.allObjects;
      // 如果是同环比报表，时间对象默认：创建时间，
      if (
        this.allObjects.objecttype == "2" ||
        this.allObjects.objecttype == "ratio"
      ) {
        // 设置默认开始时间当前日期前6个月,结束时间当前月份
        let start = new Date();
        let end = new Date();
        start.setMonth(start.getMonth() - 6);
        this.$moment(start).format("YYYY-MM-DD");
        this.sameMonthStarttime = this.$moment(start).format("YYYY-MM") + "-01";
        this.sameMonthEndtime =
          this.$moment(end).format("YYYY-MM") + "-" + this.endDayTime(end);
        this.sameMonthReportFlag = true;
      }
      this.runTo = sourceData.runTo;
      this.pageSource = "dataSource"; // 报表新建，第一次进来
    }
  },
  async mounted() {
    // 从列表页直接调进报表运行页直接调接口
    // 从列表页进编辑页或者是新建报表还是先走前两个接口
    if (this.$route.params.reportId && this.runTo) {
      this.getPromiseData();
    } else {
      this.getPromiseData();
    }
    this.$bus.$on("getReportDataByDetailsBus",this.getReportDataByDetailsfn);
    // 报表显示图表且图表为双轴图对排序方式和排序方式类型赋值;
    this.$bus.$on("setBiaxialSortOrder",this.setBiaxialSortOrderfn);
    // 组行、组列、报表列、表格的列拖拽
    this.$bus.$on("dragRowColumnReportColumnsBus",this.dragRowColumnReportColumnsfn);
  },
  beforeDestroy() {
    this.$bus.$off("getReportDataByDetailsBus",this.getReportDataByDetailsfn);
    this.$bus.$off("dragRowColumnReportColumnsBus",this.dragRowColumnReportColumnsfn);
    this.$bus.$off("setBiaxialSortOrder",this.setBiaxialSortOrderfn);
  },
  watch: {
    // 监听运行状态
    runTo: async function () {
      if (this.runTo) {
        // 控制只要进来运行页就开启自动刷新
        this.$refs.rightTopRef.form.automaticUpdate = true;
        this.automaticUpdates = true;
        // 运行状态
        if (!this.tempId) {
          // 不是第一次进来运行页才执行以下操作
          this.tabCur = 0;
          // 在运行页列表式显示2千条数据
          // 列表式报表添加了分页
          // 汇总式和矩阵式报表传空，然后截取接口返回数据显示2千条
          if (
            this.transversegroupList.length === 0 &&
            this.lengthwaysgroupList.length === 0
          ) {
            // 列表式报表做了分页
            this.runShowDataTo = false;
            this.tempDataSize = "2000";
          } else {
            this.runShowDataTo = true;
            this.tempDataSize = "";
          }
          await this.getReportDataByDetails();
        }
      }
    },
    // 监听报表文件夹列表，当获取文件夹接口返回后，获取文件夹的编辑权限
    allFolderlist: {
      handler() {
        if (this.$route.params.folderId) {
          // 根据文件夹id获取文件夹的编辑权限
          this.folderPermissions = this.getReportFolderPermissions(
            this.$route.params.folderId,
            this.allFolderlist
          ).isedit;
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听组列数据改变，设置详细信息表置顶的按钮为false
    choosedColumnFileds: {
      handler() {
        if (!this.showRefresh) {
          this.detailToTopTo = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // $bus事件优化
    getReportDataByDetailsfn(e, dynamicdata){
      this.chartInfos = e;
      this.getReportDataByDetails(dynamicdata);
    },
    // 报表显示图表且图表为双轴图对排序方式和排序方式类型赋值
    setBiaxialSortOrderfn(file, type){
      this.sortDatasObject.orderfieldapi = file;
      this.sortDatasObject.orderfieldtype = type;
    },
    // 组行、组列、报表列、表格的列拖拽
    dragRowColumnReportColumnsfn(e){
      this.dragReportColumnsBus(e);
    },
    // 修改页数
    handleCurrentChange(val) {
      this.pageSize = val.toString();
      this.getReportDataByDetails();
    },
    // 滚动加载时，根据报表类型的不同追加数据
    loadMoreData(total, type) {
      // 列表式式报表
      if (this.myreportType == "Tabular") {
        this.tableData = this.tableData.concat(
          this.TabulartableData.slice((total - 1) * 50, total * 50)
        );
        if (total - 1 == this.TabularPagenum && total - 1 != 1) {
          this.SummaryAndMatrixMapTotal.level01 = "maxtotal";
          this.SummaryAndMatrixMapTotal.totalrecord =
            this.TabulartableData.length;
          this.tableData.push(this.SummaryAndMatrixMapTotal);
        }
      }
      // 汇总式报表
      if (this.myreportType == "Summary") {
        this.tableData = this.tableData.concat(
          this.SummarytableData.slice((total - 1) * 50, total * 50)
        );
        let mergeRowArr = [];
        this.alltransversegroupList.map((item) => {
          mergeRowArr.push(item.schemefield_name_alias);
        });
        if (total - 1 == this.TabularPagenum && total - 1 != 1) {
          this.SummaryAndMatrixMapTotal.level01 = "maxtotal";
          this.tableData.push(this.SummaryAndMatrixMapTotal);
        }
        if(this.tableData && this.tableData.length){
          this.tableData = mergeTableRow(this.tableData, mergeRowArr);
        }
      }
      // 矩阵式报表
      if (this.myreportType == "Matrix" && !type) {
        this.rowColumnData = this.rowColumnData.concat(
          this.MatrixtableData.slice((total - 1) * 50, total * 50)
        );
        let mergeRowArr = [];
        this.MatrixtlengthwaysgroupList.map((item) => {
          mergeRowArr.push(item.schemefield_name_alias);
        });
        if (total - 1 == this.TabularPagenum && total - 1 != 1) {
          this.rowColumnData.push(this.SummaryAndMatrixMapTotal);
        }
        this.rowColumnData = mergeTableCoulmn(this.rowColumnData, mergeRowArr);
      }
      // 矩阵式报表的详细信息行
      if (type == "matrixDetail" && this.myreportType == "Matrix") {
        this.listTableDetail = this.listTableDetail.concat(
          this.AlllistTableDetail.slice((total - 1) * 50, total * 50)
        );
      }
    },
    // 请求报表数据需要2个接口返回值
    getPromiseData(id) {
      //1、getObjectAllField：根据对象来获取全部字段接口以及传参
      //2、getRoleTreeAndDurationList：获取角色树和报表持续时间接口以及传参
      let paramsOne = {};
      let paramsTwo = {};
      if (!this.$route.params.reportId) {
        paramsOne = {
          objecta: this.allObjects.objecta ? this.allObjects.objecta : "",
          objectb: this.allObjects.objectb ? this.allObjects.objectb : "",
          objectc: this.allObjects.objectc ? this.allObjects.objectc : "",
          objectd: this.allObjects.objectd ? this.allObjects.objectd : "",
        };
      } else {
        paramsOne = {
          id: id?id:this.$route.params.reportId,
        };
      }
      Promise.all([
        this.getObjectAllField(paramsOne),
        this.getRoleTreeAndDurationList(paramsTwo),
      ])
        .then((res) => {
          this.reportDataTC = res[0].data.reportTC; //报表添加表格图表：回显数据
          // 获取筛选条件字段列表
          this.allFiledDatas = [];
          this.allSelectFileds = [];
          // 对象A
          if (
            this.reportDataTC.listObjectAField &&
            this.reportDataTC.listObjectAField.length > 0
          ) {
            this.reportDataTC.listObjectAField.map((item) => {
              this.allFiledDatas.push(item);
            });
            this.allSelectFileds.push({
              id: this.reportDataTC.objecta,
              label_name: this.reportDataTC.objectALabel,
              fieldList: this.reportDataTC.listObjectAField,
            });
          }
          // // 对象B
          if (
            this.reportDataTC.listObjectBField &&
            this.reportDataTC.listObjectBField.length > 0
          ) {
            this.reportDataTC.listObjectBField.map((item) => {
              this.allFiledDatas.push(item);
            });
            this.allSelectFileds.push({
              id: this.reportDataTC.objectb,
              label_name: this.reportDataTC.objectBLabel,
              fieldList: this.reportDataTC.listObjectBField,
            });
          }
          // 对象C
          if (
            this.reportDataTC.listObjectCField &&
            this.reportDataTC.listObjectCField.length > 0
          ) {
            this.reportDataTC.listObjectCField.map((item) => {
              this.allFiledDatas.push(item);
            });
            this.allSelectFileds.push({
              id: this.reportDataTC.objectc,
              label_name: this.reportDataTC.objectCLabel,
              fieldList: this.reportDataTC.listObjectCField,
            });
          }
          // 对象D
          if (
            this.reportDataTC.listObjectDField &&
            this.reportDataTC.listObjectDField.length > 0
          ) {
            this.reportDataTC.listObjectDField.map((item) => {
              this.allFiledDatas.push(item);
            });
            this.allSelectFileds.push({
              id: this.reportDataTC.objectd,
              label_name: this.reportDataTC.objectDLabel,
              fieldList: this.reportDataTC.listObjectDField,
            });
          }
          if (this.$route.params.reportId) {
            this.getConditionsByReportId(); // 获取已应用的过滤条件
          }
          this.timeRanges = res[0].data.listDateField; //获取时间范围下拉列表
          this.AllFiledlistSummaryField = res[0].data.listSummaryField; //对象的数值和金额类型字段。
          //获取报表持续时间
          let duration = res[1].data.duration;
          //获取当前人的角色及下属角色树
          if (res[1].data.roleList.length > 0) {
            let roleList = res[1].data.roleList;
            this.allRoles = roleList;
            this.currentRole = roleList[0];
            this.subordinateRoles = roleList.filter(
              (item) =>
                item.parentroleid == this.currentRole.roleid &&
                item.roleid != this.currentRole.roleid
            );
            this.middleRoleTo = false;
            this.roleid = "";
          } else {
            this.allRoles = [];
          }
          this.setTimeData(duration);
          // 新建报表时，增加默认筛选项，时间范围中，主对象创建时间为今年
          if (this.$route.params.reportId == undefined && this.timeRanges) {
            this.pageSource = "dataSource"; // 报表新建，第一次进来
            // 查找创建时间的id
            this.$refs.filterSetRef.form.timeRange = this.timeRanges.find(
              (item) => item.schemefield_name == "createdate"
            ).id;
            this.$refs.filterSetRef.form.durationList = "cury"; // 日历年度
            this.$refs.filterSetRef.form.durationListLabel = "当前日历年度";
            this.changeDemonstration("cury"); // 选择持续时间
            this.applyTimeRange(); // 应用时间范围
          }
          // 首次进编辑页
          if (this.$route.params.reportId && !this.runTo) {
            this.getReportDataByDetails();
          }
          // 首次进运行页
          if (this.$route.params.reportId && this.runTo) {
            this.getReportDataByDetails();
          }
          // if (!this.$route.params.reportId && !this.runTo) {
          //   this.getReportDataByDetails();
          // }
          this.getParamsData(); // 先初始化获取报表数据的入参params
        })
        .catch(() => {
          return false;
        });
    },
    // 拼接结束日期 日
    endDayTime(value) {
      let data = new Date(value);
      let year = data.getFullYear();
      let month = data.getMonth() + 1;
      // 判断是否未闰年
      if (
        month == "1" ||
        month == "3" ||
        month == "5" ||
        month == "7" ||
        month == "8" ||
        month == "10" ||
        month == "12"
      ) {
        return "31";
      } else if (
        month == "4" ||
        month == "6" ||
        month == "9" ||
        month == "11"
      ) {
        return "30";
      } else if (
        (month == "2" && year % 4 == 0 && year % 100 != 0) ||
        year % 400 == 0
      ) {
        return "28";
      } else {
        return "29";
      }
    },
    // 同环比报表切换日期类型对象
    changeObjectOne(value) {
      if (value) {
        this.chooseDimension = value;
        this.chooseRowFileds = [];
        this.chooseRowFileds.push(value);
        this.isFullCondition();
      } else {
        this.chooseDimension = value;
        this.chooseRowFileds = [];
      }
    },
    // 同环比报表切换数值和金额类型
    changeObjectTwo(value) {
      if (value) {
        this.chooseStatisticaLindicators = value;
        this.gatherfieldname = [];
        this.gatherfieldname.push(value);
        this.isFullCondition();
      } else {
        this.chooseStatisticaLindicators = "";
        this.gatherfieldname = [];
      }
    },
    //  同环比报表切换开始时间
    changeStartTime(value) {
      if (value) {
        this.sameMonthStarttime = value;
        this.isFullCondition();
      } else {
        this.sameMonthStarttime = "";
      }
    },
    //  同环比报表切换结束时间
    changeEndTime(value) {
      if (value) {
        this.sameMonthEndtime = value + "-" + this.endDayTime(value);
        this.isFullCondition();
      } else {
        this.sameMonthEndtime = "";
      }
    },
    // 判读同环比筛选条件是否都满足
    isFullCondition() {
      if (
        this.chooseRowFileds?.length > 0 &&
        this.gatherfieldname?.length > 0 &&
        this.sameMonthEndtime &&
        this.sameMonthStarttime
      ) {
        // 开始时间结束时间，日期维度和分组依据有值才可调取接口
        this.getReportDataByDetails();
      }
    },
    // 点击图表按钮返回事件,判断是否已有图表数据，有的话切换显示隐藏状态，无请求接口
    getChartReport() {
      this.reportEchartsLoading = true;
      if (this.chartInfoDatas && this.chartInfoDatas.groupOneArr) {
        this.reportEchartsLoading = false;
      } else {
        this.getChartDataByReport();
      }
    },
    // 获取报表中图表的接口
    async getChartDataByReport(dynamicdata) {
      // 有id，无图表；无id，在新增
      if (
        (!this.$route.params.reportId ||
          (!this.isshowcharts && this.$route.params.reportId)) &&
        this.isFirstRequest
      ) {
        this.chartInfos = {
          dashboardtype: "bar_0", //图表的类型
          xcon: "totalrecord", //存储统计的字段ID
          summaryway: "sum", //汇总方式，avg：求平均值;max:求最大值; min:最小值  sum:求和。
          xgather: "", //存储第二个分组字段的ID
          ycon:
            this.reportInfoToChart.groupList.length > 0
              ? this.reportInfoToChart.groupList[0].id
              : "", //存储第一个分组字段的ID
          unit: "integral", //显示单位 ，aoto:selected;integral:整数单位;ten thousand:万;ten million:千万;billion:亿;
          dashboardsortcondition: "value", //排序条件，label:标签;value:数值
          dashboardsorttype: "desc", //排序方式，asc:升序;desc:降序;
          max: "", //指针图表区域的最大值
          mid1: "", //指针图表区域的第一中间值
          mid2: "", //指针图表区域的第二中间值
          min: "", //指针图表区域的最小值
          recordnum: "5", //显示最大记录数
          isshowvalue: "true", //格式化里面的显示数值，true:显示;false:不显示。
          isshowpercent: "false", //格式化里面的显示百分比，true:显示;false:不显示。
          onegroupdatetype: "month", //日期分组依据
        };
      }
      let obj = Object.assign(this.paramsObject, this.chartInfos);
      await ReportObjectApi.getChartDataByReport(obj)
        .then((datas) => {
          if (datas.result) {
            if (dynamicdata == "dynamic") {
              this.$bus.$emit("setDynamicChartInfoDatas", datas.data);
            } else {
              this.chartInfoDatas = datas.data;
            }
            let reportInfoData = datas.data.reportInfo;
            // 有报表id且已有图表
            this.chartInfos = {
              dashboardtype: reportInfoData.dashboardtype, //图表的类型
              xcon: reportInfoData.xcon, //存储统计的字段ID
              summaryway: reportInfoData.summaryway, //汇总方式，avg：求平均值;max:求最大值; min:最小值  sum:求和。
              xgather: reportInfoData.xgather, //存储第二个分组字段的ID
              ycon: reportInfoData.ycon, //存储第一个分组字段的ID
              unit: reportInfoData.unit, //显示单位 ，aoto:selected;integral:整数单位;ten thousand:万;ten million:千万;billion:亿;
              dashboardsortcondition: reportInfoData.dashboardsortcondition, //排序条件，label:标签;value:数值
              dashboardsorttype: reportInfoData.dashboardsorttype, //排序方式，asc:升序;desc:降序;
              max: reportInfoData.max, //指针图表区域的最大值
              mid1: reportInfoData.mid1, //指针图表区域的第一中间值
              mid2: reportInfoData.mid2, //指针图表区域的第二中间值
              min: reportInfoData.min, //指针图表区域的最小值
              recordnum: reportInfoData.recordnum, //显示最大记录数
              isshowvalue: reportInfoData.isshowvalue, //格式化里面的显示数值，true:显示;false:不显示。
              isshowpercent: reportInfoData.isshowpercent, //格式化里面的显示百分比，true:显示;false:不显示。
              onegroupdatetype: reportInfoData.transversedatetypeone, //日期分组依据
            };
            this.reportEchartsLoading = false;
            this.isFirstRequest = false; // 第一次请求完成，不论为新增还是已有报表id
          } else {
            this.chartInfoDatas = null;
          }
        })
        .catch(() => {
          // 报错不展示图表
          this.generateChartTos = false;
        });
    },
    // 切换tab
    changeTab(index) {
      this.tabCur = index;
    },
    // 切换详细信息行按钮：一共4种情况：列表式报表不显示详细信息行按钮
    // 汇总式报表不显示详情、汇总式报表显示详情、矩阵式报表的统计表、矩阵式报表的详情表
    async changeDetailButt(detailButt) {
      this.detailButts = detailButt;
      this.detailToTopTo = false;
      if (this.runTo) {
        // 运行状态切换详细信息行按钮：汇总式报表显示详情为2000，其余情况为全部
        if (
          this.transversegroupList.length >= 1 &&
          this.lengthwaysgroupList.length === 0 &&
          detailButt
        ) {
          this.runShowDataTo = true;
          this.tempDataSize = "2000";
        } else if (
          this.transversegroupList.length >= 1 &&
          this.lengthwaysgroupList.length === 1
        ) {
          this.runShowDataTo = true;
          this.tempDataSize = "";
        }
      } else {
        // 编辑状态切换详细信息行按钮
        this.tempDataSize = "50";
      }
      if (
        this.lengthwaysgroupList.length > 0 &&
        this.transversegroupList.length > 0
      ) {
        // 矩阵式报表切换详细信息行时仅需请求下面的详情表，不需请求统计表
        if (this.runTo) {
          this.tempDataSize = "";
          this.runShowDataTo = true;
        } else {
          this.tempDataSize = "50";
        }
        // 判断是在开启详细信息行还是在关闭
        if (detailButt) {
          this.removeConditions();
          // 在已经开启图表按钮的情况下，在开启详细信息行按钮，刷新图表
          if (this.generateChartTos) {
            this.reportEchartsLoading = true;
            await this.getChartDataByReport();
          }
        } else {
          this.$refs.matrixTableRef.selectRow = "";
          this.$refs.matrixTableRef.selectColumn = "";
          // 在已经开启图表按钮的情况下，在关闭详细信息行按钮，刷新图表
          if (this.generateChartTos) {
            this.reportEchartsLoading = true;
            await this.getChartDataByReport();
          }
        }
        return;
      }
      await this.getReportDataByDetails();
    },
    // 组行、组列、报表列之间的拖拽
    dragReportColumnsBus(e) {
      // 数组去重
      e.chooseRowFiledsData = this.unique(e.chooseRowFiledsData);
      e.choosedColumnFiledsData = this.unique(e.choosedColumnFiledsData);
      e.chooseReportColumnsData = this.unique(e.chooseReportColumnsData);
      // 组行数据同步
      this.chooseRowFileds = JSON.parse(JSON.stringify(e.chooseRowFiledsData));
      // 判断添加完组行后，是否只有一个行分组，此时生成的图表选择的分组需要重新获取一下
      if (this.chooseRowFileds.length === 1) {
        this.groupIsDeleted(this.chooseRowFileds[0].id);
      }
      // 组列数据同步
      this.choosedColumnFileds = JSON.parse(
        JSON.stringify(e.choosedColumnFiledsData)
      );
      // 报表列数据同步
      this.listCheckedFieldsArr = JSON.parse(
        JSON.stringify(e.chooseReportColumnsData)
      );
      // 修改入参mainobjectcolumnid传入报表列的顺序，且在添加组行组列时需要判断mainobjectcolumnid中是否包含组行组列的字段
      this.mainobjectcolumnid = [];
      this.listCheckedFieldsArr && this.listCheckedFieldsArr.map((item) => {
        this.mainobjectcolumnid.push(item.id);
      });
      this.chooseRowFileds && this.chooseRowFileds.map((item) => {
        if (this.mainobjectcolumnid.every((item1) => item1.id !== item.id)) {
          this.mainobjectcolumnid.push(item.id);
        }
      });
      this.choosedColumnFileds && this.choosedColumnFileds.map((item) => {
        if (this.mainobjectcolumnid.every((item1) => item1.id !== item.id)) {
          this.mainobjectcolumnid.push(item.id);
        }
      });
      // 给分组id集合重新赋值
      this.groupIds = [];
      this.chooseRowFileds && this.chooseRowFileds.map((item) => {
        this.groupIds.push(item.id);
      });
      this.choosedColumnFileds && this.choosedColumnFileds.map((item) => {
        this.groupIds.push(item.id);
      });
      this.getReportDataByDetails();
    },
    // 数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].id == arr[j].id) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //监听刷新
    beforeunloadFn(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = this.$i18n.t(
          "vue_label_report_notice_leavecertification"
        );
      }
      return this.$i18n.t("vue_label_report_notice_leavecertification");
    },
    // 关闭保存并关闭弹框
    saveCloneClone() {
      this.saveCloneTo = false;
    },
    //点击丢弃
    saveCloneCancel() {
      if(this.iscloneReport=='save'){
        this.exportCloneReport();
      }
      this.saveCloneTo = false;
      this.$store.commit(types.SET_EDITPAGE_FIRST_ID, "");
      // 退出时，清空保存时，保存的ID
      this.$store.commit(types.SAVE_TEMP_REPORTTYPECUSTOID, "");
      // 退出时,清空保存到vuex中的请求参数(编辑到运行,运行到编辑)
      this.$store.commit(types.SET_REPORTINFOS, {});
      // 清空缓存
      this.$store.commit(types.SAVE_CHOOSEDTIMERANGES, {});
    },
    // 排序：升序、降序
    sortingOrder(item, type, tableType) {
      this.sortDatasObject = {
        orderfieldapi: item.schemefield_name_alias,
        orderfieldtype: item.schemefieldType,
        ordertype: type,
      };
      if (tableType) {
        this.getReportDetail(); // 矩阵式报表的详表排序不需保存，实时的就可。
      } else {
        // 其他类型表的排序
        this.getReportDataByDetails();
      }
    },
    // 日期分组依据
    clickColOperation(data) {
      let rowArr = this.chooseRowFileds;
      let columnArr = this.choosedColumnFileds;
      if (rowArr.some((item) => item.id == this.indexData.id)) {
        //属于行分组
        let index = rowArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            this.transversedatetypeone = data;
            break;
          case 1:
            this.transversedatetypetwo = data;
            break;
          case 2:
            this.transversedatetypethree = data;
            break;
        }
      } else {
        let index = columnArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            this.lengthwaysdatetypeone = data;
            break;
          case 1:
            this.lengthwaysdatetypetwo = data;
            break;
        }
      }
      this.getReportDataByDetails();
    },
    //  统计
    statisticsCurs(data) {
      // 保存上次选中的条件，判断是否超过5个统计值
      let oldgatherfieldname = JSON.parse(JSON.stringify(this.gatherfieldname));
      let oldavgfield = JSON.parse(JSON.stringify(this.avgfield));
      let oldmaxfield = JSON.parse(JSON.stringify(this.maxfield));
      let oldminfield = JSON.parse(JSON.stringify(this.minfield));
      if (data.length === undefined) {
        // 直接操作表头列
        if (data == "sum") {
          if (this.gatherfieldname.some((item) => item === this.tableCheckId)) {
            // 删除已计算的总和字段
            let index = this.gatherfieldname.findIndex(
              (item) => item === this.tableCheckId
            );
            this.gatherfieldname.splice(index, 1);
          } else {
            this.gatherfieldname.push(this.tableCheckId); //总和
          }
        } else if (data == "avg") {
          if (this.avgfield.some((item) => item === this.tableCheckId)) {
            // 删除已计算的平均值字段
            let index = this.avgfield.findIndex(
              (item) => item === this.tableCheckId
            );
            this.avgfield.splice(index, 1);
          } else {
            this.avgfield.push(this.tableCheckId); //平均值
          }
        } else if (data == "max") {
          if (this.maxfield.some((item) => item === this.tableCheckId)) {
            // 删除已计算的最大值字段
            let index = this.maxfield.findIndex(
              (item) => item === this.tableCheckId
            );
            this.maxfield.splice(index, 1);
          } else {
            this.maxfield.push(this.tableCheckId); //最大值
          }
        } else if (data == "min") {
          if (this.minfield.some((item) => item === this.tableCheckId)) {
            // 删除已计算的最小值字段
            let index = this.minfield.findIndex(
              (item) => item === this.tableCheckId
            );
            this.minfield.splice(index, 1);
          } else {
            this.minfield.push(this.tableCheckId); //最小值
          }
        }
      } else {
        // 操作选择的报表列数值字段
        if (data.indexOf("sum") > -1) {
          // 该字段需统计总和
          if (
            this.gatherfieldname.every((item) => item !== this.tableCheckId)
          ) {
            // 不包含该字段
            this.gatherfieldname.push(this.tableCheckId); //总和
          }
        } else {
          if (this.gatherfieldname.some((item) => item === this.tableCheckId)) {
            // 判断是否包含该字段
            // 删除已计算的总和字段
            let index = this.gatherfieldname.findIndex(
              (item) => item === this.tableCheckId
            );
            this.gatherfieldname.splice(index, 1);
          }
        }
        if (data.indexOf("avg") > -1) {
          // 该字段需统计平均值
          if (this.avgfield.every((item) => item !== this.tableCheckId)) {
            // 不包含该字段
            this.avgfield.push(this.tableCheckId); //平均值
          }
        } else {
          if (this.avgfield.some((item) => item === this.tableCheckId)) {
            // 判断是否包含该字段
            // 删除已计算的平均值字段
            let index = this.avgfield.findIndex(
              (item) => item === this.tableCheckId
            );
            this.avgfield.splice(index, 1);
          }
        }
        if (data.indexOf("max") > -1) {
          // 该字段需统计最大值
          if (this.maxfield.every((item) => item !== this.tableCheckId)) {
            // 不包含该字段
            this.maxfield.push(this.tableCheckId); //最大值
          }
        } else {
          if (this.maxfield.some((item) => item === this.tableCheckId)) {
            // 判断是否包含该字段
            // 删除已计算的最大值字段
            let index = this.maxfield.findIndex(
              (item) => item === this.tableCheckId
            );
            this.maxfield.splice(index, 1);
          }
        }
        if (data.indexOf("min") > -1) {
          // 该字段需统计最小值
          if (this.minfield.every((item) => item !== this.tableCheckId)) {
            // 不包含该字段
            this.minfield.push(this.tableCheckId); //最小值
          }
        } else {
          if (this.minfield.some((item) => item === this.tableCheckId)) {
            // 判断是否包含该字段
            // 删除已计算的最小值字段
            let index = this.minfield.findIndex(
              (item) => item === this.tableCheckId
            );
            this.minfield.splice(index, 1);
          }
        }
        // 所有弹框均隐藏
        this.$refs.profileRef.$refs.popoverNumber.map((item) => {
          item.doClose();
        });
      }
      // 设置行计数按钮，判断是否要禁用
      let e = {
        gatherfieldname: this.gatherfieldname,
        avgfield: this.avgfield,
        maxfield: this.maxfield,
        minfield: this.minfield,
      };
      // 如果统计值超过5个就提示，选中样式恢复到符合条件的样式
      if (
        this.gatherfieldname.length +
          this.avgfield.length +
          this.maxfield.length +
          this.minfield.length >
        5
      ) {
        let totalSum = [];
        if (oldgatherfieldname.indexOf(this.tableCheckId) > -1) {
          totalSum.push("sum");
        }
        if (oldavgfield.indexOf(this.tableCheckId) > -1) {
          totalSum.push("avg");
        }
        if (oldmaxfield.indexOf(this.tableCheckId) > -1) {
          totalSum.push("max");
        }
        if (oldminfield.indexOf(this.tableCheckId) > -1) {
          totalSum.push("min");
        }
        this.$message.error(
          "已达到5个统计值的最大限制。可以删除现有的某个统计值，然后重试。"
        );
        this.$bus.$emit("cancelChooseType", totalSum);
      } else {
        this.$store.commit("setLineCountButtDisabled", e);
        this.getReportDataByDetails();
      }
    },
    // 取消报表列中的数值类型字段的弹框
    cancelSummary() {
      // 所有弹框均隐藏
      this.$refs.profileRef.$refs.popoverNumber.map((item) => {
        item.doClose();
      });
    },
    // 删除组行
    deleteGroupRow() {
      let rowArr = this.chooseRowFileds;
      let columnArr = this.choosedColumnFileds;
      if (rowArr.some((item) => item.id == this.indexData.id)) {
        let index = rowArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            rowArr.splice(index, 1);
            break;
          case 1:
            rowArr.splice(index, 1);
            break;
          case 2:
            rowArr.splice(index, 1);
            break;
        }
      } else {
        let index = columnArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            rowArr.splice(index, 1);
            break;
          case 1:
            rowArr.splice(index, 1);
            break;
        }
      }
      this.getReportDataByDetails();
    },
    // 删除组列
    deleteGroupColumn() {
      let rowArr = this.chooseRowFileds;
      let columnArr = this.choosedColumnFileds;
      if (rowArr.some((item) => item.id == this.indexData.id)) {
        let index = rowArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            columnArr.splice(index, 1);
            break;
          case 1:
            columnArr.splice(index, 1);
            break;
          case 2:
            columnArr.splice(index, 1);
            break;
        }
      } else {
        let index = columnArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            columnArr.splice(index, 1);
            break;
          case 1:
            columnArr.splice(index, 1);
            break;
        }
      }
      this.getReportDataByDetails();
    },
    // 删除列
    deleteColumn(index) {
      this.deleteReportColData(this.indexData.id, index);
    },
    // 点击表头三角图片获取到数据
    clickImg(item) {
      this.indexData = item;
      this.tableCheckId = this.indexData.id;
      // 点击DOM关闭该弹框
      this.$store.commit(types.SET_KEEPOPENKUANG, true);
    },
    // 获取应用的过滤条件
    async getConditionsByReportId() {
      let params = {
        id: this.$route.params.reportId,
      };
      let {
        data: { conditionList },
      } = await ReportObjectApi.getConditionsByReportId(params);
      // 回显已选择的过滤条件
      let dataFilter = {
        data: [],
        filter: "",
      };
      if (conditionList) {
        this.choosedFilterFileds = [];
        conditionList.map((item) => {
          // 根据fieldid(id)找到对应的该条数据
          let eachData = this.allFiledDatas.find((item1) => {
            return item1.id === item.fieldid;
          });
          this.choosedFilterFileds.push({
            fieldid: item.fieldid,
            prefix: eachData.prefix, // 对象前缀
            labelname: eachData.label_name,
            mainObjId: eachData.objid,
            // 操作下拉内容
            screeningOperation:
              operator.TYPE[
                eachData.schemefieldType === "Z"
                  ? eachData.expressionType
                  : eachData.schemefieldType
              ].getScreeningOpeTypes(),
            // 选择的操作
            operator: item.operator,
            // 字段类型
            type:
              eachData.schemefieldType === "Z"
                ? eachData.expressionType
                : eachData.schemefieldType,
            // 查询字段所对应的值的表现形式
            inputType: this.inputTypeConversion(
              eachData.schemefieldType,
              eachData.expressionType
            ), // 值表现形式
            value: { value: item.value }, // 选择的值
            seq: "",
            isApply: true,
          });
          dataFilter.data.push({
            fieldId: item.fieldid,
            op: item.operator,
            val: item.value,
            mainObjId: eachData.objid,
          });
        });
        // 判断是否有公式
        if (conditionList.length > 0 && conditionList[0].boolfilter) {
          dataFilter.filter = conditionList[0].boolfilter;
          // 显示已选择的公式
          this.formula = true;
          this.formCalculate = dataFilter.filter;
        } else {
          dataFilter.filter = "";
          // 无公式
          this.formula = false;
          this.formCalculate = "";
        }
        // 添加筛选条件时无法保存数据源对象的后两个，所以需要需要再传一次
        dataFilter.relatedObjIdC = this.allObjects.objectc || "";
        dataFilter.relatedObjIdD = this.allObjects.objectd || "";
        this.conditionVals = JSON.stringify(dataFilter);
      }
    },
    /*
      判断是否是数值类型，用在报表编辑页，
        1.累计汇总、百分比、评分、币种、数字；['C', 'P', 'SCORE', 'c', 'N']
        2.公式字段：数字、百分比、币种；['Z'：'N', 'P', 'c']
    */
    judgeNumber(item) {
      let schemefieldType = item.schemefieldType || item.fieldtype;
      let expressionType = item.expressionType;

      if (
        schemefieldType === "C" ||
        schemefieldType === "P" ||
        schemefieldType === "SCORE" ||
        schemefieldType === "c" ||
        schemefieldType === "N" ||
        (schemefieldType === "Z" &&
          (expressionType === "N" ||
            expressionType === "P" ||
            expressionType === "c"))
      ) {
        return true;
      }
      return false;
    },
    //  将数值类型转换为千分位格式
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      } else {
        return num;
      }
    },
    /*
      判断是否需要从mapTaskRel里根据api值获取对应数据，
      3种类型字段：任务事件、任务事件的关联对象、查找多选字段
    */
    isMapTaskRel(field) {
      if (field.isTask || field.isTaskObj || field.isLookup_Muti) {
        return true;
      } else {
        return false;
      }
    },
    // 获取报表数据的入参params
    getParamsData() {
      // 同环比的时候为日期类型id，否则为行分组
      let groupone = "";
      if (
        (this.allObjects.objecttype == "2" ||
          this.allObjects.objecttype == "ratio") &&
        [...this.chooseRowFileds].length >= 1
      ) {
        groupone = this.chooseRowFileds[0].id
          ? this.chooseRowFileds[0].id
          : this.chooseRowFileds[0];
        this.mainobjectcolumnid = [];
        this.mainobjectcolumnid.push(groupone);
      }
      // 非同环比报表行分组1=this.chooseRowFileds[0].id;
      if (
        this.allObjects.objecttype != "2" &&
        this.allObjects.objecttype != "ratio" &&
        this.chooseRowFileds.length >= 1
      ) {
        groupone = this.chooseRowFileds[0].id;
      }
      let startdateTime = "";
      let enddatestrTime = ""; //结束时间
      let totalrecord = ""; //记录数
      if (
        this.allObjects.objecttype == "2" ||
        this.allObjects.objecttype == "ratio"
      ) {
        startdateTime = this.sameMonthStarttime;
        enddatestrTime = this.sameMonthEndtime;
        totalrecord = "1";
        // 拼接日期类型id和数值类型id
        if (groupone && this.gatherfieldname) {
          this.mainobjectcolumnid = this.mainobjectcolumnid.concat(
            this.gatherfieldname
          );
        }
      } else {
        startdateTime = this.$refs.filterSetRef.form.startTime;
        enddatestrTime = this.$refs.filterSetRef.form.endTime;
        // 同环比报表totalrecord为1，其他报表在符合情况下为1
        if (this.$refs.rightTopRef.form.lineCountButt) {
          totalrecord = "1";
        } else {
          totalrecord = "0";
        }
      }
      // 编辑页中pagesize永远是1
      if (!this.runTo) {
        this.pageSize = "1";
      }
      this.paramsObject = {
        isshowchart: this.generateChartTos ? "true" : "false", //图表按钮显示状态
        dataSize:
          this.allObjects.objecttype == "2" ||
          this.allObjects.objecttype == "ratio"
            ? ""
            : this.tempDataSize,
        pageSize: this.pageSize, //页数：列表页带有分页
        isshowdetail:
          this.$refs.rightTopRef && this.$refs.rightTopRef.form.detailButt
            ? "true"
            : "false", //是否详细信息行
        id: this.tempId,
        mainobjectcolumnid: this.mainobjectcolumnid
          ? this.mainobjectcolumnid.join(",")
          : [],
        scope:
          this.allObjects.objecttype == "2" ||
          this.allObjects.objecttype == "ratio"
            ? "all"
            : this.$refs.filterSetRef.form.person, //同环比报表默认传all
        islightning: "true",
        reporttypecustomid: "",
        objecta: this.allObjects.objecta,
        objectb: this.allObjects.objectb,
        optionb:
          this.allObjects.optionb == 0 || this.allObjects.optionb == "inner"
            ? "inner"
            : "outer",
        bfindid: this.allObjects.bfindid,
        objectc: this.allObjects.objectc,
        optionc:
          this.allObjects.optionc == 0 || this.allObjects.optionc == "inner"
            ? "inner"
            : "outer",
        cfindid: this.allObjects.cfindid,
        objectd: this.allObjects.objectd,
        optiond:
          this.allObjects.optiond == 0 || this.allObjects.optiond == "inner"
            ? "inner"
            : "outer",
        dfindid: this.allObjects.dfindid,
        totalrecord: totalrecord,
        datecon:
          this.allObjects.objecttype != "2" &&
          this.allObjects.objecttype != "ratio"
            ? this.$refs.filterSetRef.form.timeRange
            : "",
        startdatestr: startdateTime,
        enddatestr: enddatestrTime,
        datarange:
          this.allObjects.objecttype != "2" &&
          this.allObjects.objecttype != "ratio"
            ? this.$refs.filterSetRef.form.durationList
            : "",
        // 行分组3和列分组2
        transversegroupone: groupone,
        transversegrouptwo:
          this.chooseRowFileds.length >= 2 ? this.chooseRowFileds[1].id : "",
        transversegroupthree:
          this.chooseRowFileds.length == 3 ? this.chooseRowFileds[2].id : "",
        lengthwaysgroupone:
          this.choosedColumnFileds.length >= 1
            ? this.choosedColumnFileds[0].id
            : "",
        lengthwaysgrouptwo:
          this.choosedColumnFileds.length == 2
            ? this.choosedColumnFileds[1].id
            : "",
        // 行分组1234字段
        transversedatetypeone: this.transversedatetypeone,
        transversedatetypetwo: this.transversedatetypetwo,
        transversedatetypethree: this.transversedatetypethree,
        lengthwaysdatetypeone: this.lengthwaysdatetypeone,
        lengthwaysdatetypetwo: this.lengthwaysdatetypetwo,
        gatherfieldname: this.gatherfieldname.join(","),
        avgfield: this.avgfield.join(","), //平均值
        maxfield: this.maxfield.join(","), //最大值
        minfield: this.minfield.join(","),
        objectRelation: "",
        conditionVals: this.conditionVals,
        expression: this.expressions,
        roleid: this.roleid,
        orderFieldApi: this.sortDatasObject.orderfieldapi, // 排序字段的api
        orderFieldType: this.sortDatasObject.orderfieldtype, // 排序字段类型
        orderType: this.sortDatasObject.ordertype, // 排序方式，升序降序
        tbhbexpression: this.tbhbexpression,
        reporttype:
          this.allObjects.objecttype == "2" ||
          this.allObjects.objecttype == "ratio"
            ? "ratio"
            : "", //同环比报表类型，
        m:!this.runTo?'nodata':'',// 在报表运行页显示数据，编辑页不显示数据
      };
      if(!this.paramsObject.id&&!this.cloneParamsObject.scope){
        this.cloneParamsObject= JSON.parse(JSON.stringify(this.paramsObject));
      }
    },
    // 获取报表数据
    async getReportDataByDetails(dynamicdata) {
      /*--
        判断是否要进行刷新操作，重新获取数据，包含以下情况
          1.第一次进来：有报表id、无报表id；
          2.自动刷新开启；
          3.自动刷新未开启，但点击了刷新按钮；
      --*/

      // 不需重新获取数据，则直接return即可。
      // 串联式报表
      if (this.allObjects.objecttype == "1") {
        if (
          !(
            this.tempId ||
            this.pageSource === "dataSource" ||
            this.automaticUpdates ||
            (!this.automaticUpdates && this.refreshTo)
          )
        ) {
          this.showRefresh = true;
          return;
        }
      }
      // 重新获取数据
      this.refreshTo = false;
      // 初始化数据
      this.getParamsData();
      // 如果是新建报表获取动态数据时，只执行查询报表图表接口
      if (dynamicdata === "dynamic") {
        this.getChartDataByReport(dynamicdata);
        return;
      }
      // 报表，图表，详细信息行loading
      this.reportLoading = true;
      this.reportEchartsLoading = true;
      this.loadingDetail = true;
      // 查询数据的时候清空滚动加载的页数
      if (this.myreportType == "Tabular") {
        this.$refs.listTypeTableRef.total = 1; //清空滚动加载的页数
        this.tableData = [];
      } else if (this.myreportType == "Summary") {
        this.$refs.summaryTypeTableRef.total = 1; //清空滚动加载的页数
        this.tableData = [];
      } else if (this.myreportType == "Matrix") {
        this.$refs.matrixTableRef.total = 1; //清空滚动加载的页数
        this.rowColumnData = [];
        // 详细信息行页数
        if (
          this.transversegroupList.length > 0 &&
          this.lengthwaysgroupList.length > 0 &&
          !this.sameMonthReportFlag &&
          this.$refs.rightTopRef
            ? this.$refs.rightTopRef.form.detailButt
            : false
        ) {
          this.listTableDetail = [];
          this.$refs.matrixDetailTableRef.total = 1; //清空滚动加载的页数
        }
      }

      try {
        let dataObject = {};
        let isOptime = JSON.stringify(this.isOptimization);
        if (isOptime && isOptime == "true") {
          // 获取优化后的报表信息接口
          dataObject = await ReportObjectApi.getOptimizationReportDataByDetail({
            ...this.paramsObject,
            id: this.$route.params.reportId,
          });
        } else {
          // 获取报表信息接口
          dataObject = await ReportObjectApi.getReportData(this.paramsObject);
        }
        //对详细信息行按钮进行赋值
        if (
          this.$refs.rightTopRef &&
          this.$refs.rightTopRef.form.detailButt === ""
        ) {
          if (dataObject.data.reportInfo.isshowdetail === "true") {
            this.isshowDetailedInfor = true;
            this.detailButts = true;
          } else {
            this.isshowDetailedInfor = false;
          }
          this.$refs.rightTopRef.form.detailButt = this.isshowDetailedInfor;
        }
        //首次进入页面对行计数按钮进行赋值
        if (
          this.$refs.rightTopRef &&
          this.$refs.rightTopRef.form.lineCountButt === ""
        ) {
          if (dataObject.data.reportInfo.totalrecord === "1") {
            this.isshowRowCount = true;
          } else {
            this.isshowRowCount = false;
          }
          this.$refs.rightTopRef.form.lineCountButt = this.isshowRowCount;
        }
        // 改变store中存储的行计数按钮数据
        if(this.$refs.rightTopRef){
          this.$store.commit(
            "setLineCountButt",
            this.$refs.rightTopRef.form.lineCountButt
          );
        }
        // 获取报表名字,编辑后保存，需要传报表名字
        if (dataObject.data.reportInfo && dataObject.data.reportInfo.name) {
          this.reportName = dataObject.data.reportInfo.name;
        }
        // 首次进入页面对图表按钮进行赋值
        if (
          this.$refs.topNameButtsRef &&
          this.$refs.topNameButtsRef.generateChartTo === ""
        ) {
          if (dataObject.data.reportInfo.isshowchart === "true") {
            this.isshowcharts = true;
            //控制图表按钮显示
            this.generateChartTos = this.isshowcharts;
            this.$refs.topNameButtsRef.generateChartTo = this.isshowcharts;
          } else {
            this.isshowcharts = false;
          }
        }
        // 获取图表信息
        this.getChartInfos(dataObject.data.reportInfo);
        // 判断是否在查看图表、且报表有分组
        if (
          this.generateChartTos &&
          dataObject.data.reportInfo.transversegroupList.length > 0
        ) {
          // 设置报表中图表的loading
          this.reportEchartsLoading = true;
          // 由于图表作为报表的一个属性，后端另写的一个接口，所以需要在传报表数据的
          // 基础上，再加上图表数据。
          this.getChartDataByReport();
        }
        // 清空数据：表格、行列分组
        this.tableData = [];
        this.rowColumnData = [];
        this.listCheckedFields = [];
        this.listCheckedFieldsArr = [];
        this.transversegroupList = [];
        this.lengthwaysgroupList = [];
        this.chooseRowFileds = [];
        this.choosedColumnFileds = [];
        this.mapTaskRel = {};

        // 定义变量reportInfo，reportTC
        let reportInfoData = dataObject.data.reportInfo;
        this.pageCount = dataObject.data.pageCount; //总页数
        let reportTC = this.reportDataTC;
        // 汇总式报表行列分组的集合字段正好跟矩阵式报表的相反，先定义一下
        this.transversegroupList = reportInfoData.transversegroupList; //获取已选择的行分组
        this.lengthwaysgroupList = reportInfoData.lengthwaysgroupList; //获取已选择的列分组
        this.loading = false; // 在图表接口也返回之后再取消加载，要不报表会出现暂时的暂无数据

        // 新建的报表
        if (!this.$route.params.reportId) {
          // 报表数据源名称,同环比报表名为：新建"业务机会"月度同环比报表，其他报表为：新建"业务机会"报表
          if (
            this.allObjects.objecttype == "2" ||
            this.allObjects.objecttype == "ratio"
          ) {
            this.temName = `${this.$i18n.t("label.ems.create")}"${
              reportTC.objectALabel
            }"${this.$i18n.t("label.monthly.month.report")}`;
          } else {
            this.temName = `${this.$i18n.t("label.ems.create")}"${
              reportTC.objectALabel
            }"${this.$i18n.t("label.visualpage.btype")}`;
          }
          // 在左侧菜单中显示报表所选择的数据源
          this.allObjectDatas = [];
          this.allObjectDatas.push(
            reportTC.objectALabel,
            reportTC.objectBLabel,
            reportTC.objectCLabel,
            reportTC.objectDLabel
          );
        }
        // 带有报表id的第一次请求，获取报表数据
        if (this.tempId) {
          document.title = this.$setTitle(`${reportInfoData.name}`); // 设置 title 标题
          this.temName = reportInfoData.name;
          // 在左侧菜单中显示报表所选择的数据源
          this.allObjectDatas = [];
          this.allObjectDatas.push(
            reportTC.objectALabel,
            reportTC.objectBLabel,
            reportTC.objectCLabel,
            reportTC.objectDLabel
          );
          // 存储reporttypecustomi，留作保存的时候传过去
          this.tempReporttypecustomid = reportInfoData.reporttypecustomid;
          // 获取另存为时的报表名称和备注
          this.reportNameNote = {
            reportId: reportInfoData.id,
            reportLabel: reportInfoData.name,
            description: reportInfoData.description,
            reportfolderid: reportInfoData.reportfolderid,
          };
          // 将后台返回的对应的所属数据源信息保存，作为下次 请求使用
          this.allObjects = {
            objecta: reportTC.objecta,
            objectb: reportTC.objectb,
            optionb: reportTC.optionb,
            bfindid: reportTC.bfindid,
            objectc: reportTC.objectc,
            optionc: reportTC.optionc,
            cfindid: reportTC.cfindid,
            objectd: reportTC.objectd,
            optiond: reportTC.optiond,
            dfindid: reportTC.dfindid,
            objecttype: reportInfoData.reporttype,
          };
          this.getConditionsByReportId(); // 获取已应用的过滤条件
        }
        // 每次进行操作都会重新请求报表接口获取数据，进行数据回显同步
        // 将后台返回的mainobjectcolumnid选择的组行分组赋值给当前的组件中，到运行页时作为参数使用
        this.mainobjectcolumnid = reportInfoData.mainobjectcolumnid
          ? reportInfoData.mainobjectcolumnid.split(",")
          : [];
        if (
          reportInfoData.transversegroupList.length > 0 &&
          reportInfoData.lengthwaysgroupList.length === 0
        ) {
          // 汇总式
          // 选择的行分组字段集合
          reportInfoData.transversegroupList.map((item) => {
            this.chooseRowFileds.push({
              apiname: item.schemefield_name,
              fieldtype: item.schemefieldType,
              id: item.id,
              labelname: item.label_name,
              objid: item.schemetableId,
            });
          });
          // 日期数据
          this.transversedatetypeone = reportInfoData.transversedatetypeone;
          this.transversedatetypetwo = reportInfoData.transversedatetypetwo;
          this.transversedatetypethree = reportInfoData.transversedatetypethree;
          this.lengthwaysdatetypeone = reportInfoData.lengthwaysdatetypeone;
          this.lengthwaysdatetypetwo = reportInfoData.lengthwaysdatetypetwo;
          // 获取时间分组依据类型传给子组件
          this.dateGroupings = {
            transversedatetypeone: reportInfoData.transversedatetypeone,
            transversedatetypetwo: reportInfoData.transversedatetypetwo,
            transversedatetypethree: reportInfoData.transversedatetypethree,
            lengthwaysdatetypeone: reportInfoData.lengthwaysdatetypeone,
            lengthwaysdatetypetwo: reportInfoData.lengthwaysdatetypetwo,
          };
        } else if (
          // 矩阵式
          reportInfoData.lengthwaysgroupList.length > 0 &&
          reportInfoData.transversegroupList.length > 0
        ) {
          // 选择的行列分组字段集合
          reportInfoData.lengthwaysgroupList.map((item) => {
            this.chooseRowFileds.push({
              apiname: item.schemefield_name,
              fieldtype: item.schemefieldType,
              id: item.id,
              labelname: item.labelName,
              objid: item.schemetableId,
            });
          });
          this.choosedColumnFileds = JSON.parse(
            JSON.stringify(reportInfoData.transversegroupList)
          );
          // 日期数据
          this.transversedatetypeone = reportInfoData.lengthwaysdatetypeone;
          this.transversedatetypetwo = reportInfoData.lengthwaysdatetypetwo;
          this.transversedatetypethree = reportInfoData.transversedatetypethree;
          this.lengthwaysdatetypeone = reportInfoData.transversedatetypeone;
          this.lengthwaysdatetypetwo = reportInfoData.transversedatetypetwo;
          // 获取时间分组依据类型传给子组件
          this.dateGroupings = {
            transversedatetypeone: reportInfoData.lengthwaysdatetypeone,
            transversedatetypetwo: reportInfoData.lengthwaysdatetypetwo,
            transversedatetypethree: reportInfoData.transversedatetypethree,
            lengthwaysdatetypeone: reportInfoData.transversedatetypeone,
            lengthwaysdatetypetwo: reportInfoData.transversedatetypetwo,
          };
        }
        // 获取排序
        this.sortDatas = {
          orderfieldapi: reportInfoData.orderfieldapi || "",
          orderfieldtype: reportInfoData.orderfieldtype || "",
          ordertype: reportInfoData.ordertype || "",
        };

        // 统计的字段id集合
        this.gatherfieldname = reportInfoData.gatherfieldname
          ? reportInfoData.gatherfieldname.split(",")
          : [];
        this.avgfield = reportInfoData.avgfield
          ? reportInfoData.avgfield.split(",")
          : [];
        this.maxfield = reportInfoData.maxfield
          ? reportInfoData.maxfield.split(",")
          : [];
        this.minfield = reportInfoData.minfield
          ? reportInfoData.minfield.split(",")
          : [];
        // 设置行计数按钮，判断是否要禁用
        let e = {
          gatherfieldname: this.gatherfieldname,
          avgfield: this.avgfield,
          maxfield: this.maxfield,
          minfield: this.minfield,
        };
        this.$store.commit("setLineCountButtDisabled", e);
        // 统计的字段集合
        this.gatherFieldList = reportInfoData.gatherFieldList
          ? reportInfoData.gatherFieldList
          : [];
        this.listAvgFields = reportInfoData.listAvgFields
          ? reportInfoData.listAvgFields
          : [];
        this.listMaxFields = reportInfoData.listMaxFields
          ? reportInfoData.listMaxFields
          : [];
        this.listMinFields = reportInfoData.listMinFields
          ? reportInfoData.listMinFields
          : [];
        // 获取统计数据传给子组件
        this.statisticsDatas = {
          gatherfieldname: reportInfoData.gatherfieldname || "",
          avgfield: reportInfoData.avgfield || "",
          maxfield: reportInfoData.maxfield || "",
          minfield: reportInfoData.minfield || "",
        };
        // 获取行级汇总公式左侧树数据
        this.summaryTree = [];
        let tempArr = [];
        tempArr.push(
          {
            labelName: reportTC.objectALabel,
            fieldList: reportTC.filteredListObjectAField,
          },
          {
            labelName: reportTC.objectBLabel,
            fieldList: reportTC.filteredListObjectBField,
          },
          {
            labelName: reportTC.objectCLabel,
            fieldList: reportTC.filteredListObjectCField,
          },
          {
            labelName: reportTC.objectDLabel,
            fieldList: reportTC.filteredListObjectDField,
          }
        );
        tempArr = tempArr.filter((item) => {
          if (item.labelName != "") {
            item.fieldList = item.fieldList.filter((itemx) => {
              if (itemx.schemefieldType === "Z") {
                if (
                  itemx.expressionType === "N" ||
                  itemx.expressionType === "p" ||
                  itemx.expressionType === "c"
                ) {
                  return itemx;
                }
              } else {
                if (
                  itemx.schemefieldType === "N" ||
                  itemx.schemefieldType === "p" ||
                  itemx.schemefieldType === "c"
                ) {
                  return itemx;
                }
              }
            });
            // 如果字段不存在数值类型的就在行级公式中的菜单中不显示菜单
            if (item.fieldList.length > 0) {
              return item;
            }
          }
        });
        this.summaryTree = tempArr;
        // 获取已应用的行级公式和汇总公式
        if (reportInfoData.listReportExpression != undefined) {
          // 行级公式
          this.expressionLine = reportInfoData.listReportExpression.filter(
            (item) => item.type === "L"
          );
          // 汇总公式
          this.expressionSummary = reportInfoData.listReportExpression.filter(
            (item) => item.type === "Z"
          );
        } else {
          this.expressionLine = [];
          this.expressionSummary = [];
        }
        // 回显已选择的时间范围字段
        if (reportInfoData.datecon) {
          this.choosedTimeRanges = this.timeRanges.find(
            (item) => item.id == reportInfoData.datecon
          );
          this.isApply = true;
          this.$refs.filterSetRef.form.timeRange = reportInfoData.datecon;
          this.$refs.filterSetRef.form.startTime = this.$moment(
            reportInfoData.startdatestr
          ).format("YYYY-MM-DD"); // 格式化成对应时间格式
          this.$refs.filterSetRef.form.endTime = this.$moment(
            reportInfoData.enddatestr
          ).format("YYYY-MM-DD"); // 格式化成对应时间格式
          this.$refs.filterSetRef.form.durationList = reportInfoData.datarange
            ? reportInfoData.datarange
            : ""; // 选择的持续时间的value，若是选择的自定义时间选项，则传过去的参数值为''，但后端未返回该参数，获取到的值会是undefined
            this.durationLists && this.durationLists.map((item) => {
            if (item.options) {
              item.options.map((item1) => {
                if (item1.key === this.$refs.filterSetRef.form.durationList) {
                  this.$refs.filterSetRef.form.durationListLabel = item1.value;
                }
              });
            }
          }); // 选择的持续时间的label
        }
        if(this.$refs.filterSetRef){
          this.$refs.filterSetRef.form.person = reportInfoData.scope; // 向我展示回显
        }
        this.mapTaskRel = dataObject.data.mapTaskRel;
        // 运行状态，列表式报表仅展示2000条
        // if (this.runTo) {
        //   if (
        //     dataObject.data.listReportData &&
        //     dataObject.data.listReportData.length > 0
        //   ) {
        //     this.runShowDataTo = true;
        //   }
        // }
        this.totalrecords = dataObject.data.dataSize; // 记录数
        // this.matrixCounts = dataObject.data.ListTotal
        //   ? dataObject.data.ListTotal.find(
        //       (item) => item.schemefieldnamealias === "totalrecord"
        //     ).total
        //   : "";
        if (dataObject.data.ListTotal && dataObject.data.ListTotal.length > 0) {
          dataObject.data.ListTotal.find((item) => {
            if (item.schemefieldnamealias === "totalrecord") {
              return (this.matrixCounts = item.total);
            }
          });
        } else {
          this.matrixCounts = "";
        }
        // 矩阵式报表的统计数
        this.isHavaMoreColumns = dataObject.data.isHavaMoreColumns
          ? JSON.parse(dataObject.data.isHavaMoreColumns)
          : false; // 矩阵式报表上面的统计表是否还有列未显示出来
        this.listCheckedFieldsArr = JSON.parse(
          JSON.stringify(reportInfoData.listCheckedFields)
        ); // 获取报表列数据
        this.listCheckedFields = JSON.parse(
          JSON.stringify(reportInfoData.listCheckedFields)
        ); //获取表头数据
        this.groupIds = reportInfoData.groupIds.split(","); //所选行分组字段
        // 分组的后的表格数据的模块（第一个表格）
        // 矩阵式和汇总式datasize传空，返回全部数据，页面仅展示前两千条数据
        let listReportDataLineAscone;
        let listReportDatacone;
        let listTranGroupDataBeanone;
        if (dataObject.data.listReportDataLineAsc) {
          listReportDataLineAscone =
            dataObject.data.listReportDataLineAsc.slice(0, 2000);
        }
        if (dataObject.data?.listReportData) {
          listReportDatacone = dataObject.data.listReportData.slice(0, 2000);
        }
        if (dataObject.data?.listTranGroupDataBean) {
          listTranGroupDataBeanone =
            dataObject.data.listTranGroupDataBean.slice(0, 2000);
        }
        this.SummarytableData =
          listReportDataLineAscone ||
          listReportDatacone ||
          listTranGroupDataBeanone; //获取行列表
        this.reportLoading = false;
        // 给报表赋值
        if (
          reportInfoData.transversegroupList.concat(
            reportInfoData.lengthwaysgroupList
          ).length > 0
        ) {
          //有分组
          if (
            //有行分组,汇总式报表编辑页显示50条数据，使用数据50*2，所以编辑页要截取100条数据
            reportInfoData.transversegroupone != "" &&
            (reportInfoData.lengthwaysgroupone === undefined ||
              reportInfoData.lengthwaysgroupone === "")
          ) {
            if (this.runTo) {
              this.tableData = this.SummarytableData?.slice(0, 50);
            } else {
              this.tableData = this.SummarytableData?.slice(0, 100);
            }
            this.TabularPagenum = Math.ceil(this.SummarytableData?.length / 50);
            if (
              JSON.stringify(dataObject.data.mapTotal) !== "{}" &&
              reportInfoData.reporttype != "ratio" &&
              ((this.tableData.length <= 100 && !this.runTo) ||
                (this.tableData.length < 50 && this.runTo))
            ) {
              dataObject.data.mapTotal.level01 = "maxtotal";
              this.tableData.push(dataObject.data.mapTotal);
            }
            this.SummaryAndMatrixMapTotal = dataObject.data.mapTotal;
            let mergeRowArr = [];
            reportInfoData.transversegroupList.map((item) => {
              mergeRowArr.push(item.schemefield_name_alias);
            });
            this.alltransversegroupList = reportInfoData.transversegroupList;
            if( this.tableData && this.tableData.length > 0){
              this.tableData = mergeTableRow(this.tableData, mergeRowArr);
            }
            if (this.runTo) {
              this.runShowDataTo = true;
            }
          } else if (reportInfoData.transversegroupList.length > 0) {
            //有列分组，矩阵式报表
            if(dataObject.data?.listMatData.length>0){
              this.MatrixtableData = dataObject.data.listMatData.slice(0, 2000);
              this.rowColumnData = this.MatrixtableData.slice(0, 50);
              this.TabularPagenum = Math.ceil(this.MatrixtableData?.length / 50);
            }
            if (dataObject.data?.listMatData?.length > 0) {
              this.SummaryAndMatrixMapTotal = {
                listLengthData: dataObject.data.ListTotal,
                mapLengthGroup: {
                  detailOrSubtotal: "maxtotal",
                },
              };
              // 编辑页只显示50条：在编辑页小于等于50条时，加合计行
              // 运行页显示2000条：在运行页总数小于50条时，没有滚动加载直接加合计行，大于50条时最后一次滚动加载时再加合计行
              if (
                (this.rowColumnData.length <= 50 && !this.runTo) ||
                (this.rowColumnData.length < 50 && this.runTo)
              ) {
                this.rowColumnData.push(this.SummaryAndMatrixMapTotal);
              }
            }
            if (this.runTo) {
              this.runShowDataTo = true;
            }
            this.chooseColumnTypes = reportInfoData.listTranGroupDataBean;
            let mergeRowArr = [];
            reportInfoData.lengthwaysgroupList.map((item) => {
              mergeRowArr.push(item.schemefield_name_alias);
            });
            this.MatrixtlengthwaysgroupList =
              reportInfoData.lengthwaysgroupList;
            this.rowColumnData = mergeTableCoulmn(
              this.rowColumnData,
              mergeRowArr
            );
          }
        } else {
          // 没有分组
          this.TabulartableData =
            dataObject.data.listReportDataLineAsc ||
            dataObject.data.listReportData ||
            reportInfoData.listTranGroupDataBean;
          this.tableData = this.TabulartableData?.slice(0, 50);
          this.TabularPagenum = Math.ceil(this.TabulartableData.length / 50);
          if (
            (this.tableData.length <= 50 && !this.runTo) ||
            (this.tableData.length < 50 && this.runTo)
          ) {
            dataObject.data.mapTotal.level01 = "maxtotal";
            dataObject.data.mapTotal.totalrecord = this.TabulartableData.length;
            this.tableData.push(dataObject.data.mapTotal);
          }
          this.SummaryAndMatrixMapTotal = dataObject.data.mapTotal;
        }
        this.myreportType = reportInfoData.reporttype;
        this.tempId = ""; // 清空第一次报表列表进来时传递的id
        this.pageSource = ""; // 清空第一次新建报表设置的来源页面值
        // 判断如果是矩阵式报表且显示详细信息，则需要重新取右下角数据
        if (
          this.lengthwaysgroupList.length > 0 &&
          this.transversegroupList.length > 0 &&
          this.$refs.rightTopRef.form.detailButt
        ) {
          this.removeConditions();
        }
        // 判断报表类型，同环比显示概要组件,显示默认开始时间，结束时间
        if (
          this.allObjects.objecttype == "ratio" ||
          this.allObjects.objecttype == "2"
        ) {
          this.sameMonthReportFlag = true; //显示同环比的概要组件
          this.sameMonthStarttime = reportInfoData.startdatestr;
          this.sameMonthEndtime = reportInfoData.enddatestr; //结束时间
          // 设置日期字段id
          if (reportInfoData.transversegroupList && reportInfoData.transversegroupList.length > 0) {
            this.chooseDimension = reportInfoData.transversegroupone;
          }
          // 设置金额和数值字段id
          if (reportInfoData?.gatherfieldname) {
            this.chooseStatisticaLindicators = reportInfoData.gatherfieldname;
            this.gatherfieldname = [];
            this.gatherfieldname.push(this.chooseStatisticaLindicators);
          }
          if (
            reportInfoData.gatherFieldList &&
            reportInfoData.gatherFieldList.length > 0 &&
            !reportInfoData.gatherfieldname
          ) {
            this.chooseStatisticaLindicators =
              reportInfoData.gatherFieldList[0].id;
            this.gatherfieldname = [];
            this.gatherfieldname.push(this.chooseStatisticaLindicators);
          }
          // 获取数值金额的汇总字段id和name
          if (
            reportInfoData.gatherFieldList &&
            reportInfoData.gatherFieldList.length > 0 &&
            reportInfoData.gatherfieldname
          ) {
            this.gatherFieldList = reportInfoData.gatherFieldList;
            this.gatherFieldList.map((item) => {
              if (item.id === this.chooseStatisticaLindicators) {
                this.summaryFieldName = item.labelName;
                this.summaryFieldID = item.schemefield_name_alias;
              }
            });
          }
          // 获取日期字段的id和name展示在列表中
          if (
            reportInfoData.listCheckedFields &&
            reportInfoData.listCheckedFields.length > 0 &&
            reportInfoData.transversegroupone
          ) {
            reportInfoData.listCheckedFields.map((item) => {
              if (item.id === reportInfoData.transversegroupone) {
                this.dateFieldName = item.labelName;
                this.dateFieldId = item.schemefield_name_alias;
              }
            });
          }

          // 同环比报表拼接数值金额类型字段
          if (this.AllFiledlistSummaryField && reportInfoData.gatherFieldList) {
            let totalrecordArray = [];
            totalrecordArray.push(
              reportInfoData.gatherFieldList.find(
                (item) => item.schemefieldName === "totalrecord"
              )
            );
            this.listSummaryField =
              this.AllFiledlistSummaryField.concat(totalrecordArray);
          }
        } else {
          this.sameMonthReportFlag = false;
        }
        this.$forceUpdate();
      } catch (error) {
        this.loading = false;
        // 清空数据：表格、行列分组
        // this.tableData = [];
        // this.rowColumnData = [];
        // this.listCheckedFields = [];
        // this.transversegroupList = [];
        // this.lengthwaysgroupList = [];
        // this.chooseRowFileds = [];
        // this.choosedColumnFileds = [];
        // this.mapTaskRel = {};
      }
    },
    // 根据报表接口返回的报表数据获取图表的信息
    getChartInfos(reportInfoData) {
      let groupList = [];
      if (
        reportInfoData.transversegroupList.length > 0 &&
        reportInfoData.lengthwaysgroupList.length > 0
      ) {
        groupList = reportInfoData.transversegroupList.concat(
          reportInfoData.lengthwaysgroupList
        );
      } else {
        groupList = reportInfoData.transversegroupList;
      }
      // 根据报表返回数据获取分组信息
      this.reportInfoToChart = {
        groupList: groupList, // 获取分组的下拉选项
        gatherFieldList: reportInfoData.gatherFieldList
          ? reportInfoData.gatherFieldList
          : [], // 总和
        listAvgFields: reportInfoData.listAvgFields
          ? reportInfoData.listAvgFields
          : [], // 平均值
        listMaxFields: reportInfoData.listMaxFields
          ? reportInfoData.listMaxFields
          : [], // 最大值
        listMinFields: reportInfoData.listMinFields
          ? reportInfoData.listMinFields
          : [], // 最小值
        lengthwaysgroupone: reportInfoData.lengthwaysgroupone, // 分组一的id
        lengthwaysgrouptwo: reportInfoData.lengthwaysgrouptwo, // 分组二的id
        transversegroupone: reportInfoData.transversegroupone, // 分组一的id
        transversegrouptwo: reportInfoData.transversegrouptwo, // 分组二的id
        transversegroupthree: reportInfoData.transversegroupthree, // 分组三的id
        lengthwaysdatetypeone: reportInfoData.lengthwaysdatetypeone, // 分组一的日期分组依据
        lengthwaysdatetypetwo: reportInfoData.lengthwaysdatetypetwo, // 分组二的日期分组依据
        transversedatetypeone: reportInfoData.transversedatetypeone, // 分组一的日期分组依据
        transversedatetypetwo: reportInfoData.transversedatetypetwo, // 分组二的日期分组依据
        transversedatetypethree: reportInfoData.transversedatetypethree, // 分组三的日期分组依据
        reporttype: reportInfoData.reporttype,
        reportTC: this.reportDataTC, //报表添加表格图表：回显数据
        groupIds: reportInfoData.groupIds,
        dashboardsortcondition: reportInfoData.dashboardsortcondition, //排序方式
      };
      // gatherFieldList 统计字段
      if (
        this.AllFiledlistSummaryField &&
        this.AllFiledlistSummaryField.length > 0
      ) {
        let objString = JSON.stringify(this.AllFiledlistSummaryField);
        let objParse = JSON.parse(objString);
        //报表：表格图表统计字段schemefield_name_alias去掉汇总方式
        objParse.map((item) => {
          item.schemefield_name_alias = item.schemefield_name_alias_obj;
        });
        this.reportInfoToChart.DashboardListSummaryField = objParse;
      }
      //无分组表格图表排序字段数据过滤掉记录数：listCheckedFields
      if (
        reportInfoData.listCheckedFields &&
        reportInfoData.listCheckedFields.length > 0
      ) {
        let objStrings = JSON.stringify(reportInfoData.listCheckedFields);
        let objParses = JSON.parse(objStrings);
        let dashborarObject = [];
        objParses.map((item) => {
          if (item.schemefieldName !== "totalrecord") {
            dashborarObject.push(item);
          }
        });
        this.reportInfoToChart.DashboardListCheckedFields = dashborarObject;
      }
    },
    // 添加组行、删除组行组列时，需要重新选择图表的分组
    groupIsDeleted(id) {
      if (
        (this.chartInfos.ycon === id && this.chooseRowFileds.length > 0) ||
        this.chooseRowFileds.length === 1
      ) {
        this.chartInfos.ycon = this.chooseRowFileds[0].id;
      }
    },
    // 获取矩阵报表内部单元格详情信息
    async getReportDetail() {
      this.getParamsData(); // 初始化数据
      this.loadingDetail = true;
      let params = JSON.parse(JSON.stringify(this.paramsObject));
      params.detailConditions = this.detailConditions;
      let dataObject = await ReportObjectApi.getReportDetail(params);
      let data = dataObject.data;
      this.loadingDetail = false;
      this.AlllistTableDetail = data.reportDetail; //详细信息行的全部数据，首次加载渲染前50条数据
      this.listTableDetail = data.reportDetail.slice(0, 50);
      this.DetailPagenum = Math.ceil(data.reportDetail.length / 50); //滚动加载的次数
    },
    // 点击清除
    removeConditions() {
      // 将选中的单元格清空，且默认选中右下角合计单元格
      this.cellArr = [];
      this.$nextTick(() => {
        this.$refs.matrixTableRef.selectRow = "maxtotal";
        this.$refs.matrixTableRef.selectColumn = "total";

        // 详细信息行在运行页获取2000行，编辑页获取50行
        if (this.runTo) {
          this.tempDataSize = "2000";
          this.runShowDataTo = true;
        } else {
          this.tempDataSize = "50";
        }
        this.detailConditions = "{}";
        this.getReportDetail();
      });
    },
    // 根据对象来获取全部字段
    async getObjectAllField(params) {
      return new Promise(function (resolve, reject) {
        ReportObjectApi.getObjectAllField(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 处理时间范围日期字段
    setTimeData(duration) {
      this.durationLists.push(
        // 日
        {
          label: duration.calenderDayDurationListName,
          options: duration.calenderDayDurationList,
        },
        // 日历星期
        {
          label: duration.calenderWeekDurationListName,
          options: duration.calenderWeekDurationList,
        },
        // 日历月度
        {
          label: duration.calenderMonthDurationListName,
          options: duration.calenderMonthDurationList,
        },
        // 日历季度
        {
          label: duration.calenderQuarterDurationListName,
          options: duration.calenderQuarterDurationList,
        },
        // 日历年度
        {
          label: duration.calenderYearDurationListName,
          options: duration.calenderYearDurationList,
        },
        // 会计季度
        {
          label: duration.fiscalQuarterDurationListName,
          options: duration.fiscalQuarterDurationList,
        },
        // 会计年度
        {
          label: duration.fiscalYearQuarterDurationListName,
          options: duration.fiscalYearQuarterDurationList,
        },
        // 自定义年度
        {
          label: duration.customyearDurationListName,
          options: duration.customyearDurationList,
        },
        // 自定义
        {
          label: "",
          options: duration.cumstomDurationList,
        }
      );
    },
    // 获取角色树和报表持续时间接口
    async getRoleTreeAndDurationList(paramsTwo) {
      if (paramsTwo) {
        return new Promise(function (resolve, reject) {
          ReportObjectApi.getRoleTreeAndDurationList(paramsTwo)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      } else {
        let params = {};
        if (!this.$route.params.reportId) {
          params = {
            objecta: this.allObjects.objecta ? this.allObjects.objecta : "",
            objectb: this.allObjects.objectb ? this.allObjects.objectb : "",
            objectc: this.allObjects.objectc ? this.allObjects.objectc : "",
            objectd: this.allObjects.objectd ? this.allObjects.objectd : "",
          };
        } else {
          params = {
            id: this.$route.params.reportId,
          };
        }
        let dataObject = await ReportObjectApi.getRoleTreeAndDurationList(
          params
        );
        //获取当前人的角色及下属角色树
        if (dataObject.result && dataObject.data.roleList.length > 0) {
          let roleList = dataObject.data.roleList;
          this.allRoles = roleList;
          this.currentRole = roleList[0];
          this.subordinateRoles = roleList.filter(
            (item) =>
              item.parentroleid == this.currentRole.roleid &&
              item.roleid != this.currentRole.roleid
          );
          this.middleRoleTo = false;
          this.roleid = "";
        } else {
          this.allRoles = [];
        }
        //获取报表持续时间
        let duration = dataObject.data.duration;
        this.setTimeData(duration);
      }
    },
    // 切换持续时间下拉框
    changeDemonstration(index) {
      let data = "";
      this.durationLists && this.durationLists.map((item) => {
        if (item.options) {
          item.options.map((item1) => {
            if (item1.key == index) {
              data = item1;
            }
          });
        }
      });
      if(this.$refs.filterSetRef){
        this.$refs.filterSetRef.form.durationListLabel = data.value;
        this.$refs.filterSetRef.form.startTime = data.startdate;
        this.$refs.filterSetRef.form.endTime = data.enddate;
      }
    },
    // 获取下级角色
    getNextRoles(item) {
      this.middleRole = item;
      this.middleRoleTo = true;
      this.subordinateRoles = this.allRoles.filter(
        (item) =>
          item.parentroleid == this.middleRole.roleid &&
          item.roleid != this.middleRole.roleid
      );
      this.roleid = item.roleid;
      this.getReportDataByDetails();
    },
    // 选择报表列
    changeReportColData(id) {
      if (id != "") {
        // 报表列字段添加限制，最多200个
        if(this.listCheckedFieldsArr.length<200){
          this.mainobjectcolumnid.push(id);
          this.getReportDataByDetails();
          if (this.choosedColumnFileds.length > 0) {
            this.getReportDetail();
          }
          // 根据id找到对应的该条数据
          let itemData = this.allFiledDatas.find((item1) => item1.id === id);
          // 同步更改报表列集合
          this.listCheckedFieldsArr.push(itemData);
        }else{
          this.$message.error(this.$i18n.t("c2392"))
        }
      }
      this.$refs.profileRef.form.reportColumn = "";
    },
    // 删除已选报表列数据
    deleteReportColData(id, index) {
      this.listCheckedFieldsArr.splice(index, 1);
      let indexs = this.mainobjectcolumnid.findIndex((item) => item === id);
      this.mainobjectcolumnid.splice(indexs, 1);
      // 清除计算的总和、平均值、最大值、最小值
      if (this.gatherfieldname.indexOf(id) > -1) {
        let index = this.gatherfieldname.findIndex((item) => item === id);
        this.gatherfieldname.splice(index, 1);
      }
      if (this.avgfield.indexOf(id) > -1) {
        let index = this.avgfield.findIndex((item) => item === id);
        this.avgfield.splice(index, 1);
      }
      if (this.maxfield.indexOf(id) > -1) {
        let index = this.maxfield.findIndex((item) => item === id);
        this.maxfield.splice(index, 1);
      }
      if (this.minfield.indexOf(id) > -1) {
        let index = this.minfield.findIndex((item) => item === id);
        this.minfield.splice(index, 1);
      }
      this.getReportDataByDetails();
    },
    //刷新
    async clickRefresh() {
      this.refreshTo = true;
      this.showRefresh = false;
      await this.getReportDataByDetails();
    },
    // 汇总行
    changeRowData(value) {
      if (value !== "") {
        let id = value;
        let data = this.allFiledDatas.find((item) => {
          return item.id == id;
        });
        this.chooseRowFileds.push(data);
        if (this.listCheckedFieldsArr.every((item) => item.id != id)) {
          this.mainobjectcolumnid.push(id);
        }
        // 判断添加完组行后，是否只有一个行分组，此时生成的图表选择的分组需要重新获取一下
        if (this.chooseRowFileds.length === 1) {
          this.groupIsDeleted(id);
        }
        this.getReportDataByDetails();
        // 同步更新groupIds
        this.groupIds.push(id);
      }
      this.$refs.profileRef.form.rowFiled = "";
    },
    // 删除已选行数据
    deleteRowData(index, id) {
      this.chooseRowFileds.splice(index, 1);
      // 如果此时没有行分组了，则列分组要同步删除
      if (this.chooseRowFileds.length === 0) {
        this.choosedColumnFileds = [];
        this.groupIds = [];
      }
      // 当删除组行时，生成的图表选择的分组需要重新获取一下
      this.groupIsDeleted(id);
      this.getReportDataByDetails();
      // 同步更新groupIds
      this.groupIds = this.groupIds.filter((item) => item !== id);
    },
    // 汇总列
    changeColData(item) {
      if (item != "") {
        let id = item;
        let data = this.allFiledDatas.find((item) => {
          return item.id == id;
        });
        this.choosedColumnFileds.push(data);
        if (this.listCheckedFieldsArr.every((item) => item.id != id)) {
          this.mainobjectcolumnid.push(id);
        }
        this.mainobjectcolumnid.unshift("totalrecord");
        this.getReportDataByDetails();
        // 同步更新groupIds
        this.groupIds.push(item);
      }
      this.$refs.profileRef.form.columnFiled = "";
    },
    // 删除已选列数据
    deleteColData(index, id) {
      this.choosedColumnFileds.splice(index, 1);
      // 当删除组列时，生成的图表选择的分组需要重新获取一下
      this.groupIsDeleted(id);
      this.getReportDataByDetails();
      // 同步更新groupIds
      this.groupIds = this.groupIds.filter((item) => item !== id);
    },
    // 切换向我显示下拉内容
    changePerson() {
      this.getReportDataByDetails();
    },
    // 点击退出
    clickQuit() {
      this.saveCloneTo = true;
    },
    // 点击到运行页
    clickRun() {
      this.runTo = true;
      this.displayFieldsTo = false; // 不展示列表字段
    },
    // 点击编辑
    clickEdit() {
      this.runTo = false;
      this.tabCur = 1;
      this.tempDataSize = "50";
      this.getReportDataByDetails();
      // if (
      //   this.lengthwaysgroupList.length > 0 &&
      //   this.transversegroupList.length > 0 &&
      //   this.$refs.rightTopRef.form.detailButt
      // ) {
      //   // 判断是否为矩阵式报表且详细信息行已打开
      //   this.getReportDetail();
      // }
    },
    // 合并行列
    objectSpanMethod({ row, column }) {
      const span = column["property"] + "-span";
      if (row[span]) {
        // 解决使用cloudcc-ui不能合并分页行的问题，需要调用this.tableData,该代码不能删除
        this.tableData;
        return row[span];
      }
    },
    // 点击单元格
    cellclick(row, column) {
      // 判断详细信息行按钮是否开启，若在点击单元格之后还未开启，则手动开启
      if (!this.$refs.rightTopRef.form.detailButt) {
        this.$refs.rightTopRef.form.detailButt = true;
        this.detailButts = true;
      }
      this.cellArr = [];
      // 获取选中单元格所对应的行列分组数据
      let dataArr = [];
      this.lengthwaysgroupList.map((item) => {
        let apiName = item.schemefield_name_alias;
        // 若为行的小计或合计，则不需要该筛选条件
        let cellData = row.mapLengthGroup[item.schemefield_name_alias_obj];
        if (cellData || cellData === "") {
          // 不是小计行、合计行
          dataArr.push({
            [apiName]: cellData === null ? "" : cellData,
          });
          this.cellArr.push({
            labelName: item.labelName,
            value: cellData,
          });
        }
      });
      this.transversegroupList.map((item, index) => {
        let apiName = item.schemefield_name_alias;
        // 若为列的小计或合计，则不需要该筛选条件
        let label = [];
        if (column.label === this.$i18n.t("label.subtotal")) {
          // 若为小计列，则取property，property为label-value
          label = column.property.split("_")[0].split(",");
        } else {
          // 正常列，直接取label即可。
          label = column.label.split("_");
        }
        if (
          column.label !==
          this.$i18n.t("label.reportsSubscriptionPage.condition.aggregate")
        ) {
          // 不是合计列
          dataArr.push({
            [apiName]: label[index] === null ? "" : label[index],
          });
          this.cellArr.push({
            labelName: item.labelName,
            value: label[index],
          });
        }
      });
      // 转换数据格式
      let params = {};
      dataArr.forEach((item) => {
        Object.keys(item).forEach((objItem) => {
          params[objItem] = item[objItem];
        });
      });
      // 详细信息行在运行页获取2000行，编辑页获取50行
      if (this.runTo) {
        this.tempDataSize = "2000";
      } else {
        this.tempDataSize = "50";
      }
      this.detailConditions = JSON.stringify(params);
      this.getReportDetail();
    },
    // 去除千分位
    delcommafy(num) {
      if ((num + "").trim() == "") {
        return "";
      }
      if (num) {
        num = num.replace(/,/gi, "");
        return num;
      } else {
        return num;
      }
    },
    // 点击行级汇总公式按钮
    hideFormula(index) {
      this.formulaCur = index;
      this.summaryFormulaTo = true;
      this.formulaInfos = "";
      if (index == 1) {
        this.formulaHeader = this.$i18n.t("vue_label_report_summaryformula");
      } else {
        this.formulaHeader = this.$i18n.t("vue_label_report_rowlevelformula");
      }
    },
    // 关闭编辑汇总公式弹框
    summaryFormulaCancel() {
      this.summaryFormulaTo = false;
    },
    // 点击行级公式
    clickLineFormula() {
      this.summaryFormulaTo = true;
      this.formulaHeader = this.$i18n.t("vue_label_report_rowlevelformula");
      if (this.expressionLine.length > 0) {
        this.formulaInfos = this.expressionLine[0].values;
      } else {
        this.formulaInfos = "";
      }
    },
    // 点击汇总公式
    clickSummaryFormula(item, index) {
      this.summaryFormulaTo = true;
      this.formulaHeader = this.$i18n.t("vue_label_report_summaryformula");
      this.summaryFormulaId = item.apiname;
      if (this.expressionSummary.length > 0) {
        this.formulaInfos = this.expressionSummary[index].values;
      } else {
        this.formulaInfos = "";
      }
    },
    // 应用行级公式
    lineFormulaApply(params) {
      let data = [];
      // 表达式中有数据
      if (this.expressions !== "") {
        data = JSON.parse(this.expressions).data;
        // 存在行级公式
        if (data.some((item) => item.type === "L")) {
          let index = data.findIndex((item) => item.type === "L");
          data.splice(index, 1);
        }
      }
      data.push(params);
      // 格式化传入的数据，请求后台
      this.expressions = JSON.stringify({
        data: data,
      });
      this.getReportDataByDetails();
      this.summaryFormulaTo = false;
    },
    // 应用汇总公式
    summaryFormulaApply(params) {
      let data = [];
      if (this.expressions !== "") {
        data = JSON.parse(this.expressions).data;
        // 编辑原有的汇总公式
        if (
          data.some(
            (item) => item.type === "Z" && item.apiname === params.apiname
          )
        ) {
          let index = data.findIndex((item) => item.type === "Z");
          data.splice(index, 1);
        }
      }
      data.push(params);

      // 格式化传入的数据，请求后台
      this.expressions = JSON.stringify({
        data: data,
      });
      this.getReportDataByDetails();
      this.summaryFormulaTo = false;
    },
    // 删除行级公式
    deleteLineFormula() {
      let data = [];
      data = JSON.parse(this.expressions).data;
      let index = data.findIndex((item) => item.type === "L");
      data.splice(index, 1);
      this.expressions = JSON.stringify({
        data: data,
      });
      this.getReportDataByDetails();
    },
    // 删除汇总公式
    deleteSummaryFormula(item) {
      let apiname = item.apiname;
      let data = JSON.parse(this.expressions).data;
      let index = data.findIndex(
        (item) => item.type === "Z" && item.apiname === apiname
      );
      data.splice(index, 1);
      this.expressions = JSON.stringify({
        data: data,
      });
      this.getReportDataByDetails();
      this.summaryFormulaTo = false;
    },
    //删除公式
    formulaCancel() {
      this.formula = false;
      this.formCalculate = "";
      // 获取之前已应用的过滤条件
      this.conditionVals = JSON.parse(this.conditionVals);
      // 清空公式
      this.conditionVals.filter = "";
      this.conditionVals = JSON.stringify(this.conditionVals);
      this.getReportDataByDetails();
    },
    // 应用公式
    advancedScreeningApply(data) {
      this.formula = true;
      this.formCalculate = data;
      // 获取之前已应用的过滤条件
      this.conditionVals = JSON.parse(this.conditionVals);
      // 修改公式
      this.conditionVals.filter = data;
      this.conditionVals = JSON.stringify(this.conditionVals);
      this.getReportDataByDetails();
    },
    // 选择时间范围字段
    changeTimeRange(value) {
      let data = this.timeRanges.find((item) => item.id == value);
      this.choosedTimeRanges = data;
      this.$refs.filterSetRef.$refs.timeRangePopver.doShow(); // 显示弹框
    },
    // 删除已选择的时间范围字段
    deleteTimeRange() {
      this.isApply = false;
      this.choosedTimeRanges = {};
      this.$refs.filterSetRef.form.timeRange = "";
      this.$refs.filterSetRef.form.startTime = "";
      this.$refs.filterSetRef.form.endTime = "";
      this.$refs.filterSetRef.form.durationList = "";
      this.$refs.filterSetRef.form.durationListLabel = "";
      this.getReportDataByDetails();
      this.$refs.filterSetRef.$refs.timeRangePopver.doClose();
    },
    // 应用时间范围字段弹框
    applyTimeRange() {
      let data = this.timeRanges.find(
        (item) => item.id == this.$refs.filterSetRef.form.timeRange
      );
      this.isApply = true;
      this.choosedTimeRanges = data;
      this.getReportDataByDetails();
      this.$refs.filterSetRef.$refs.timeRangePopver.doClose();
    },
    // 监听取消时间范围字段弹框
    hideTimeRangePopver() {
      if (!this.isApply) {
        // 未应用，清空已选择的数据
        this.$refs.filterSetRef.form.timeRange = "";
        this.$refs.filterSetRef.form.durationList = "";
        this.$refs.filterSetRef.form.startTime = "";
        this.$refs.filterSetRef.form.endTime = "";
        this.choosedTimeRanges = {};
      }
    },
    // 选择过滤条件字段
    async changeFilterFiled(value) {
      // 根据字段id获取整条数据
      let id = value;
      let data = this.allFiledDatas.find((item) => item.id == id);
      this.choosedFilterFileds.push({
        fieldid: data.id,
        prefix: data.prefix, // 对象前缀
        labelname: data.label_name, // 字段名
        operator:
          operator.TYPE[
            data.schemefieldType === "Z"
              ? data.expressionType
              : data.schemefieldType
          ].getScreeningOpeTypes()[0].id, // 选择的操作值
        screeningOperation:
          operator.TYPE[
            data.schemefieldType === "Z"
              ? data.expressionType
              : data.schemefieldType
          ].getScreeningOpeTypes(), // 操作可选的下拉内容
        // 字段类型
        type:
          data.schemefieldType === "Z"
            ? data.expressionType
            : data.schemefieldType,
        inputType: this.inputTypeConversion(
          data.schemefieldType,
          data.expressionType
        ), // 值表现形式
        value: { value: "" },
        seq: "",
        isApply: false, // 是否应用
      });
      this.screenCondition = data.label_name;
      let index = this.choosedFilterFileds.length - 1;
      this.$nextTick(() => {
        // 先给其他所有弹框隐藏
        this.$refs.filterSetRef.$refs.filterPopver.map((item) => {
          item.doClose();
        });
        this.$refs.filterSetRef.$refs.filterPopver[index].doShow(); // 弹框显示
      });
      this.$refs.filterSetRef.form.filterFiled = "";
    },
    // 删除选择的过滤条件字段
    deleteFilterFiled(index) {
      this.$refs.filterSetRef.$refs.filterPopver.map((item) => {
        item.doClose();
      });
      this.conditionVals = JSON.parse(this.conditionVals);
      // 判断公式中是否包含要删除的过滤条件
      if (this.conditionVals.filter.indexOf(index + 1) > -1) {
        this.$message.warning(
          this.$i18n.t("vue_label_report_notice_deletefilter")
        );
        this.conditionVals = JSON.stringify(this.conditionVals);
      } else {
        this.choosedFilterFileds.splice(index, 1);
        // 删除
        this.conditionVals.data.splice(index, 1);
        this.conditionVals = JSON.stringify(this.conditionVals);
        this.getReportDataByDetails();
      }
    },
    // 点击选择的过滤条件字段
    clickCondition(index) {
      if (this.choosedFilterFileds[index]) {
        this.screenCondition = this.choosedFilterFileds[index].labelname;
      }
    },
    // 监听关闭过滤条件字段弹框
    hideFilterPopver(item, index) {
      if (!item.isApply) {
        this.choosedFilterFileds.splice(index, 1);
      }
    },
    // 应用过滤条件
    applyCondition(item, index) {
      let editValue =
        this.$refs.filterSetRef.$refs.EditableCell[index].editValue;
      // 选项列表查找多选字段，获取的value为数组，需要转成后端需要的格式
      if (item.inputType === "multi-select") {
        editValue = setValueTypeConversion(editValue);
      }
      item.value.value = editValue; // 将选择的值更新在choosedFilterFileds中
      // 先清空过滤条件集合，然后再重新赋值
      this.conditionVals = JSON.parse(this.conditionVals);
      // 添加筛选条件时无法保存数据源对象的后两个，所以需要需要再传一次
      this.conditionVals.relatedObjIdC = this.allObjects.objectc || "";
      this.conditionVals.relatedObjIdD = this.allObjects.objectd || "";
      this.conditionVals.data = [];
      this.choosedFilterFileds.map((item) => {
        this.conditionVals.data.push({
          fieldId: item.fieldid,
          op: item.operator,
          val: item.value.value,
        });
      });
      this.conditionVals = JSON.stringify(this.conditionVals);
      this.getReportDataByDetails();
      this.$refs.filterSetRef.$refs.filterPopver[index].doClose();
      this.choosedFilterFileds[index].isApply = true; // 设置为已应用
    },
    // 点击保存按钮，判断是新增时的保存还是修改后的保存，即是否有报表id
    clickSave() {
      this.getParamsData(); // 初始化入参
      //保存并关闭事件
      //如果有传来ID即不显示输入名称对话框
      this.saveCloneTo = false; // 保存并关闭的按钮与点击保存按钮公用一个方法。
      if (this.$route.params.reportId) {
        // 有报表id，表示是修改后的保存
        this.saveReportTo = false;
        this.paramsObject.reporttypecustomid = this.tempReporttypecustomid;
        let reportInfosObj = JSON.parse(JSON.stringify(this.paramsObject));
        let obj = Object.assign(reportInfosObj, this.chartInfos);
        obj.id = this.$route.params.reportId; // id在报表保存和图表保存中均有，会覆盖，赋值放在最下面
        obj.name = this.reportName; // name在报表保存和图表保存中均有，会覆盖，赋值放在最下面
        ReportObjectApi.saveReportData(obj).then(
          (res) => {
            if (!res.result) {
              return;
            }
            this.iscloneReport ='';
            this.$message({
              showClose: true,
              message: this.$i18n.t("label.search.saveok"),
              type: "success",
            });
            this.$router.push({
              path: "/reportObject",
              query: {
                type: "reportDetail",
              },
            });
          },
          () => {}
        );
      } else {
        this.saveReportTo = true;
        let saveReportName = "";
        // 保存报表设置默认值,同环比报表为：对象名+月度同环比报表，其他报表为：对象+报表
        if (this.sameMonthReportFlag) {
          saveReportName =
            this.allObjectDatas[0] + this.$i18n.t("label.monthly.month.report");
        } else {
          saveReportName =
            this.allObjectDatas[0] + this.$i18n.t("report.label.report");
        }
        this.$refs.saveReportRef.form.reportLabel = saveReportName;
        this.$refs.saveReportRef.getFolders(); //调取获取报表文件夹接口
      }
    },
    // 新增时的保存报表
    async saveReportConfirm(form) {
      this.paramsObject.description = form.reportNote; // 报表描述
      this.paramsObject.reportfolderid = form.location; // 报表所在文件夹id
      // 保存时需要同步保存图表数据
      let tempParams = JSON.parse(JSON.stringify(this.paramsObject));
      let obj = Object.assign(tempParams, this.chartInfos);
      obj.name = form.reportLabel; // name在保存报表数据接口中有，在图表保存接口中也有，会冲突，所以在最后赋值
      await ReportObjectApi.saveReportData(obj);
      this.saveReportTo = false;
      this.$message.success(this.$i18n.t("vue_label_notice_new_success"));
      this.$router.push({
        path: "/reportObject/reportList",
        query: {
          type: "reportDetail",
        },
      });
      // 保存报表成功即清除传来的ID
      this.$store.commit(types.SET_EDITPAGE_FIRST_ID, "");
    },
    // 点击运行并保存
    runAndSave() {},
    // 关闭保存报表弹框
    saveReportCancel() {
      this.saveReportTo = false;
    },
    //运行页另存为
    runRunCommitB() {
      this.nextSaveReportTo = true;
    },
    // 关闭导出弹框
    exportBoxCancels() {
      this.exportReportTo = false;
    },
    //运行页另存为取消
    nextSaveReportCancel() {
      this.nextSaveReportTo = false;
    },
    //运行页删除
    runRunDelete() {
      this.deleteReportTo = true;
    },
    //运行页删除取消
    deleteReportCancel() {
      this.deleteReportTo = false;
    },
    // 运行页导出保存后重新保存原报表
    exportCloneReport(){
      this.cloneParamsObject.reporttypecustomid = this.tempReporttypecustomid;
        let reportInfosObj = JSON.parse(JSON.stringify(this.cloneParamsObject));
        let obj = Object.assign(reportInfosObj, this.chartInfos);
        obj.id = this.$route.params.reportId; // id在报表保存和图表保存中均有，会覆盖，赋值放在最下面
        obj.name = this.reportName; // name在报表保存和图表保存中均有，会覆盖，赋值放在最下面
        ReportObjectApi.saveReportData(obj).then(
          () => {},
          () => {}
        );
    },
    // 确定导出报表时对当前编辑项进行保存
    firstSaveExportReport(){
      // 有报表id，表示是修改后的保存
      this.paramsObject.reporttypecustomid = this.tempReporttypecustomid;
      let reportInfosObj = JSON.parse(JSON.stringify(this.paramsObject));
      let obj = Object.assign(reportInfosObj, this.chartInfos);
      obj.id = this.$route.params.reportId; // id在报表保存和图表保存中均有，会覆盖，赋值放在最下面
      obj.name = this.reportName; // name在报表保存和图表保存中均有，会覆盖，赋值放在最下面
      ReportObjectApi.saveReportData(obj).then(
        (res) => {
          if (res.result) {
            this.iscloneReport='save';
            this.$refs.exportReportRef.exportBoxConfirm();
          }
        },
        () => {}
      );
    },
    //运行页导出
    clickExport() {
      this.exportReportTo = true;
      // 蒙牛导出数据50000
      // 其他导出数据5000
      if(window.Glod.EXPORT_REPORT_MAX_SIZE){
        let newExps=[];//报表导出数据配置对象
        let exps= window.Glod.EXPORT_REPORT_MAX_SIZE.split(",");
        // 字符串转为对象
        exps&&exps.map((item)=>{
          newExps.push(eval("({" + item + "})"))
        })
        // 筛选出当前用户可导出报表数据的配置信息
        let orgData=newExps.filter((item)=>{
          return item[this.$CCDK.CCUser.getUserInfo().orgId]
        })
        // 根据配置信息设置报表导出最大值
        if(orgData&&orgData.length>0){
          this.exportReportMax=orgData[0][this.$CCDK.CCUser.getUserInfo().orgId];
        } else {
          this.exportReportMax=5000;
        }
      }else{
        this.exportReportMax=5000;
      }
      this.totalPages = Math.ceil(this.totalrecords / this.exportReportMax); // 总页数
    },
    /*
      判断是否可跳转，后端已做过判断，isAddLink为true、且有要跳转的id才可跳转，
      查找字段Y增加跳转详情页功能
    */
    isNameFiled(row, item) {
      if ((item.isAddLink && row[item.linkId])
          ||(item.schemefield_type=="Y"&&row[item.obj_alias+item.schemefieldName])) {
        return true;
      } else {
        return false;
      }
    },
    //点击跳转详情页name
    cardViewjump(event,data,field,flag) {
      if (event.srcElement.innerHTML == "  ") {
        return;
      }else if (data[field.linkId]||data[field.obj_alias+field.schemefieldName] ) {
        if(this.$store.state.navigationStyle && flag ){
          let id = "" ;
          if(data[field.linkId]){
            id = data[field.linkId]
          }else if(data[field.obj_alias+field.schemefieldName]){
            id = data[field.obj_alias+field.schemefieldName]
          }
          let menuObj={
            name: data[field.schemefield_name_alias], // tab名称
            id: id, //id，唯一标识tab的
            routerName:'consoleDetail',//路由的名字
            params:{
              page: id,
              oprateType:'DETAIL',
              id,
            },// 页面需要的参数
          }
          window.$CCDK.CCMenu.addMenu1(menuObj);
          // detail:单击时生成一级菜单并跳转
          // true:ctrl+单击，生成一级菜单不跳转，当前一级菜单下标不变
          if(flag==='detail'){
            this.$router.push({
              path: `/commonObjects/console-multi-screen/console-detail/${id}/DETAIL?dataId=${id}`,
            });
          }else if(flag===true){
            this.consoleData.data.level1Id = "menu";
          }
          return;
        }else{
          let id = "" ;
          if(data[field.linkId]){
            id = data[field.linkId]
          }else if(data[field.obj_alias+field.schemefieldName]){
            id = data[field.obj_alias+field.schemefieldName]
          }
          // 判断是否可跳转
          this.$router.push({
            path: `/commonObjects/detail/${id}/DETAIL`,
          });
        }
      } else {
        return
      }
    },
    // 给行列分组列及小计合计添加背景色
    cellClass({ row, column, columnIndex }) {
      // 列表式报表
      if (
        this.transversegroupList.length === 0 &&
        this.lengthwaysgroupList.length === 0
      ) {
        // 1.合计行
        if (row.level01 === "maxtotal") {
          return "background-color: #EFF7FC";
        } else {
          return "background-color: #ffffff";
        }
      }
      // 汇总式报表
      if (
        this.transversegroupList.length > 0 &&
        this.lengthwaysgroupList.length === 0
      ) {
        // 1.属于行分组列但不包含合计行；2.显示详细信息行时的小计；3.不显示详细信息行时的小计；
        if (
          (columnIndex < this.transversegroupList.length &&
            row.level01 !== "maxtotal") ||
          (this.$refs.rightTopRef.form.detailButt &&
            (row.level01 === "a" ||
              row.level01 === "b" ||
              row.level01 === "c")) ||
          (!this.$refs.rightTopRef.form.detailButt &&
            ((this.transversegroupList.length === 2 && row.level01 == "a") ||
              (this.transversegroupList.length === 3 &&
                (row.level01 == "a" || row.level01 == "b"))))
        ) {
          return "background-color: #F9F9FA";
        } else if (row.level01 === "maxtotal") {
          // 合计
          return "background-color: #EFF7FC";
        } else {
          return "background-color: #ffffff";
        }
      }
      // 矩阵式报表
      if (
        this.lengthwaysgroupList.length > 0 &&
        this.transversegroupList.length > 0
      ) {
        if (
          (this.$refs.matrixTableRef.selectRow === row &&
            this.$refs.matrixTableRef.selectColumn === column) ||
          (this.$refs.matrixTableRef.selectRow === "maxtotal" &&
            row.mapLengthGroup.detailOrSubtotal === "maxtotal" &&
            this.$refs.matrixTableRef.selectColumn === "total" &&
            column.property === "total")
        ) {
          // 先判断是否是在点击矩阵式报表的统计表的单元格、默认选择的右下角的合计数据
          return "background-color:#0076DE; color: #fff;border-radius: 3px;";
        } else {
          // 若是不，则再进行判断分组合计情况
          if (
            /*
            1.属于行分组列但不属于合计行；
            2.属于行分组的小计行但不包含合计列；
            3.属于列分组的小计列但不包含合计行；
          */
            (columnIndex < this.lengthwaysgroupList.length &&
              row.mapLengthGroup &&
              row.mapLengthGroup.detailOrSubtotal !== "maxtotal") ||
            (row.mapLengthGroup &&
              row.mapLengthGroup.detailOrSubtotal === "subtotal" &&
              column.property !== "total") ||
            (column.label === this.$i18n.t("label.subtotal") &&
              row.mapLengthGroup &&
              row.mapLengthGroup.detailOrSubtotal !== "maxtotal")
          ) {
            return "background-color: #F9F9FA";
          } else if (
            /*
            1.行分组的合计；
            2.列分组的合计；
          */
            (row.mapLengthGroup &&
              row.mapLengthGroup.detailOrSubtotal === "maxtotal") ||
            column.property === "total"
          ) {
            return "background-color: #EFF7FC";
          }
        }
      }
    },
    // 过滤条件弹框：inputType字段类型处理
    inputTypeConversion(schemefieldType, expressionType) {
      let inputTypeData = "";
      switch (schemefieldType) {
        case "Z": // 数值类型
          inputTypeData = expressionType;
          break;
        case "L": // 选项列表（多选）
        case "R": // 记录类型
          inputTypeData = "Q";
          break;
        // 查找、主祥字段，目前先不做精确精准搜素
        case "Y":
        case "M":
          inputTypeData = "V";
          break;
        default:
          // 以上情况之外
          inputTypeData = schemefieldType;
      }
      return FILTERVALUEINPUTTYPE[inputTypeData];
    },
    // 判断字段是否为复选框类型，且获取对应的value
    getCheckboxFiled(item, value) {
      // 定义变量：字段是否为复选框类型，复选框的value
      let data = {
        isCheckboxFiled: false,
        checkboxFiledValue: false,
      };
      if (item.schemefieldType !== "B") return data;
      data.isCheckboxFiled = true; // 属于复选框类型
      if (value === "true") {
        data.checkboxFiledValue = true;
      }
      return data;
    },
    // 获取运行状态下是否展示过滤器
    getFilterTo(filterTo) {
      this.filterTos = filterTo;
    },
    // 是否展示图表
    getGenerateChartTo(generateChartTo) {
      this.generateChartTos = generateChartTo;
    },
    // 是否开启自动更新
    getAutomaticUpdate(automaticUpdate) {
      this.automaticUpdates = automaticUpdate;
    },
    // 设置详细信息表置顶
    setDetailToTop() {
      this.detailToTopTo = !this.detailToTopTo;
    },
  },
  computed: {
    ...mapState({consoleData:(state)=>state.consoleData}),
    // 报表权限信息
    reportOperation: function () {
      return this.$store.getters.getReportOperation;
    },
    // 筛选项tab后，显示添加的筛选项数量（时间范围+过滤条件）
    filterNumber: function () {
      let num = 0;
      // 选择的时间范围字段choosedTimeRanges为对象，不是数组，不能判断length
      if (JSON.stringify(this.choosedTimeRanges) !== "{}") {
        num = 1;
      }
      return this.choosedFilterFileds.length + num;
    },
    // 右侧报表的宽度，不同情况下宽度不同
    classObject: function () {
      return {
        right_tables: true, // 默认状态，编辑状态且不展示字段列表，100% - 过滤器 - 字段单列
        right_tables_field: !this.runTo && this.displayFieldsTo, // 编辑状态且展示字段列表，100% - 过滤器 - 字段列表
        right_tables_run_filter: this.runTo && this.filterTos, // 运行状态且展示过滤器，100% - 过滤器
        right_tables_run: this.runTo && !this.filterTos, // 运行状态且不显示过滤器，仅显示报表，100%
      };
    },
    // 属于矩阵式报表且展示详情的情况
    isMatrixDetailTable: function () {
      if (
        this.transversegroupList.length > 0 &&
        this.lengthwaysgroupList.length > 0 &&
        this.detailButts
      ) {
        return true;
      }
      return false;
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeunloadFn);
  },
  // 被缓存的组件激活时调用。
  activated(){
    // 矩阵式报表
    // 一个列分组||两个列分组
    if(this.$refs.matrixTableRef){
      if(this.transversegroupList.length === 1){
        this.$refs.matrixTableRef.$refs.martrixeltable.bodyWrapper.scrollTop=this.$refs.matrixTableRef.matrixTableScrollTop;
      }else{
        this.$refs.matrixTableRef.$refs.martrixTwoeltable.bodyWrapper.scrollTop=this.$refs.matrixTableRef.matrixTableScrollTop;
      }
    }
    // 矩阵式报表详细信息行
    if(this.$refs.matrixDetailTableRef){
      this.$refs.matrixDetailTableRef.$refs.martrixelDetailelTable.bodyWrapper.scrollTop=this.$refs.matrixDetailTableRef.matrixDetailTableScrollTop
    }
    // 列表式报表
    if(this.$refs.listTypeTableRef){
      this.$refs.listTypeTableRef.$refs.listelTable.bodyWrapper.scrollTop=this.$refs.listTypeTableRef.listelTableScrolltop
    }
    // 汇总式报表
    if(this.$refs.summaryTypeTableRef){
      // 根据是否启用详细信息行按钮判断使用哪个table
      if(this.$refs.rightTopRef?.form?.detailButt){
        this.$refs.summaryTypeTableRef.$refs.summaryDetailelTable.bodyWrapper.scrollTop=this.$refs.summaryTypeTableRef.summaryTableScrollTop
      }else{
        this.$refs.summaryTypeTableRef.$refs.summaryelTable.bodyWrapper.scrollTop=this.$refs.summaryTypeTableRef.summaryTableScrollTop
      }
    }
  },
  // 离开搜索结果页的时候  通过兄弟传参  修改输入框是否清空
  beforeRouteLeave(to, from, next) {
    if(from.fullPath.indexOf("/reportObject/editReport")>-1 && this.iscloneReport=='save'){
      this.exportCloneReport();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.body {
  height: 100%;
  padding: 10px;
  font-size: 12px !important;
}
.editReport {
  ::v-deep a {
    text-decoration: none;
  }
  ::v-deep .el-table {
    overflow: visible !important;
    border-color: #e1e6ef;
    position: static !important;
    // background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
  }
  ::v-deep .el-table__body-wrapper {
    overflow: auto !important;
    flex: 1;
  }
  ::v-deep .el-table .cell {
    min-height: 23px;
  }
  ::v-deep .el-table td,
  ::v-deep .el-table th {
    vertical-align: top;
    border-color: #e1e6ef;
    padding: 7px 0 !important;
    font-size: 12px;
    color: #080707;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
  ::v-deep .el-table--border th.gutter:last-of-type {
    display: none !important;
  }
}
::v-deep .editReport {
  height: 100%;
  background-color: #fff;
  .editReport_bottom {
    background-color: #fff;
    height: calc(100% - 61px);
    display: flex;
    justify-content: space-between;
    .display_field {
      padding-top: 10px;
      width: 28px;
      background-color: #f9f9fa;
      font-size: 16px;
      color: #6b6d70;
      cursor: pointer;
      .display_field_center {
        display: flex;
        align-items: center;
        transform: rotate(-90deg);
        position: absolute;
        left: 51px;
        margin-top: 10px;
      }
    }
    .left_chooses {
      // width: 310px;
      width: 240px;
    }
    .right_tables {
      // width: calc(100% - 310px);
      width: calc(100% - 270px);
      border-left: 1px solid #dcdcdc;
      background: #f9f9f9;
      position: relative;
      .table_mask {
        width: 100%;
        height: calc(100% - 40px);
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        z-index: 99;
        border-radius: 2px;
      }
      .content_table {
        height: calc(100% - 40px);
        .table_echart_content {
          height: 100%;
          .table_echart {
            height: 100%;
            .table_echart_div {
              margin: 10px;
              height: 50% !important;
              display: flex;
              justify-content: space-between;
            }
            .data_echart {
              // background-color: #fff;
              // width: 100%;
              flex: 1;
              overflow: auto;
              height: 100%;
              padding: 0px 10px 10px 0;
            }
            .data_table {
              padding-bottom: 10px;
              width: 100%;
              height: 100%;
              .listTablepaging {
                width: 100%;
                background: #fff;
                text-align: right;
                padding: 11px 10px;
              }
              .listTypeTable-box {
                height: calc(100% - 40px) !important;
              }
              .draggable,
              .draggables {
                height: 100%;
              }
              .cell_word {
                cursor: pointer;
                .data_style {
                  display: flex;
                }
              }
              .cell_cursor {
                cursor: default;
                .data_style {
                  display: flex;
                }
              }
              .column_group_cell {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              ::v-deep .el-table__body tr:hover > td {
                background-color: transparent;
              }
              ::v-deep .el-loading-mask {
                z-index: 99;
              }
            }
            .data_table_active {
              height: 50% !important;
            }
            .data_table_width {
              width: 50% !important;
            }
          }
        }
        .number_style {
          text-align: right;
          justify-content: flex-end;
        }
      }
    }
    .right_tables_field {
      // width: calc(100% - 610px);
      width: calc(100% - 540px) !important;
    }
    .right_tables_run_filter {
      width: calc(100% - 240px) !important;
    }
    .right_tables_run {
      width: 100% !important;
    }
  }
  .editReport_bottom_run {
    height: calc(100% - 114px);
  }
  .groupsClass {
    background-color: "#F0F0F5";
  }

  .formulaActive {
    background-color: transparent !important;
  }
  .nameFiled {
    color: #006dcc !important;
    cursor: pointer;
  }
}
::v-deep .report_header {
  // padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
  .el-icon-right,
  .el-icon-back,
  .el-icon-top,
  .el-icon-bottom {
    cursor: pointer;
    margin-left: 5px;
    color: #999999;
    font-size: 14px;
    font-weight: bold;
  }
}
::v-deep .el-table--enable-row-transition .el-table_e _ body td {
  transition: background-color 11111s;
}

// 表头字体样式
::v-deep .el-table th {
  padding: 7px 0 !important;
  color: #080707;
  background: #f2f2f3;
  border-color: #e1e6ef;
  font-size: 12px;
  border-right: 5px solid #f2f2f3;
  &:hover {
    border-right: 5px solid #dddbda;
  }
}

// 拖拽时表头样式
::v-deep .chosen {
  border-color: #0070d2 !important;
  border-radius: 2px !important;
}

// 下拉框样式
::v-deep .el-select-group__title {
  padding-left: 15px;
  color: #6b6d70;
  font-weight: 600;
}

//滚动条
::v-deep ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  //滑块部分
  border-radius: 0;
  background: #c1c1c1 !important;
  -webkit-box-shadow: none !important;
}
::v-deep ::-webkit-scrollbar-track {
  //轨道部分
  border-radius: 0;
  background: #f1f1f1 !important;
  -webkit-box-shadow: none !important;
}
</style>

<style lang="scss">
@import "./style/indexPopover.scss";
</style>
