<!--
  编辑汇总公式弹框
-->
<template>
  <div class="summaryFormula">
    <div class="summaryFormula_center">
      <div class="center_header">
        <div class="header_left">
          {{ $t("pagecreator_page_button_edit") }}{{ formulaHeader }}
        </div>
        <div class="header_right" @click="summaryFormulaCancel">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-chahao"></use>
          </svg>
        </div>
      </div>
      <div class="center_center">
        <div class="left_fields">
          <div class="title_style">{{ $t("label.field") }}</div>
          <div class="search_input">
            <el-input
              :placeholder="$t('label.searchfield')"
              suffix-icon="el-icon-search"
              v-model="form.field"
              @input="realTimeSaear"
            ></el-input>
          </div>
          <div class="tree_style">
            <el-tree
              :data="isUseTempArrStatus ? isUseTempArrs : summaryTree"
              :props="defaultProps"
              @node-click="handleNodeClick"
              node-key="id"
              :default-expanded-keys="searchIdArr"
            ></el-tree>
          </div>
          <div class="choose_type">
            <el-select
              v-model="form.type"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              v-show="
                formulaHeader == this.$i18n.t('vue_label_report_summaryformula')
              "
            >
              <el-option
                v-for="item in allTypes"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-button
              type="primary"
              @click="insertApiname"
              :disabled="
                (formulaHeader == $t('vue_label_report_rowlevelformula') &&
                  filedData === '') ||
                (formulaHeader ==
                  this.$i18n.t('vue_label_report_summaryformula') &&
                  filedData === '' &&
                  form.type === '')
              "
              >{{ $t("label.insert") }}</el-button
            >
          </div>
        </div>
        <div class="right_calculates">
          <el-form ref="form" :model="form" label-width>
            <div class="first_line">
              <el-form-item
                :label="$t('vue_label_report_columnname')"
                prop="colName"
                :rules="[
                  {
                    required: true,
                    message: this.$i18n.t(
                      'vue_label_report_notice_namerequired'
                    ),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="form.colName"></el-input>
              </el-form-item>
              <el-form-item :label="$t('label.file.des')">
                <el-input v-model="form.describe"></el-input>
              </el-form-item>
            </div>
            <div class="second_line">
              <el-form-item :label="$t('vue_label_report_output')">
                <el-select
                  v-model="form.expressiontype"
                  :placeholder="$t('label.dashboardSetup.number')"
                  style="width: 380px; margin-right: 10px"
                >
                  <el-option
                    :label="$t('label.dashboardSetup.number')"
                    value="number"
                  ></el-option>
                  <!-- <el-option label="日期" value="date"></el-option>
                  <el-option label="日期/时间" value="dateTime"></el-option>
                  <el-option label="文本" value="text"></el-option>-->
                </el-select>
                <el-select
                  v-model="form.decimalplaces"
                  placeholder
                  style="width: 120px"
                  v-if="form.expressiontype == 'number'"
                >
                  <el-option
                    v-for="(item, index) in numberList"
                    :key="index"
                    :label="item"
                    :value="item"
                    >{{ item }}</el-option
                  >
                </el-select>
              </el-form-item>
            </div>
            <el-form-item :label="$t('vue_label_report_formulainformation')">
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 5 }"
                :placeholder="$t('label_tabpage_contentz')"
                v-model="form.text"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="all_operators">
            <div class="top_title">{{ $t("label.operator") }}</div>
            <div class="all_butts">
              <div
                class="each_butt"
                v-for="(item, index) in allOperations"
                :key="index"
                @click="clickOperator(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="bottom_butts">
            <div @click="summaryFormulaCancel">
              {{ $t("button_partner_cancel") }}
            </div>
            <div
              v-if="formulaHeader == $t('vue_label_report_rowlevelformula')"
              @click="lineFormulaApply('form')"
            >
              {{ $t("label.apply") }}
            </div>
            <div v-else @click="summaryFormulaApply('form')">
              {{ $t("label.apply") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "formulaHeader",
    "summaryTree",
    "expressions",
    "summaryFormulaTo",
    "formulaInfos",
    "summaryFormulaId",
  ],
  data() {
    return {
      numberList: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
      ],
      filedData: "",
      form: {
        field: "",
        type: "SUM",
        colName: "",
        describe: "",
        decimalplaces: 2,
        expressiontype: "number",
        text: "",
      },
      defaultProps: {
        children: "fieldList",
        label: "labelName",
      },
      allTypes: [
        {
          id: "SUM",
          label: this.$i18n.t("label.summation"),
        },
        {
          id: "AVG",
          label: this.$i18n.t("label.average"),
        },
        {
          id: "MAX",
          label: this.$i18n.t("label.maximum"),
        },
        {
          id: "MIN",
          label: this.$i18n.t("label.minimun"),
        },
        // ,{
        //   id: "",
        //   label: "唯一计数",
        // }
      ],
      allOperations: ["+", "-", "/", "*", "(", ")"],
      // 要不要使用临时数组显示到行级公式的树中
      isUseTempArrStatus: false,
      isUseTempArrs: [],
      searchIdArr: [],
    };
  },
  watch: {
    summaryFormulaTo: function () {
      if (this.formulaInfos === "") {
        this.form = {
          field: "",
          type: "SUM",
          colName: "",
          describe: "",
          decimalplaces: 2,
          expressiontype: "number",
          text: "",
        };
        this.filedData = "";
        this.searchIdArr = [];
      } else {
        this.form = {
          field: this.formulaInfos.apiname,
          type: "SUM",
          colName: this.formulaInfos.apiname,
          describe: this.formulaInfos.description,
          decimalplaces: this.formulaInfos.decimalplaces,
          expressiontype: "number",
          text: this.formulaInfos.expression,
        };
      }
    },
  },
  methods: {
    // 点击运算符
    clickOperator(item) {
      this.form.text += item;
    },
    summaryFormulaCancel() {
      this.$emit("summaryFormulaCancel");
    },
    // 行级公式点击应用
    lineFormulaApply(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            id: "",
            apiname: this.form.colName,
            expression: this.form.text,
            type: "L",
            reportid: "",
            description: this.form.describe,
            expressiontype: "",
            decimalplaces: this.form.decimalplaces,
          };
          this.$emit("lineFormulaApply", params);
        } else {
          return false;
        }
      });
    },
    // 汇总公式点击应用
    summaryFormulaApply(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = [];
          // 表达式中有内容
          if (this.expressions !== "") {
            data = JSON.parse(this.expressions).data;
            // 有汇总公式且名称相同
            if (
              data.some(
                (item) =>
                  item.type === "Z" && item.apiname === this.form.colName
              )
            ) {
              this.$message.error("列名称不能重复！");
            } else {
              let params = {
                id: "",
                apiname: this.form.colName,
                expression: this.form.text,
                type: "Z",
                reportid: "",
                description: this.form.describe,
                expressiontype: "",
                decimalplaces: this.form.decimalplaces,
              };
              this.$emit("summaryFormulaApply", params);
            }
          } else {
            let params = {
              id: "",
              apiname: this.form.colName,
              expression: this.form.text,
              type: "Z",
              reportid: "",
              description: this.form.describe,
              expressiontype: "",
              decimalplaces: this.form.decimalplaces,
            };
            this.$emit("summaryFormulaApply", params);
          }
        } else {
          return false;
        }
      });
    },
    handleNodeClick(data) {
      this.filedData = data;
    },
    // 点击插入
    insertApiname() {
      if (
        this.formulaHeader === this.$i18n.t("vue_label_report_rowlevelformula")
      ) {
        this.form.text +=
          this.filedData.schemetableName +
          "__c." +
          this.filedData.schemefieldName +
          "__c";
      } else {
        // 汇总公式插入字段需要选择汇总类型
        this.form.text +=
          this.filedData.schemetableName +
          "__c." +
          this.filedData.schemefieldName +
          "__c:" +
          this.form.type;
      }
    },
    // 实时搜索
    realTimeSaear(value) {
      let arr = this.summaryTree.map((item) => {
        return item.fieldList;
      });
      let flatArr = arr.flatMap((i) => i);
      // 搜索出的对应的数组
      let searchArrRes = flatArr.filter((item) => {
        // 模糊搜索
        return item.labelName.indexOf(value) > -1;
      });
      if (value) {
        this.isUseTempArrStatus = true; //使用临时数组
        this.isUseTempArrs = JSON.parse(JSON.stringify(this.summaryTree));
        // 搜索出的id
        this.searchIdArr = searchArrRes.map((item) => item.id);
        this.isUseTempArrs.forEach((item) => {
          let arr = item.fieldList.filter((itemx) => {
            return this.searchIdArr.includes(itemx.id);
          });
          item.fieldList = arr;
        });
      } else {
        this.isUseTempArrStatus = false;
        this.searchIdArr = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/bouncedForm.scss";

.summaryFormula {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.4);
  min-height: 500px;
  overflow: hidden;

  .summaryFormula_center {
    width: 892px;
    /* height: 689px; */
    height: 570px;
    background: white;
    position: relative;
    margin: 5% auto; /*水平居中*/
    /* top: 70px; 偏移 */
    transform: translateY(-50% -50%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    .center_header {
      padding: 17px 20px 15px 20px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dcdcdc;
      .header_left {
        color: #080707;
      }
      .header_right {
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    .center_center {
      /* height: 633px; */
      height: 510px;
      display: flex;
      justify-content: space-between;
      .left_fields {
        width: 340px;
        height: 100%;
        padding: 15px 20px;
        background: #f9f9f9;
        border-radius: 4px;
        text-align: left;
        .title_style {
          margin-bottom: 15px;
          font-size: 13px;
          color: #080707;
        }
        .search_input {
          ::v-deep .el-input__inner {
            margin-bottom: 10px;
            width: 300px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
            border-radius: 4px;
          }
          ::v-deep .el-input__icon {
            line-height: 28px;
          }
        }
        .tree_style {
          /* height: 429px; */
          height: 350px;
          margin-bottom: 15px;
          border: 1px solid #dcdcdc;
          border-radius: 4px;
          ::v-deep .el-tree {
            height: 100%;
            overflow-y: auto;
            .el-tree-node.is-current > .el-tree-node__content {
              background-color: #4e8df7 !important;
              color: #ffffff;
            }
          }
        }
        .choose_type {
          display: flex;
          justify-content: space-between;
          ::v-deep .el-input__inner {
            margin-bottom: 10px;
            height: 30px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
          }
          ::v-deep .el-input__icon {
            height: 30px;
            line-height: 30px;
          }
          .el-button--primary {
            width: 54px;
            height: 30px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            background-color: #1b5297;
            border-color: #1b5297;
          }
          .el-button--primary.is-disabled {
            background: #c9c7c5 !important;
            border-color: #c9c7c5 !important;
          }
        }
      }
      .right_calculates {
        width: 552px;
        height: 100%;
        padding: 15px 20px 10px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        ::v-deep .el-form {
          .first_line {
            display: flex;
            justify-content: space-between;
            .el-input {
              width: 250px;
            }
          }
          .second_line {
            .el-form-item__content {
              display: flex;
              // justify-content: space-between;
            }
          }
          .el-input {
            height: 30px;
            .el-input__inner,
            .el-input__icon {
              height: 30px;
              line-height: 30px;
            }
          }
        }
        .all_operators {
          .top_title {
            margin-bottom: 5px;
            font-size: 12px;
            color: #545454;
            text-align: left;
          }
          .all_butts {
            display: flex;
            div {
              width: 32px;
              height: 32px;
              margin-right: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 13px;
              color: #1b5297;
              border: 1px solid #dcdcdc;
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }
        .bottom_butts {
          height: 60px;
          padding: 0 20px;
          border-radius: 0 0 8px 8px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          div {
            padding: 6px 12px;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
          }
          div:first-child {
            color: #5780b3;
            border: 1px solid #dcdcdc;
            background: #fff;
            margin-right: 10px;
          }
          div:last-child {
            color: #fff;
            background: #1b5297;
          }
        }
      }
    }
  }
}
</style>