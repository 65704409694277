<!-- 
    文件说明：同环比报表中左侧筛选组件
    创建时间：2021-9-15
    开发人员：张凤
 -->
<template>
  <div class="sameMonth">
    <div v-if="!appReport">
      <div class="each_tab_box">
        <div class="each_tab">
          <svg class="icon" aria-hidden="true" slot="reference">
            <use href="#icon-zhaiyao"></use>
          </svg>
          <div>{{ $t("label.outline") }}</div>
        </div>
        <div></div>
      </div>

      <div class="select_box">
        <div class="time_dimension">
          <div class="title">
            {{ $t("label.select.time.dimension") }}
          </div>
          <div class="object_box">
            <svg aria-hidden="true" class="icon">
              <use href="#icon-zuhang"></use>
            </svg>
            <span class="object_name">{{ allObjectDatas[0] }}</span>
          </div>
          <el-select
            v-model="chooseDimensionData"
            clearable
            filterable
            :placeholder="$t('label.tabpage.pleaseSelectz')"
            @change="changeObjectOne"
          >
            <el-option
              v-for="(item, index) in timeRanges"
              :key="index"
              :label="item.labelName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="time_range">
          <div class="title">
            {{ $t("label.select.time.range") }}
          </div>
          <div class="start_time">
            <div class="time_title">
              {{ $t("label.import.index.starttime") }}
            </div>
            <el-date-picker
              style="width: 80%; left: 0"
              v-model="sameMonthStarttimeData"
              value-format="yyyy-MM-dd"
              :placeholder="$t('label.projectManagement.Selectmonth')"
              @change="changeStartTime"
              :picker-options="pickerStartOptions"
            >
            </el-date-picker>
          </div>
          <div class="end_time">
            <div class="time_title">
              {{ $t("label.endtime") }}
            </div>
            <el-date-picker
              v-model="sameMonthEndtimeData"
              value-format="yyyy-MM"
              type="month"
              :placeholder="$t('label.projectManagement.Selectmonth')"
              @change="changeEndTime(sameMonthEndtimeData)"
              :picker-options="pickerEndOptions"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="time_target">
          <div class="title">
            {{ $t("label.select.statistical.indicators") }}
          </div>
          <div class="object_box">
            <svg aria-hidden="true" class="icon">
              <use href="#icon-zuhang"></use>
            </svg>
            <span class="object_name">
              {{ allObjectDatas[0] }}
            </span>
          </div>
          <el-select
            @change="changeObjectTwo"
            clearable
            filterable
            v-model="chooseStatisticaLindicatorsData"
            :placeholder="$t('label.tabpage.pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in listSummaryField"
              :key="index"
              :label="item.labelName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div v-else class="appSame">
      <div class="filter-box">
        <div class="filter_title">
          {{ $t("label.weixin.report.filters") }}
        </div>
        <div v-if="!filterBoxFlage" class="drop-icon" @click="getDropIcon()">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-drop-down"></use>
          </svg>
        </div>
        <div v-if="filterBoxFlage" class="drop-icon" @click="getDropIcon()">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-a-Putaway"></use>
          </svg>
        </div>
      </div>
      <div v-if="filterBoxFlage" class="timefilter">
        <!-- 时间维度 -->
        <div class="filter_show_me">
          <div class="show_me_title">{{ $t("c1770") }}</div>
          <el-input
            v-model="chooseDimensionDataLabel"
            @focus="getshowToAll"
            placeholder=""
          >
            <svg slot="suffix" class="icon" aria-hidden="true">
              <use href="#icon-drop-down"></use>
            </svg>
          </el-input>
        </div>
        <!-- 开始时间 -->
        <div class="filter_show_me">
          <div class="show_me_title">
            {{ $t("label.import.index.starttime") }}
          </div>
          <el-date-picker
            v-model="sameMonthStarttimeData"
            value-format="yyyy-MM-dd"
            type="month"
            :placeholder="$t('label.projectManagement.Selectmonth')"
            @change="changeStartTime"
            :picker-options="pickerStartOptions"
          >
          </el-date-picker>
        </div>
        <!-- 结束时间 -->
        <div class="filter_show_me">
          <div class="show_me_title">{{ $t("label.endtime") }}</div>
          <el-date-picker
            v-model="sameMonthEndtimeData"
            value-format="yyyy-MM"
            type="month"
            :placeholder="$t('label.projectManagement.Selectmonth')"
            @change="changeEndTime(sameMonthEndtimeData)"
            :picker-options="pickerEndOptions"
          >
          </el-date-picker>
        </div>
        <!-- 统计指标 -->
        <div class="filter_show_me">
          <div class="show_me_title">
            {{
              $t("front-lightning-module-v1-dashborad-statistical-indicators")
            }}
          </div>
          <el-input
            v-model="StatisticaLindicatorsDataLabel"
            @focus="getstatisticalAll"
            placeholder=""
          >
            <svg slot="suffix" class="icon" aria-hidden="true">
              <use href="#icon-drop-down"></use>
            </svg>
          </el-input>
        </div>
        <div class="runreport-box">
          <div class="runreport" @click="runSameReport">
            {{ $t("label.runreport") }}
          </div>
        </div>
      </div>

      <!-- 时间维度弹框 -->
      <el-dialog
        :visible.sync="showToAllFlag"
        :close-on-click-modal="true"
        :show-close="false"
        width="80%"
      >
        <div class="show-dialog">
          <el-radio-group
            v-model="chooseDimensionData"
            @change="changeObjectOne"
          >
            <el-radio
              @click.native="getTimeDimension($event, item.id)"
              v-for="(item, index) in timeRanges"
              :key="index"
              :label="item.id"
            >
              {{ item.labelName }}
            </el-radio>
          </el-radio-group>
        </div>
      </el-dialog>
      <!-- 统计指标弹框 -->
      <el-dialog
        :visible.sync="showstatisticalFlag"
        :close-on-click-modal="true"
        :show-close="false"
        width="80%"
      >
        <div class="show-dialog">
          <el-radio-group
            v-model="chooseStatisticaLindicatorsData"
            @change="changeObjectTwo"
          >
            <el-radio
              @click.native="getStatistical($event, item.id)"
              v-for="(item, index) in listSummaryField"
              :key="index"
              :label="item.id"
            >
              {{ item.labelName }}
            </el-radio>
          </el-radio-group>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 区分移动端报表
    appReport: {
      type: String,
      default: "",
    },
    listSummaryField: {
      // 数值和金额类型的字段
      type: Array,
      default: () => [],
    },
    allObjectDatas: {
      // 表头业务对象数据
      type: Array,
      default: () => [],
    },
    timeRanges: {
      // 日期类型的字段
      type: Array,
      default: () => [],
    },
    // 开始时间
    sameMonthStarttime: {
      type: String,
      default: "",
    },
    // 结束时间
    sameMonthEndtime: {
      type: String,
      default: "",
    }, // 日期字段id默认创建时间
    chooseDimension: {
      type: String,
      default: "",
    },
    // 数值金额字段id,默认记录数
    chooseStatisticaLindicators: {
      type: String,
      default: "",
    },
  },
  data() {
    let that = this;
    return {
      // 时间维度弹框
      showToAllFlag: false,
      // 筛选器展开或关闭列表
      filterBoxFlage: false,
      // 移动端时间维度标签名
      chooseDimensionDataLabel: "",
      //统计指标弹框开关
      showstatisticalFlag: false,
      // 统计指标名称
      StatisticaLindicatorsDataLabel: "",
      chooseStatisticaLindicatorsData: this.chooseStatisticaLindicators,
      sameMonthEndtimeData: this.sameMonthEndtime,
      sameMonthStarttimeData: this.sameMonthStarttime,
      chooseDimensionData: this.chooseDimension,
      // 禁选开始时间范围
      pickerStartOptions: {
        disabledDate(time) {
          if (that.sameMonthEndtimeData) {
            return (
              time.getTime() > new Date(that.sameMonthEndtimeData).getTime() ||
              time.getTime() > Date.now() ||
              time.getTime() < new Date(that.fiftyMonths).getTime()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      // 禁选结束时间范围
      pickerEndOptions: {
        disabledDate(time) {
          if (that.sameMonthStarttimeData) {
            return (
              time.getTime() <
                new Date(that.sameMonthStarttimeData).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
    };
  },
  watch: {},
  computed: {
    // 开始时间范围在结束时间的50个月之内
    fiftyMonths: function () {
      let oldDate = new Date(this.sameMonthEndtimeData);
      let day = oldDate.getDay() + 1;
      let month = oldDate.getMonth() - 1;
      let year = oldDate.getFullYear() - 4;
      let date = "";
      if (month > 0) {
        date = year + "-" + month + "-" + day;
        return date;
      } else {
        date = year - 1 + "-" + (12 + month) + "-" + day;
        return date;
      }
    },
  },
  mounted() {
    this.timeRanges.map((item) => {
      if (item.id == this.chooseDimension) {
        this.chooseDimensionDataLabel = item.labelName;
      }
    });
    this.listSummaryField.map((item) => {
      if (item.id == this.chooseStatisticaLindicatorsData) {
        this.StatisticaLindicatorsDataLabel = item.labelName;
      }
    });
  },
  methods: {
    // 点击运行报表按钮
    runSameReport() {
      this.$emit("runSameReport");
    },
    // 时间维度弹框
    getTimeDimension(el, value) {
      if (el.target.tagName === "INPUT") {
        return;
      } else {
        this.$emit("changeObjectOne", value, this.appReport);
        this.timeRanges.map((item) => {
          if (item.id == value) {
            this.chooseDimensionDataLabel = item.labelName;
          }
        });
        this.chooseDimensionData = value;
        this.showToAllFlag = false;
      }
    },
    // 统计指标弹框
    getStatistical(el, value) {
      if (el.target.tagName === "INPUT") {
        return;
      } else {
        this.$emit("changeObjectTwo", value, this.appReport);
        this.listSummaryField.map((item) => {
          if (item.id == value) {
            this.StatisticaLindicatorsDataLabel = item.labelName;
          }
        });
        this.chooseStatisticaLindicatorsData = value;
        this.showstatisticalFlag = false;
      }
    },
    // 时间维度
    getshowToAll() {
      this.showToAllFlag = true;
    },
    // 统计指标
    getstatisticalAll() {
      this.showstatisticalFlag = true;
    },
    // 点击图表收起或显示筛选器
    getDropIcon() {
      this.filterBoxFlage = !this.filterBoxFlage;
    },
    // 更改对象1
    changeObjectOne(value) {
      this.$emit("changeObjectOne", value, this.appReport);
      this.timeRanges.map((item) => {
        if (item.id == value) {
          this.chooseDimensionDataLabel = item.labelName;
        }
      });
      this.showToAllFlag = false;
    },
    // 更改对象2
    changeObjectTwo(value) {
      this.$emit("changeObjectTwo", value, this.appReport);
      this.listSummaryField.map((item) => {
        if (item.id == value) {
          this.StatisticaLindicatorsDataLabel = item.labelName;
        }
      });
      this.showstatisticalFlag = false;
    },
    // 更改开始时间
    changeStartTime(value) {
      this.$emit("changeStartTime", value, this.appReport);
    },
    // 更改结束时间
    changeEndTime(value) {
      this.$emit("changeEndTime", value, this.appReport);
    },
  },
};
</script>
<style lang='scss' scoped>
.filter-box {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
  background: #f3f6f9;
  padding: 0 15px;
  line-height: 30px;
  color: #7786a5;
  border-bottom: 1px solid #d8dde6 !important;
  .drop-icon {
    svg {
      margin-top: 8px;
      width: 15px;
      height: 15px;
    }
  }
}
.runreport-box {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 25px 0;
  .runreport {
    color: #e5851a;
    font-size: 12px;
    border: 1px solid #e5851a;
    width: 60%;
    border-radius: 2px;
    text-align: center;
    height: 33px;
    line-height: 33px;
    font-size: 15px !important;
  }
}
::v-deep .el-dialog {
  margin-top: 10% !important;
  overflow-y: auto !important;
  max-height: 80%;
  .el-radio,
  .el-radio__inner,
  .el-radio__input {
    top: 34%;
  }
}
.appSame {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-radio-group {
    width: 100%;
  }
  ::v-deep .el-radio {
    width: calc(100% - 20px);
    line-height: 50px;
    border-bottom: 1px solid #e1e1e1;
    margin-right: 0;
    padding: 0 10px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    .el-radio__label {
      color: #333333;
      font-size: 15px;
    }
    .el-radio__inner::after {
      width: 7px;
      height: 7px;
      border-radius: 100%;
      border-color: #787878;
      background-color: #009ed0;
    }
    .el-radio__input.is-checked .el-radio__inner {
      background: #c7e7f6;
      border-color: #787878;
    }
  }
  ::v-deep .el-input__suffix {
    height: 30px;
    line-height: 30px;
    margin-right: 20px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.timefilter {
  margin-bottom: 25px;

  ::v-deep .el-input__suffix {
    height: 30px;
    line-height: 30px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  .filter_title {
    color: #5e6077;
    font-size: 11px;
  }
  .filter_show_me {
    padding: 0 15px;
    .show_me_title {
      color: #5e6077;
      margin: 15px 0 2px 0;
      font-size: 12px;
    }
    ::v-deep .el-select {
      width: 100%;
    }

    ::v-deep .el-input {
      height: 33px;
      line-height: 1px;
      .el-input__inner {
        line-height: 1px;
        height: 33px;
        // padding: 0 10px;
        font-size: 15px;
      }
      .el-input__inner:focus {
        border: 1px solid #e5851a;
      }
      .el-input__icon {
        line-height: 33px;
      }
    }
    ::v-deep .el-date-editor .el-input.el-input__inner {
      width: 100% !important;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
}
.sameMonth {
  display: flex;
  flex-direction: column;
  .each_tab_box {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dcdcdc;
    .each_tab {
      padding: 0 13px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #6b6d70;
      font-size: 13px;
      height: 40px;
      background: #fff;
      margin-bottom: -1px;
      width: 50%;
      border-bottom: 3px solid #006dcc;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      div {
        width: 50%;
        cursor: pointer;
        color: #6b6d70;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }

  ::v-deep .el-date-editor.el-input__inner {
    width: 180px !important;
  }
  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 180px !important;
  }
  .select_box {
    padding: 9px 13px;
    // 时间维度
    .time_dimension,
    .time_target {
      ::v-deep .el-select {
        width: 100%;
        margin-bottom: 20px;
      }
      ::v-deep .el-input__inner {
        width: 100%;
      }
      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }
    // 时间范围
    .time_range {
      .time_title {
        height: 32px;
        line-height: 32px;
        min-width: 60px;
        margin-right: 10px;
        color: #080707;
        white-space: nowrap;
      }
    }

    // 统计指标
    .time_target {
      margin-top: 26px;
    }
    .title {
      font-size: 13px;
      color: #6b6d70;
      margin-bottom: 20px;
    }
    .object_box {
      margin: 0 0 10px;
      .object_name {
        color: #080707;
        font-size: 12px;
      }
      svg {
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
    }
    ::v-deep .el-select,
    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    ::v-deep .el-select .el-input__inner {
      font-size: 12px;
    }
  }
  .end_time,
  .start_time {
    display: flex;
    margin-bottom: 12px;
    ::v-deep .el-input__icon {
      line-height: 32px;
    }
    ::v-deep .el-month-table td div {
      padding: 0 !important;
      height: 40px !important;
    }
    ::v-deep .el-month-table td {
      padding: 0 !important;
    }
  }
}
</style>

<style lang='scss'>
.end_time,
.start_time {
  ::v-deep .el-month-table td div {
    padding: 0 !important;
    height: 40px !important;
  }
  ::v-deep .el-month-table td {
    padding: 0 !important;
  }
}
</style>