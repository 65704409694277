<!-- 右侧顶部，刷新提示信息及行计数、详细信息、自动更新预览按钮 -->
/* 
  行计数按钮：
    列表式报表不显示；
    汇总式报表：不显示详细信息行时控制记录数列；显示详细信息行时控制小计旁边的数字；
    矩阵式报表：控制记录数行；
  禁用情况：仅有记录数，没有其他数值类型字段的统计时，默认打开，不可关闭，为禁用状态。
*/
<template>
  <div class="right_tables_top">
    <!-- 表格上方提示文字 -->
    <div class="see_information">
      <!-- 不允许自动更新 -->
      <div v-if="!form.automaticUpdate && showRefresh" class="preview">
        <div class="report_sample">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-warning"></use>
          </svg>
          <div>{{ $t("vue_label_report_preview") }}</div>
        </div>
        <div class="refresh" @click="clickRefresh">
          {{ $t("label.dashboard.refresh") }}
        </div>
      </div>
      <!-- 允许自动更新 -->
      <div class="report_sample" v-else-if="!runTo">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-checkMark"></use>
        </svg>
        <div>{{ $t("vue_label_report_onlydisplaypart") }}</div>
      </div>
      <!-- 运行状态下显示仅显示2000条数据 -->
      <div class="report_sample" v-else-if="runTo && runShowDataTo">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-warning"></use>
        </svg>
        <div>{{ $t("vue_label_report_onlydisplay2000") }}</div>
      </div>
    </div>

    <div class="top_btn">
      <!-- 行计数按钮 -->
      <div class="line_count" v-show="chooseRowFileds.length > 0">
        {{ $t("vue_label_report_rowcount") }}
        <el-switch
          v-model="form.lineCountButt"
          active-color="#205396"
          inactive-color="#AEABA9"
          @change="changeLineCountButt"
          :disabled="lineCountButtDisabled"
        ></el-switch>
      </div>
      <!-- 详细信息行按钮 -->
      <div class="btn_one" v-show="chooseRowFileds.length > 0">
        {{ $t("vue_label_report_detailline") }}
        <el-switch
          v-model="form.detailButt"
          active-color="#205396"
          inactive-color="#AEABA9"
          @change="changeDetailButt"
        ></el-switch>
      </div>
      <!-- 自动更新预览按钮 -->
      <div class="btn_two">
        {{ $t("vue_label_report_autoupdatepreview") }}
        <el-switch
          v-model="form.automaticUpdate"
          active-color="#205396"
          inactive-color="#AEABA9"
          @change="clickRefresh"
        ></el-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showRefresh: {
      // 显示刷新
      type: Boolean,
      default: false,
    },
    isshowRowCount: {
      // 是否显示行计数按钮
      type: Boolean,
      default: false,
    },
    isshowDetailedInfor: {
      // 是否显示详细信息行按钮
      type: Boolean,
      default: false,
    },
    runTo: {
      // 是否运行状态
      type: Boolean,
      default: false,
    },
    runShowDataTo: {
      // 运行状态下是否仅显示的2000条数据
      type: Boolean,
      default: false,
    },
    chooseRowFileds: {
      // 行分组数据
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        lineCountButt: "", //是否显示行计数，默认展开
        detailButt: "", //后端不保存isShow，所以不需要回显
        automaticUpdate: false, // 自动更新预览按钮，默认为false
      },
    };
  },
  watch: {
    // 监听自动更新按钮
    "form.automaticUpdate": {
      handler() {
        this.$emit("getAutomaticUpdate", this.form.automaticUpdate);
      },
      immediate: true,
    },
    // // 行计数按钮是否禁用，若是禁用，设置开启行计数按钮
    "$store.getters.getLineCountButtDisabled": {
      handler(val) {
        if (val) {
          this.form.lineCountButt = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    // 计算行计数按钮是否禁用，若是禁用，设置开启行计数按钮
    lineCountButtDisabled: function () {
      return this.$store.getters.getLineCountButtDisabled;
    },
  },
  methods: {
    // 切换行计数按钮，将事件传回index做处理
    changeLineCountButt() {
      this.$emit("changeLineCountButt");
    },
    // 切换信息行按钮
    changeDetailButt() {
      this.$emit("changeDetailButt", this.form.detailButt);
    },
    // 点击刷新
    clickRefresh() {
      this.$emit("clickRefresh");
    },
  },
};
</script>

<style lang='scss' scoped>
.right_tables_top {
  height: 37px;
  line-height: 37px;
  padding: 0 13px;
  border-bottom: 1px solid #dcdcdc;
  color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  background-color: #fff;
  .see_information {
    .report_sample {
      display: flex;
      align-items: center;
      color: #6b6d70 !important;
      img,
      svg {
        margin-right: 5px;
      }
    }
    .preview {
      display: flex;
      .report_sample {
        color: #6b6d70 !important;
        img,
        svg {
          margin-right: 5px;
        }
      }
      .refresh {
        color: #0099ff;
        cursor: pointer;
      }
    }
  }
  .top_btn {
    display: flex;
    .el-switch {
      margin-left: 2px;
      margin-top: -2px;
    }
    .line_count,
    .btn_one {
      margin-right: 10px;
    }
  }
}
</style>
