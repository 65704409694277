<!-- 矩阵式报表的详表 -->
<template>
  <div
    class="detail_table draggable"
    :class="{ detailToTopClass: detailToTopTo }"
  >
    <div class="detail_top_title">
      <div class="div_left">
        <!-- 详细信息 -->
        <span class="detail_info">{{ $t("label.more.info") }}</span>
        <!-- {0}行 -->
        <span
          >（{{
            $t("vue_label_report_howmuchlines", {
              length: AlllistTableDetail.length,
            })
          }}）</span
        >
        <!-- 提示信息 -->
        <div class="title_info" v-if="cellArr.length === 0">
          <i class="el-icon-info"></i>
          {{ $t("vue_label_report_clickcrosstofliter") }}
        </div>
        <!-- 点击单元格选择的内容 -->
        <div class="choose_infos" v-if="cellArr.length > 0">
          <div
            class="each_info"
            v-for="(item, index) in cellArr"
            :key="index"
            v-show="item.value !== undefined"
          >
            <div>{{ item.labelName }}</div>
            <div>= {{ item.value }}</div>
          </div>
          <div class="remove_style" @click="removeConditions">
            {{ $t("label.delete.condition") }}
          </div>
        </div>
      </div>
      <el-button
        type="primary"
        size="mini"
        :icon="detailToTopTo ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
        @click="setDetailToTop"
      ></el-button>
    </div>
    <el-table
      ref="martrixelDetailelTable"
      :data="listTableDetail"
      style="width: 100%"
      height="calc(100% - 40px)"
      border
      v-if="showTable"
      v-loadmore="loadmore"
    >
      >
      <el-table-column min-width="60" align="center" class-name="filtered">
        <template slot-scope="scope">
          <span v-text="getIndex(scope.$index)"></span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in listCheckedFields.filter((field) => {
          return field.id != 'totalrecord';
        })"
        :key="index + item.id"
        :prop="item.schemefield_name_alias"
        min-width="150"
      >
        <template #header>
          <div
            class="report_header"
            @drop="(e) => handleTargetDrop(e, index)"
            @dragover.prevent
          >
            <div ref="headerData">{{ item.labelName }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event, 'detail')"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div :class="{ number_style: judgeNumber(item) }">
            <div v-if="judgeNumber(item)">
              {{ scope.row[item.schemefield_name_alias] }}
            </div>
            <div v-else>
              <!-- 判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
              <a
                v-if="isNameFiled(scope.row, item)"
                class="nameFiled"
                @click.exact="cardViewjump($event,scope.row,item, 'detail')"
                @click.ctrl.exact="cardViewjump($event,scope.row,item, true)"
                @click.meta.exact="cardViewjump($event,scope.row,item, true)"
              >
                <span v-if="isMapTaskRel(item)">{{
                  mapTaskRel[scope.row[item.schemefield_name_alias]]
                }}</span>
                <span v-else>{{ scope.row[item.schemefield_name_alias] }}</span>
              </a>
              <span v-else-if="isMapTaskRel(item)">{{
                mapTaskRel[scope.row[item.schemefield_name_alias]]
              }}</span>
              <span v-else>
                <!-- 复选框字段 -->
                <span
                  v-if="
                    getCheckboxFiled(
                      item,
                      scope.row[item.schemefield_name_alias]
                    ).isCheckboxFiled
                  "
                >
                  <el-checkbox
                    :value="
                      getCheckboxFiled(
                        item,
                        scope.row[item.schemefield_name_alias]
                      ).checkboxFiledValue
                    "
                    disabled
                  ></el-checkbox>
                </span>
                <span v-else>
                  {{ scope.row[item.schemefield_name_alias] }}</span
                ></span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 行级公式 -->
      <el-table-column
        :prop="expressionLine[0].apiname"
        style="width: auto; padding: 3px 6px 3px 4px"
        v-if="expressionLine.length > 0"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div>{{ expressionLine[0].apiname }}</div>
            <div @click="clickImg(expressionLine[0])">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="expressionLine[0]"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="
                  sortingOrder(expressionLine[0], $event, 'detail')
                "
                @deleteGroupRow="deleteGroupRow(expressionLine[0])"
                @deleteGroupColumn="deleteGroupColumn(expressionLine[0])"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="padding-left: 10px">
            {{ scope.row["t0" + expressionLine[0].apiname] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import colOperation from "./colOperation";

export default {
  components: {
    colOperation,
  },
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign && that.total <= that.DetailPagenum) {
            that.total += 1;
            that.loadmore();
          }
          that.matrixDetailTableScrollTop=this.scrollTop;
        });
      },
    },
  },
  props: {
    loadingDetail: {
      type: Boolean,
      default: false,
    },
    //滚动加载的页数
    DetailPagenum: {
      type: Number,
      default: 1,
    },
    cellArr: {
      type: Array,
      default: () => [],
    },
    listTableDetail: {
      type: Array,
      default: () => [],
    },
    // 页面全部数据
    AlllistTableDetail: {
      type: Array,
      default: () => [],
    },
    listCheckedFields: {
      type: Array,
      default: () => [],
    },
    groupIds: {
      type: Array,
      default: () => [],
    },
    expressionLine: {
      // 行级公式
      type: Array,
      default: () => [],
    },
    isNameFiled: {
      // 判断是否可跳转，后端已做过判断，isAddLink为true即可跳转
      type: Function,
      default: () => {},
    },
    changeMicrometerLevel: {
      // 将数值类型转换为千分位格式
      type: Function,
      default: () => {},
    },
    cardViewjump: {
      // 点击跳转详情页name
      type: Function,
      default: () => {},
    },
    getCheckboxFiled: {
      // 判断字段是否为复选框类型
      type: Function,
      default: () => {},
    },
    judgeNumber: {
      // 判断是否是数字方法
      type: Function,
      default: () => {},
    },
    isMapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Function,
      default: () => {},
    },
    mapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Object,
      default: () => {},
    },
    // colOperation组件需接收的数据
    chooseRowFileds: {
      type: Array,
      default: () => [],
    },
    choosedColumnFileds: {
      type: Array,
      default: () => [],
    },
    indexData: {
      type: Object,
      default: () => {},
    },
    dateGroupings: {
      type: Object,
      default: () => {},
    },
    statisticsDatas: {
      type: Object,
      default: () => {},
    },
    sortDatas: {
      type: Object,
      default: () => {},
    },
    detailToTopTo: {
      // 详细信息表是否置顶
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTable: true,
      total: 1, //页数
      matrixDetailTableScrollTop:0,//矩阵式详细信息行滚动条距上位置
    };
  },
  watch: {
    listCheckedFields: {
      handler() {
        this.$nextTick(() => {
          this.columnDrop();
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    loadmore() {
      this.$emit("loadMoreData", this.total, "matrixDetail");
    },
    //获取表格序号
    getIndex($index) {
      //表格序号
      return $index + 1;
    },
    // 点击清除
    removeConditions() {
      this.$emit("removeConditions");
    },
    clickImg(item) {
      this.$emit("clickImg", item);
    },
    sortingOrder(item, $event, tableType) {
      // 排序
      this.$emit("sortingOrder", item, $event, tableType);
    },
    deleteGroupRow() {
      this.$emit("deleteGroupRow", DataTransferItem);
    },
    deleteGroupColumn(item) {
      this.$emit("deleteGroupColumn", item);
    },
    deleteColumn(index) {
      this.$emit("deleteColumn", index);
    },
    clickColOperation(data) {
      this.$emit("clickColOperation", data);
    },
    // 统计
    statisticsCurs(data) {
      this.$emit("statisticsCurs", data);
    },
    // 表格列的拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(
        ".draggable .el-table__header-wrapper tr"
      );
      if (!wrapperTr) return;
    },
    // 字段列表拖拽字段至报表列
    handleTargetDrop(e, index) {
      let data = e.dataTransfer;
      if (!this.isJsonString(data.getData("text/plain"))) return;
      let content = JSON.parse(data.getData("text/plain"));
      let newList = JSON.parse(JSON.stringify(this.listCheckedFields));
      newList.splice(index + 1, 0, content);
      this.dragRowColumnReportColumns(newList);
      e.preventDefault();
      // 通常不需要阻止冒泡，但是当出现容器嵌套时最好这么做
      // 它可以防止节点被添加到数组中两次
      e.stopPropagation();
    },
    // 拖拽事件传回index
    dragRowColumnReportColumns(newList) {
      let e = {
        chooseRowFiledsData: this.chooseRowFileds,
        choosedColumnFiledsData: this.choosedColumnFileds,
        chooseReportColumnsData: newList,
      };
      this.$bus.$emit("dragRowColumnReportColumnsBus", e);
    },
    // 判断是否可以转换为json数据
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == "object") {
          return true;
        }
      } catch (e) {
        if( e ) {
          return false;
        }
      }
    },
    // 设置详细信息表置顶
    setDetailToTop() {
      this.$emit("setDetailToTop");
    },
  },
};
</script>

<style lang='scss' scoped>
.detail_table {
  height: calc(50% - 20px);
  padding: 10px;
  border-top: 1px solid #d9dbdd;
  color: #222222;
  .detail_top_title {
    height: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .div_left {
      width: calc(100% - 50px);
      display: flex;
      align-items: center;
      color: #6b6d70;
      .detail_info {
        color: #080707;
      }
      span {
        font-size: 14px;
      }
      .title_info {
        margin-left: 20px;
        width: calc(100% - 160px);
      }
      .choose_infos {
        width: calc(100% - 140px);
        margin-left: 10px;
        display: flex;
        .each_info {
          margin-right: 10px;
          padding: 0 10px;
          height: 30px;
          background: #fff;
          border: 1px solid #e9eaec;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          img {
            width: 10px;
            height: 10px;
            margin-left: 10px;
            cursor: pointer;
          }
          div:first-child {
            color: #6b6d70;
          }
          div:last-child {
            color: #080707;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .remove_style {
          width: 40px;
          color: var(--lwc-brandAccessible, rgb(0, 112, 210));
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
.detailToTopClass {
  height: 100% !important;
}
</style>