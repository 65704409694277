<!--
  编辑页保存报表
-->
<template>
  <el-dialog
    :visible="saveReportTo"
    :title="$t('vue_label_report_savereport')"
    :before-close="saveReportCancel"
    :close-on-click-modal="false"
    width="520px"
  >
    <div class="center_center">
      <el-form ref="form" :model="form" label-width="">
        <el-form-item
          :label="$t('label.nameofreport')"
          prop="reportLabel"
          :rules="[
            {
              required: true,
              message: this.$i18n.t('vue_label_report_notice_nameisrequired'),
              trigger: ['blur'],
            },
          ]"
        >
          <el-input v-model="form.reportLabel"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.remarkofreport')">
          <el-input
            type="textarea"
            :rows="4"
            placeholder
            v-model="form.reportNote"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('vue_label_report_savelocation')">
          <el-select
            v-model="form.location"
            :placeholder="$t('vue_label_report_selectfolder')"
          >
            <el-option
              v-for="(item, index) in allFolderDatas"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="saveReportCancel" :loading="savaStatus">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button
        type="primary"
        @click="saveReportConfirm('form')"
        :loading="savaStatus"
        >{{ $t("label.ems.confirm") }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { getFolder } from "./api";

export default {
  props: {
    saveReportTo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      savaStatus: false, //点击保存时的状态
      form: {
        reportLabel: "",
        reportNote: "",
        location: "", //默认选中的保存位置（不用管，默认）
      },
      allFolders: [],
      allFolderDatas: [],
      loadings: false,
    };
  },
  mounted() {},
  methods: {
    // 获取报表文件夹
    async getFolders() {
      var params = {
        searchKeyWord: "",
      };
      let dataObject = await getFolder(params);
      let data = dataObject.data;
      this.allFolders = [];
      data.topFolderList.map((item) => {
        this.allFolders.push(item);
      });
      data.noTopFolderList.map((item) => {
        this.allFolders.push(item);
      });
      // 如果是最近查看，所有报表，我创建的报表，默认保存在我的个人自定义报表
      // 如果有文件夹id就保存在所在文件夹
      if (
        this.$store.getters.getreportChooseFolder == "recentReport" ||
        this.$store.getters.getreportChooseFolder == "thereporticreated" ||
        this.$store.getters.getreportChooseFolder == "allreports"
      ) {
        this.form.location = this.allFolders.find(
          (item) => item.name === this.$i18n.t("message.myselfreport")
        ).id;
      }else if(this.$store.getters.getreportChooseFolder){
        this.form.location = this.allFolders.find(
          (item) => item.id === this.$store.getters.getreportChooseFolder
        ).id;
      }
      // 对所有可选择的文件夹做权限：可编辑或者是不可编辑但是是我的个人自定义或未归档
      this.allFolderDatas = this.allFolders.filter(
        (item) =>
          item.isedit === "true" ||
          (item.isedit === "false" &&
            (item.name === this.$i18n.t("message.myselfreport") ||
              item.name === this.$i18n.t("label.notfiledpublicreport")))
      );
    },
    saveReportCancel() {
      this.$emit("saveReportCancel");
    },
    // 点击保存
    saveReportConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.savaStatus = true;
          this.$emit("saveReportConfirm", this.form);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/bouncedForm.scss";
</style>