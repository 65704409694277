<!--
  保存并关闭弹框
-->
<template>
  <el-dialog
    :visible="saveCloneTo"
    :title="$t('label.tabpage.closez')+$t('report.label.report')"
    :before-close="saveCloneClone"
    :close-on-click-modal="false"
    width="490px"
  >
    <div class="center_center">
      {{$t('vue_label_report_notice1')}}
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="saveCloneCancel">{{$t('vue_label_report_throw')}}</el-button>
      <el-button type="primary" @click="saveAndClose">{{$t('vue_label_report_saveandclose')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    saveCloneTo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    //关闭弹框
    saveCloneClone() {
      this.$emit("saveCloneClone");
    },
    // 点击丢弃
    saveCloneCancel() {
      this.$emit("saveCloneCancel");
      this.$router.push({
        path: "/reportObject/reportList",
      });
    },
    // 保存并关闭
    saveAndClose() {
      this.$emit("saveAndClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.saveClone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.4);
  min-height: 500px;
  overflow: auto;
  .saveClone_center {
    width: 540px;
    background: white;
    position: relative;
    margin: 5% auto; /*水平居中*/
    top: 156px; /*偏移*/
    transform: translateY(-50% -50%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    .center_header {
      padding: 17px 20px 15px 20px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dcdcdc;
      .header_left {
        color: #080707;
      }
      .header_right {
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
    .center_center {
      border-radius: 4px;
      color: #666666;
      div {
        text-align: left;
        padding: 12px 20px 50px 20px;
        font-size: 13px;
      }
    }
    .center_footer {
      height: 60px;
      padding: 0 20px;
      border-top: 1px solid #dcdcdc;
      background: #f4f6f9;
      border-radius: 0 0 8px 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
      }
      div:first-child {
        color: #5780b3;
        border: 1px solid #dcdcdc;
        background: #fff;
        margin-right: 10px;
      }
      div:last-child {
        color: #fff;
        background: #1b5297;
      }
    }
  }
}
</style>