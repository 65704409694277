/* 
  概要
  前端控制查找多选字段不能作为组行，组列：包括3方面：
    1.组行、组列下拉框不显示查找多选字段；
    2.行分组、列分组已选字段位置不能被拖拽有查找多选字段；
    3.汇总式报表的组行里不能被拖拽有查找多选字段；
*/
<template>
  <div class="bottom_profile">
    <!-- 分组：组行、组列：查找多选字段不能进行行列分组，统计出来的数据不对 -->
    <div class="group_way">
      <div class="top_title">
        {{ $t("label.report.select.group.mode") }}
      </div>
      <!-- 组行 -->
      <div class="summary_row_column">
        <div class="grouprows">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-grouprows1"></use>
          </svg>
          <div class="title_word">
            {{ $t("vue_label_report_grouprow") }}
          </div>
        </div>
        <el-select
          v-model="form.rowFiled"
          filterable
          clearable
          size="small"
          :placeholder="$t('label.searchfield')"
          :popper-append-to-body="true"
          @change="changeRowData"
          style="max-width: 300px; padding-left: 0"
          v-if="
            (chooseRowFileds.length < 3 && choosedColumnFileds.length < 1) ||
            (choosedColumnFileds.length < 3 && chooseRowFileds.length < 2)
          "
        >
          <el-option-group
            v-for="(group, index) in allSelectFileds"
            :key="index"
            :label="group.label_name"
          >
            <el-option
              v-for="(item, index) in group.fieldList"
              :key="index"
              :label="item.label_name"
              :value="item.id"
              :disabled="groupIds.indexOf(item.id) > -1"
              v-show="!isLookMultiple(item.schemefieldType)"
            ></el-option>
          </el-option-group>
        </el-select>
        <!-- 已选择的行分组 -->
        <div class="all_fileds">
          <draggable
            v-model="form.chooseRowFiledsData"
            @update="dragRowColumnReportColumns"
            @add="(evt) => dragRowColumnReportColumns(evt, 'groupRow')"
            v-bind="dragOptions"
          >
            <transition-group tag="div">
              <div
                class="each_column"
                :class="{ drap_row_group: isDrap('groupRow', index) }"
                v-for="(item, index) in chooseRowFileds"
                :key="index"
                @drop="(e) => handleTargetDrop(e, 'groupRow', index)"
                @dragover="(e) => handleTargetDragover(e, 'groupRow', index)"
                @dragleave="dragIndex = ''"
              >
                <div class="column_div">
                  <div class="left_label">
                    {{ item.label_name || item.labelname }}
                  </div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('component_setup_tabs_label_delete')"
                    placement="right"
                  >
                    <div
                      class="right_img"
                      @click="deleteRowData(index, item.id)"
                    >
                      <i class="el-icon-close"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <!-- 设置可拖拽的默认div位置 -->
        <div
          v-show="isAddRowGroup"
          class="drag_div"
          :class="{ drag_div_group: isDrap('groupRow', 'div') }"
          @drop="
            (e) => handleTargetDrop(e, 'groupRow', chooseRowFileds.length - 1)
          "
          @dragover="(e) => handleTargetDragover(e, 'groupRow', 'div')"
          @dragleave="dragIndex = ''"
        ></div>
      </div>
      <!-- 组列 -->
      <div class="summary_row_column" v-show="chooseRowFileds.length > 0">
        <div class="grouprows">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-groupcolumn1"></use>
          </svg>
          <div class="title_word">
            {{ $t("vue_label_report_groupcolumn") }}
          </div>
        </div>
        <!-- 组列选择框 -->
        <el-select
          v-model="form.columnFiled"
          filterable
          clearable
          size="small"
          :placeholder="$t('label.searchfield')"
          :popper-append-to-body="true"
          style="padding-left:0"
          @change="changeColData"
          v-if="choosedColumnFileds.length < 2 && chooseRowFileds.length < 3"
        >
          <el-option-group
            v-for="group in allSelectFileds"
            :key="group.id"
            :label="group.label_name"
          >
            <el-option
              v-for="(item, index) in group.fieldList"
              :key="index"
              :label="item.label_name"
              :value="item.id"
              :disabled="groupIds.indexOf(item.id) > -1"
              v-show="!isLookMultiple(item.schemefieldType)"
            ></el-option>
          </el-option-group>
        </el-select>
        <!-- 已选择的列分组 -->
        <div class="all_fileds">
          <draggable
            v-model="form.choosedColumnFiledsData"
            @update="dragRowColumnReportColumns"
            @add="(evt) => dragRowColumnReportColumns(evt, 'groupColumn')"
            v-bind="dragOptions"
          >
            <transition-group tag="div">
              <div
                class="each_column"
                :class="{ drap_row_group: isDrap('groupColumn', index) }"
                v-for="(item, index) in choosedColumnFileds"
                :key="index"
                @drop="(e) => handleTargetDrop(e, 'groupColumn', index)"
                @dragover="(e) => handleTargetDragover(e, 'groupColumn', index)"
                @dragleave="dragIndex = ''"
              >
                <div class="column_div">
                  <div class="left_label">
                    {{ item.label_name || item.labelname }}
                  </div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('component_setup_tabs_label_delete')"
                    placement="right"
                  >
                    <div
                      class="right_img"
                      @click="deleteColData(index, item.id)"
                    >
                      <i class="el-icon-close"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <!-- 设置可拖拽的默认div位置 -->
        <div
          v-show="isAddColumnGroup"
          class="drag_div"
          :class="{ drag_div_group: isDrap('groupColumn', 'div') }"
          @drop="
            (e) =>
              handleTargetDrop(e, 'groupColumn', choosedColumnFileds.length - 1)
          "
          @dragover="(e) => handleTargetDragover(e, 'groupColumn', 'div')"
          @dragleave="dragIndex = ''"
        ></div>
      </div>
    </div>
    <!-- 报表列，禁选字段：行列分组字段及已选择的报表列字段 -->
    <div class="report_column">
      <div class="title_filter">{{ $t("label.selectreportline") }}</div>
      <el-select
        v-model="form.reportColumn"
        filterable
        clearable
        size="small"
        :placeholder="$t('label.searchfield')"
        :popper-append-to-body="true"
        style="padding-left:0"
        @change="changeReportColData"
      >
        <el-option-group
          v-for="group in allSelectFileds"
          :key="group.id"
          :label="group.label_name"
        >
          <el-option
            v-for="(item, index) in group.fieldList"
            :key="index"
            :label="item.label_name"
            :value="item.id"
            :disabled="
              groupIds.indexOf(item.id) > -1 ||
              listCheckedFields.some((items) => items.id === item.id)
            "
          ></el-option>
        </el-option-group>
      </el-select>
      <!-- 已选择的报表列，不显示记录数及行列分组字段 -->
      <div class="all_choose_column">
        <draggable
          v-model="form.chooseReportColumnsData"
          @update="dragRowColumnReportColumns"
          @add="dragRowColumnReportColumns"
          v-bind="dragOptions"
        >
          <transition-group tag="div">
            <div
              class="each_column"
              :class="[
                { number_column: judgeNumber(item) },
                { drap_column: isDrap('column', index) },
              ]"
              v-for="(item, index) in listCheckedFields"
              :key="`${index}-${item.labelName}`"
              v-show="
                item.schemefieldName != 'totalrecord' &&
                groupIds.indexOf(item.id) === -1
              "
              @drop="(e) => handleTargetDrop(e, 'column', index)"
              @dragover="(e) => handleTargetDragover(e, 'column', index)"
              @dragleave="dragIndex = ''"
            >
              <!-- 数值类型，可回显弹框 -->
              <el-popover
                ref="popoverNumber"
                placement="right"
                width="400"
                trigger="click"
                v-if="judgeNumber(item)"
                popper-class="popover_number"
              >
                <!-- 数值类型的字段回显统计类型 -->
                <numericalStatistical
                  :statisticsDatas="statisticsDatas"
                  :indexData="indexData"
                  useLocation="report"
                  @applySummary="statisticsCurs"
                  @cancelSummary="cancelSummary"
                />
                <!-- 报表列字段div，作为整体通过点击显示气泡 -->
                <div
                  slot="reference"
                  class="column_div"
                  @click="clickImg(item)"
                >
                  <div class="left_label">
                    <span v-show="judgeNumber(item)" class="span_class">#</span>
                    {{ item.labelName || item.labelname }}
                  </div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('component_setup_tabs_label_delete')"
                    placement="right"
                  >
                    <div
                      class="right_img"
                      @click="deleteReportColData(item.id, index)"
                    >
                      <i class="el-icon-close"></i>
                    </div>
                  </el-tooltip>
                </div>
              </el-popover>
              <!-- 非数值类型，不能回显弹框 -->
              <div class="column_div" v-else>
                <div class="left_label">
                  {{ item.labelName || item.labelname }}
                </div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('component_setup_tabs_label_delete')"
                  placement="right"
                >
                  <div
                    class="right_img"
                    @click="deleteReportColData(item.id, index)"
                  >
                    <i class="el-icon-close"></i>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </transition-group>
        </draggable>
        <!-- 行级公式和汇总公式 -->
        <div
          class="rowlevel_summary_formula"
          v-show="expressionLine.length > 0 || expressionSummary.length > 0"
        >
          <!-- 行级公式 -->
          <div class="rowlevel_formula" v-if="expressionLine.length > 0">
            <div class="formula_title">
              {{ $t("vue_label_report_formulacolumn") }}
            </div>
            <div class="each_column formula_box">
              <div @click="clickLineFormula">
                fx&nbsp;&nbsp;&nbsp;{{ expressionLine[0].apiname }}
              </div>
              <svg class="icon" aria-hidden="true" @click="deleteLineFormula">
                <use href="#icon-chahao"></use>
              </svg>
            </div>
          </div>
          <!-- 汇总公式 -->
          <div class="rowlevel_formula" v-if="expressionSummary.length > 0">
            <div class="formula_title">
              {{ $t("vue_label_report_summaryformulacolumn") }}
            </div>
            <div
              class="each_column formula_box"
              v-for="(item, index) in expressionSummary"
              :key="index"
            >
              <div @click="clickSummaryFormula(item, index)">
                fx&nbsp;&nbsp;&nbsp;{{ item.apiname }}
              </div>
              <svg
                class="icon"
                aria-hidden="true"
                @click="deleteSummaryFormula(item)"
              >
                <use href="#icon-chahao"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numericalStatistical from "./numericalStatistical"; // 报表编辑页回显数值的统计类型

import draggable from "vuedraggable";
export default {
  components: {
    numericalStatistical,
    draggable,
  },
  props: {
    chooseRowFileds: {
      // 选择的行分组字段
      type: Array,
      default: () => [],
    },
    choosedColumnFileds: {
      // 选择的行分组字段
      type: Array,
      default: () => [],
    },
    allSelectFileds: {
      // 所有下拉框中可选择的字段
      type: Array,
      default: () => [],
    },
    groupIds: {
      // 行列分组字段集合
      type: Array,
      default: () => [],
    },
    listCheckedFields: {
      // 选择的报表列集合
      type: Array,
      default: () => [],
    },
    judgeNumber: {
      // 判断是否是数值类型
      type: Function,
      default: () => {},
    },
    formulaCur: {
      type: String,
      default: "",
    },
    expressionSummary: {
      type: Array,
      default: () => [],
    },
    expressionLine: {
      type: Array,
      default: () => [],
    },
    statisticsDatas: {
      type: Object,
      default: () => {},
    },
    indexData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        rowFiled: "", // 选择的组行字段
        columnFiled: "", // 选择的组列字段
        reportColumn: "", // 选择的报表列字段
        chooseRowFiledsData: [], // 组件中定义的选择的组行数据，绑定拖拽的数据
        choosedColumnFiledsData: [], // 组件中定义的选择的组列数据，绑定拖拽的数据
        chooseReportColumnsData: [], // 组件中定义的选择的报表列数据，绑定拖拽的数据
      },
      dragIndex: "", // 报表列中拖拽时的字段index，用于添加拖拽移动时的样式
      nowDrag: "", //正在操作报表列，组行，还是祖列进行拖拽
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: "description",
        ghostClass: "ghost",
        chosenClass: "chosen",
      };
    },
    // 是否属于查找多选字段
    isLookMultiple() {
      return (fieldType) => {
        if (fieldType === "MR") {
          return true;
        }
        return false;
      };
    },
    // 判断拖动时的dragIndex中是否包含对应的类型type且包含下标index
    isDrap() {
      return (type, index) => {
        if (
          this.dragIndex.indexOf(type) > -1 &&
          this.dragIndex.indexOf(index) > -1
        ) {
          return true;
        }
        return false;
      };
    },
    /* 行分组是否还可以进行添加：
        1.行分组<2;
        2.行分组=2 && 列分组=0；
        新增 3.行分组=2&& 列分组=1且是从列像行拖动
    */
    isAddRowGroup() {
      if (
        this.chooseRowFileds.length < 2 ||
        (this.chooseRowFileds.length === 2 &&
          this.choosedColumnFileds.length === 0) ||
        (this.chooseRowFileds.length === 2 &&
          this.choosedColumnFileds.length === 1 &&
          this.nowDrag == "groupColumn")
      ) {
        return true;
      } else {
        return false;
      }
    },
    /* 列分组是否还可以进行添加：以下情况不可进行添加
        修改： 1.行分组=3；--> 1.行分组=3&&列=0&&向列拖动时,可以进行拖动
        2.列分组=2；
    */
    isAddColumnGroup() {
      if (
        this.chooseRowFileds.length === 3 &&
        this.choosedColumnFileds.length === 0 &&
        this.nowDrag == "groupRow"
      ) {
        return true;
      } else if (this.choosedColumnFileds.length === 2) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    // 监听接收的已选择的组行数据
    chooseRowFileds: {
      handler() {
        this.form.chooseRowFiledsData = this.chooseRowFileds; // 在拖拽时需要更改数据，所以不能直接使用props中的数据
      },
      immediate: true,
    },
    // 监听接收的已选择的组列数据
    choosedColumnFileds: {
      handler() {
        this.form.choosedColumnFiledsData = this.choosedColumnFileds; // 在拖拽时需要更改数据，所以不能直接使用props中的数据
      },
      immediate: true,
    },
    // 监听接收的已选择的报表列数据
    listCheckedFields: {
      handler() {
        this.form.chooseReportColumnsData = this.listCheckedFields; // 在拖拽时需要更改数据，所以不能直接使用props中的数据
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    changeRowData(value) {
      this.$emit("changeRowData", value);
    },
    changeColData(item) {
      this.$emit("changeColData", item);
    },
    changeReportColData(id) {
      this.$emit("changeReportColData", id);
    },
    // 统计
    statisticsCurs(data) {
      this.$emit("statisticsCurs", data);
    },
    cancelSummary() {
      this.$emit("cancelSummary");
    },
    clickImg(data) {
      this.$emit("clickImg", data);
    },
    // 删除已选行数据
    deleteRowData(index, id) {
      this.$emit("deleteRowData", index, id);
    },
    // 删除已选列数据
    deleteColData(index, id) {
      this.$emit("deleteColData", index, id);
    },
    // 点击行级汇总公式按钮
    hideFormula(index) {
      this.$emit("hideFormula", index);
    },
    // 删除已选报表列数据
    deleteReportColData(id, index) {
      this.$emit("deleteReportColData", id, index);
    },
    // 点击行级公式
    clickLineFormula() {
      this.$emit("clickLineFormula");
    },
    // 删除行级公式
    deleteLineFormula() {
      this.$emit("deleteLineFormula");
    },
    // 点击汇总公式
    clickSummaryFormula(item, index) {
      this.$emit("clickSummaryFormula", item, index);
    },
    // 删除汇总公式
    deleteSummaryFormula(data) {
      this.$emit("deleteSummaryFormula", data);
    },
    // 组行、组列、报表列拖拽
    dragRowColumnReportColumns(evt, location) {
      /* 
        当从报表列向组行、组列中进行拖拽时，
          1.需要判断拖拽字段是否为查找多选，若是查找多选字段则不能被拖拽至组行、组列；
          2.需要判断组行组组列中是否还可以进行拖拽，若是不能再进行拖拽直接return；
      */
      if (
        evt &&
        evt.type === "add" &&
        (location === "groupRow" || location === "groupColumn")
      ) {
        let itemEl = this.listCheckedFields[evt.oldIndex]; // 要移除的元素信息
        if (
          this.isLookMultiple(itemEl.schemefieldType) ||
          (location === "groupRow" && !this.isAddRowGroup) ||
          (location === "groupColumn" && !this.isAddColumnGroup)
        ) {
          this.form.chooseRowFiledsData = JSON.parse(
            JSON.stringify(this.chooseRowFileds)
          );
          this.form.choosedColumnFiledsData = JSON.parse(
            JSON.stringify(this.choosedColumnFileds)
          );
          this.form.chooseReportColumnsData = JSON.parse(
            JSON.stringify(this.listCheckedFields)
          );
          return;
        }
      }
      let e = {
        chooseRowFiledsData: this.form.chooseRowFiledsData,
        choosedColumnFiledsData: this.form.choosedColumnFiledsData,
        chooseReportColumnsData: this.form.chooseReportColumnsData,
      };
      this.$emit("dragReportColumnsBus", e);
    },
    // 从字段列表中拖拽字段成功事件
    handleTargetDrop(e, type, index) {
      this.nowDrag = type;
      this.dragIndex = "";
      let data = e.dataTransfer;
      if (!this.isJsonString(data.getData("text/plain"))) return;
      let content = JSON.parse(data.getData("text/plain"));
      switch (type) {
        case "groupRow": // 组行，若是拖拽字段已存在于分组中或拖拽的是查找多选字段，则直接return
          if (
            this.groupIds.indexOf(content.id) > -1 ||
            this.isLookMultiple(content.schemefieldType)
          )
            return;
          this.form.chooseRowFiledsData.splice(index + 1, 0, content);
          break;
        case "groupColumn": // 组列，若是拖拽字段已存在于分组中，则直接return
          if (
            this.groupIds.indexOf(content.id) > -1 ||
            this.isLookMultiple(content.schemefieldType)
          )
            return;
          this.form.choosedColumnFiledsData.splice(index + 1, 0, content);
          break;
        case "column": // 报表列
          this.form.chooseReportColumnsData.splice(index + 1, 0, content);
          break;
      }
      this.dragRowColumnReportColumns();
      e.preventDefault();
      // 通常不需要阻止冒泡，但是当出现容器嵌套时最好这么做
      // 它可以防止节点被添加到数组中两次
      e.stopPropagation();
    },
    // 从字段列表中拖拽字段移动时的事件
    handleTargetDragover(e, type, index) {
      // 判断若是行列分组不可再进行添加时直接return
      if (
        (type === "groupRow" && !this.isAddRowGroup) ||
        (type === "groupColumn" && !this.isAddColumnGroup)
      ) {
        return;
      }
      e.preventDefault();
      this.dragIndex = `${type}${index}`;
    },
    // 判断是否可以转换为json数据
    isJsonString(str) {
        try {
          if (typeof JSON.parse(str) == "object") {
            return true;
          }
        } catch (e) {
          if( e ) {
            return false;
          }
        }
    },
  },
};
</script>

<style lang='scss' scoped>
.bottom_profile {
  height: calc(100% - 39px);
  overflow-y: auto;
  border-top: 1px solid #dcdcdc;
  .group_way {
    // padding: 10px 15px 18px 12px;
    padding: 10px 16px;
    text-align: left;
    border-bottom: 1px solid #dcdcdc;
    .top_title {
      color: #6b6d70;
      font-size: 13px;
    }
    .summary_row_column {
      .grouprows {
        display: flex;
        img,
        svg {
          width: 16px;
          height: 14px;
          margin: 16px 4px 0 0;
        }
      }
      .title_word {
        color: #080707;
        margin: 15px 0 10px 0;
      }

      .all_fileds {
        // min-height: 30px;
        .each_field {
          margin-top: 10px;
          // width: 262px;
          width: 100%;
          height: 30px;
          padding: 0 10px;
          background: #f9f9fa;
          border: 1px solid #dcdcdc;
          border-radius: 4px;
          color: #17315c;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:hover {
            background: #ebe9ec !important;
          }
          img {
            width: 10px;
            height: 10px;
            cursor: pointer;
          }
        }
      }
      .drag_div {
        margin-top: 6px;
        height: 26px;
      }
      .drag_div_group {
        background-color: #eef7fc;
        border: 1px dashed #7088da;
        border-radius: 3px;
      }
    }
    .summary_row_column:first-child {
      margin-bottom: 15px;
    }
    .summary_row_column:last-child {
      margin-bottom: 30px;
    }
  }
  .report_column {
    padding: 10px 16px;
    text-align: left;
    .title_filter {
      margin: 10px 0 13px 0;
      color: #6b6d70;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .each_column {
    margin-top: 6px;
    width: 100%;
    // height: 30px;
    min-height: 26px;
    background: #f9f9fa;
    border: 1px solid #e9eaec;
    border-radius: 4px;
    color: #16325c;
    .column_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 26px;
      .left_label {
        margin-left: 10px;
        .span_class {
          color: #787774;
          font-weight: 500;
          font-size: 13px;
        }
      }
      .right_img {
        padding-right: 10px;
        display: flex;
        align-items: center;
        color: #080707;
        font-size: 13px;
        cursor: pointer;
        i {
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
    &:hover {
      background: #f1f1f3 !important;
      cursor: move;
    }
  }
  .number_column {
    background: #eef7fc;
    cursor: pointer;
    &:hover {
      background: #e2f1f9 !important;
    }
  }
  .drap_column {
    position: relative;
    margin-bottom: 15px;
    &:after {
      width: 100%;
      height: 3px;
      background-color: #1b5297;
      border-radius: 3px;
      content: "";
      position: absolute;
      top: 32px;
    }
  }
  .drap_row_group {
    position: relative;
    margin-bottom: 38px;
    &:after {
      width: 100%;
      height: 26px;
      background-color: #eef7fc;
      border: 1px dashed #7088da;
      border-radius: 3px;
      content: "";
      position: absolute;
      top: 32px;
    }
  }
  .rowlevel_summary_formula {
    margin-top: 30px;
    .rowlevel_formula {
      margin-bottom: 20px;
      .formula_title {
        color: #545454;
      }
      .formula_box {
        background: #e2f1f9;
        div {
          width: 90%;
        }
      }
    }
  }
}
::v-deep .el-select {
  width: 100%;
}
::v-deep .el-input__inner {
  width: 100%;
}

::v-deep .el-select-group__title {
  max-width: 200px !important;
}
::v-deep .el-select-dropdown__item {
  max-width: 200px !important;
}
</style>