/*
 * @Author       : liyb
 * @Date         : 2021-09-13 13:02:07
 * @LastEditors  : liyb
 * @LastEditTime : 2021-09-22 14:47:51
 * @Description  : file content
 * @FilePath     : \lightning-web\src\config\enumCode\filedType.js
 */
// 字段筛选id，图片，类型匹配
import i18n from '@/utils/i18n'; //多语言

// 文本字段类型：剩余字段，先设置为空
const textFiledTypes = []
// 数字字段类型：累计汇总、百分比、评分、币种、数字
const numberFiledTypes = ['C', 'P', 'SCORE', 'c', 'N']
// 复选框字段类型：复选框
const checkboxFiledTypes = ['B']
// 日期字段类型：日期、日期/时间
const dateFiledTypes = ['D', 'F']
// 选项列表字段类型：选项列表、选项列表多选
const optionslistFiledTypes = ['L', 'Q']

var allTypeImgs = [
  {
    // 显示文本字段
    id: "text",
    img: 'icon-A',
    img_ed: 'icon-A_white',
    tooltipContent: "vue_label_display_text_field",
    types: textFiledTypes
  },
  {
    // 显示数字字段
    id: "number",
    img: 'icon-hash',
    img_ed: 'icon-hash_white',
    tooltipContent: "vue_label_display_number_field",
    types: numberFiledTypes,
  },
  {
    // 显示复选框字段
    id: "checkbox",
    img: 'icon-checkbox',
    img_ed: 'icon-checkbox_white',
    tooltipContent: "vue_label_display_checkbox_field",
    types: checkboxFiledTypes,
  },
  {
    // 显示日期字段
    id: "date",
    img: 'icon-date',
    img_ed: 'icon-date_white',
    tooltipContent: "vue_label_display_date_field",
    types: dateFiledTypes,
  },
  {
    // 显示选项列表字段
    id: "optionslist",
    img: 'icon-optionslist',
    img_ed: 'icon-optionslist_white',
    tooltipContent: "vue_label_display_options_field",
    types: optionslistFiledTypes,
  },
]

export default allTypeImgs