import axios from '@/config/httpConfig'

// 获取报表文件夹
export function getFolder(data) {
    return axios.post('/report/getReportFolders', data)
}
// 复制报表
export function saveCopyReport(data) {
    return axios.post('/report/saveCopyReport', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}