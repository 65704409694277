<!-- 筛选器 -->
<template>
  <div class="bottom_filter">
    <!-- 标准过滤器 -->
    <div class="standard_filter">
      <div class="filter_title">
        {{ $t("vue_label_report_standardfilter") }}
      </div>
      <!-- 角色 -->
      <div class="filter_roles">
        <div class="role_title">{{ $t("label_tabpage_rolez") }}</div>
        <div class="role_infos" v-show="allRoles.length > 0">
          <!-- 当前角色 -->
          <div
            :class="{ current_role: middleRoleTo }"
            @click="getRoleTreeAndDurationList"
          >
            {{ currentRole.rolename }}&nbsp;>
          </div>
          <!-- 中间角色 -->
          <div class="rolw_style" v-if="middleRoleTo">
            {{ middleRole.rolename }}
          </div>
          <div v-show="middleRoleTo && subordinateRoles.length > 0">></div>
          <!-- 获取当前角色所有下级 -->
          <div
            class="each_role"
            v-for="(item, index) in subordinateRoles"
            :key="index"
            @click="getNextRoles(item)"
          >
            <div class="rolw_style">{{ item.rolename }}</div>
            <div v-show="index < subordinateRoles.length - 1" class="rolesFen">
              |
            </div>
          </div>
        </div>
      </div>
      <!-- 向我显示 -->
      <div class="filter_show_me">
        <div class="show_me_title">
          {{ $t("vue_label_report_showme") }}
        </div>
        <el-select
          v-model="form.person"
          filterable
          :placeholder="$t('label_tabpage_myz')"
          suffix-icon="el-icon-search"
          size="small"
          style="padding-left: 0"
          @change="changePerson"
        >
          <el-option
            v-for="(item, index) in scopeData"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <!-- 时间范围 -->
      <div class="filter_time_range">
        <div class="time_range_title">
          {{ $t("label.mobile.phone.time.range") }}
        </div>
        <el-select
          v-model="form.timeRange"
          filterable
          :placeholder="$t('label.mobile.phone.time.range')"
          suffix-icon="el-icon-search"
          size="small"
          @change="changeTimeRange"
          v-show="JSON.stringify(choosedTimeRanges) === '{}'"
        >
          <el-option
            v-for="(item, index) in timeRanges"
            :key="index"
            :label="item.label_obj"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="each_field">
          <!-- 已选择时间范围字段 -->
          <div
            class="each_field_center"
            v-show="JSON.stringify(choosedTimeRanges) !== '{}'"
          >
            <div class="field_div" v-popover:timeRangePopver>
              <div class="field_div_center">
                <div>{{ choosedTimeRanges.label_obj }}</div>
                <div class="choose_condition">
                  <!-- 多语言"至" -->
                  {{ form.durationListLabel }} ({{ form.startTime
                  }}{{ $t("label.appointment.wizard.subtitle2-2")
                  }}{{ form.endTime }})
                </div>
              </div>
            </div>
            <div class="right_img" @click="deleteTimeRange">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 筛选条件 -->
    <div class="filter_condition">
      <!-- 高级筛选条件按钮 -->
      <div class="condition_title">
        <div>{{ $t("vue_label_report_filtercondition") }}</div>
        <el-tooltip
          popper-class="my-tooltip"
          class="item"
          effect="dark"
          :content="$t('advancedconditions')"
          placement="bottom"
        >
          <advancedScreening
            imgSrc="add_ac"
            :removeLogic="formula"
            :formCalculate="formCalculate"
            @advancedScreeningApply="advancedScreeningApply"
            @clickRemoveLogic="formulaCancel"
            @showSeniorPopoper="showSeniorPopoper"
            @hideSeniorPopoper="hideSeniorPopoper"
            ref="advancedScreeningRef"
            :screeningTo="screeningTo"
        /></el-tooltip>
      </div>
      <!-- 筛选器最多10个信息提示 -->
      <div class="number_info">
        <i class="el-icon-info"></i>
        <span>{{ $t("vue_label_filters_number") }}</span>
      </div>
      <el-select
        v-model="form.filterFiled"
        filterable
        :placeholder="$t('vue_label_fliter_addfliter')"
        size="small"
        :popper-append-to-body="false"
        style="padding-left: 0"
        @change="changeFilterFiled"
        v-show="choosedFilterFileds.length < 15"
      >
        <el-option-group
          v-for="group in allSelectFileds"
          :key="group.id"
          :label="group.label_name"
        >
          <el-option
            v-for="(item, index) in group.fieldList"
            :key="index"
            :label="item.label_name"
            :value="item.id"
          ></el-option>
        </el-option-group>
      </el-select>
      <!-- 公式 -->
      <div class="formula_div" v-show="formula">
        <div>{{ formCalculate }}</div>
        <div class="edit-imgs">
          <advancedScreening
            imgSrc="edit_write"
            :removeLogic="formula"
            :formCalculate="formCalculate"
            :screeningTo="screeningTo"
            @advancedScreeningApply="advancedScreeningApply"
            @clickRemoveLogic="formulaCancel"
            @showSeniorPopoper="showSeniorPopoper"
            @hideSeniorPopoper="hideSeniorPopoper"
            ref="advancedScreeningRef"
          />
          <div @click="formulaCancel">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <!-- 选择的过滤项 -->
      <div class="each_field_div" @drop="handleTargetDrop" @dragover.prevent>
        <div
          class="each_field"
          v-for="(item, index) in choosedFilterFileds"
          :key="index"
        >
          <!-- 已选择的过滤条件字段 -->
          <div
            class="each_field_center"
            @click="clickCondition(index)"
            v-show="true"
          >
            <el-popover
              width="516"
              ref="filterPopver"
              placement="right"
              trigger="click"
              popper-class="filter_frame"
              @hide="hideFilterPopver(item, index)"
            >
              <div class="field_div" slot="reference">
                <!-- 高级筛选时的序号，当高级筛选条件气泡打开时显示 -->
                <div class="index_style" v-show="formula || screeningTo">
                  {{ index + 1 }}
                </div>
                <div
                  class="field_div_center"
                  :class="{ field_div_center_width: formula || screeningTo }"
                >
                  <!-- 字段名称及选择的筛选条件 -->
                  <div>{{ item.labelname || item.label_name }}</div>
                  <div class="choose_condition" v-if="item.operator">
                    <div>
                      {{
                        item.screeningOperation.find(
                          (item1) => item1.id === item.operator
                        ).name
                      }}
                    </div>
                    <div>{{ item.value.value }}</div>
                  </div>
                </div>
              </div>
              <!-- 过滤条件弹框 -->
              <div class="condition_details">
                <div class="condition_center">
                  <div class="top_forms">
                    <div class="center_top">
                      <div>
                        {{
                          $t("vue_label_report_filterby", {
                            screenCondition: screenCondition,
                          })
                        }}
                      </div>
                      <svg
                        class="icon"
                        aria-hidden="true"
                        @click="$refs.filterPopver[index].doClose()"
                      >
                        <use href="#icon-chahao"></use>
                      </svg>
                    </div>
                    <el-form
                      :inline="true"
                      :model="form"
                      class="demo-form-inline"
                    >
                      <el-form-item :label="$t('label_partner_all_operate')">
                        <el-select
                          v-model="item.operator"
                          filterable
                          :placeholder="
                            '--' + $t('label.data.newtemplate.null') + '--'
                          "
                          size="small"
                        >
                          <el-option
                            v-for="(item1, index1) in item.screeningOperation"
                            :key="index1"
                            :label="item1.name"
                            :value="item1.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('label.campaign.channel.value')">
                        <div class="val">
                          <!-- 不支持查找多选字段进行筛选 -->
                          <EditableCell
                            :input-type="item.inputType=='remote-multi-select'?'multi-select':item.inputType"
                            :value="
                              valueTypeConversion(item.inputType, item.value)
                            "
                            typees="filter"
                            ref="EditableCell"
                            :options="selecArr"
                            @selectes="
                              item.fieldid === '' ? '' : selectes(item)
                            "
                          ></EditableCell>
                        </div>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="bottom_butts">
                    <el-button
                      size="mini"
                      @click="$refs.filterPopver[index].doClose()"
                    >
                      {{ $t("button_partner_cancel") }}
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="applyCondition(item, index)"
                      >{{ $t("label.apply") }}</el-button
                    >
                  </div>
                </div>
              </div>
            </el-popover>
            <el-button type="text" @click="deleteFilterFiled(index)">
              <svg
                class="icon deleted_img"
                v-if="imgFlag"
                @mouseover="changeDeleteMouse"
                aria-hidden="true"
              >
                <use href="#icon-delete"></use>
              </svg>
              <svg
                class="icon deleted_img"
                v-if="!imgFlag"
                @mouseleave="changeDeleteMouse"
                aria-hidden="true"
              >
                <use href="#icon-delete_blue"></use>
              </svg>
            </el-button>
          </div>
        </div>
      </div>
      <!-- 说明文字 -->
      <!-- <div class="condition_words">
        <div>●{{ $t("label_tabpage_fieldoptions") }}</div>
        <div>●{{ $t("label_tabpage_datefield") }}</div>
      </div> -->
    </div>

    <!-- 时间范围气泡 -->
    <el-popover
      ref="timeRangePopver"
      placement="right"
      width="516"
      trigger="click"
      popper-class="time_frame"
      @show="showTimeRangePopver"
      @hide="hideTimeRangePopver"
    >
      <div class="timeRange_details">
        <div class="detail_center">
          <div class="select_style">
            <span class="demonstration">{{
              $t("label.weixin.report.range")
            }}</span>
            <el-select
              v-model="form.durationList"
              filterable
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              :popper-append-to-body="false"
              size="small"
              @change="changeDemonstration"
            >
              <el-option-group
                v-for="(group, index) in durationLists"
                :key="index"
                :label="group.label"
              >
                <el-option
                  v-for="(item, index) in group.options"
                  :key="index"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="time_style">
            <div class="start_time">
              <span class="demonstration">{{ $t("label.starttime") }}</span>
              <el-date-picker
                v-model="form.startTime"
                type="date"
                size="small"
                value-format="yyyy-MM-dd"
                popper-class="picker-report-endtime-box"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
                :disabled="form.startTime != '' && form.durationList !== ''"
              ></el-date-picker>
            </div>
            <div class="start_time">
              <span class="demonstration">{{ $t("label.endtime") }}</span>
              <el-date-picker
                v-model="form.endTime"
                type="date"
                size="small"
                value-format="yyyy-MM-dd"
                popper-class="picker-report-endtime-box"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
                :disabled="form.endTime != '' && form.durationList !== ''"
              ></el-date-picker>
            </div>
          </div>
          <div class="bottom_butts">
            <el-button size="mini" @click="$refs.timeRangePopver.doClose()">
              {{ $t("button_partner_cancel") }}
            </el-button>
            <el-button type="primary" size="mini" @click="applyTimeRange()">{{
              $t("label.apply")
            }}</el-button>
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import advancedScreening from "./advancedScreening";
import EditableCell from "@/components/TablePanel/EditableCell";
import * as CommonObjApi from "./api";
import * as SetViewApi from "./api";
import { echoValueTypeConversion } from "@/config/filter/commaProcessing";

export default {
  components: {
    advancedScreening,
    EditableCell,
  },
  props: {
    middleRoleTo: {
      type: Boolean,
      default: false,
    },
    formula: {
      type: Boolean,
      default: false,
    },
    allRoles: {
      // 所有角色
      type: Array,
      default: () => [],
    },
    currentRole: {
      type: Object,
      default: () => {},
    },
    subordinateRoles: {
      type: Array,
      default: () => [],
    },
    choosedTimeRanges: {
      // 已选择的时间范围条件
      type: Object,
      default: () => {},
    },
    durationLists: {
      type: Array,
      default: () => [],
    },
    allSelectFileds: {
      type: Array,
      default: () => [],
    },
    formCalculate: {
      type: String,
      default: "",
    },
    middleRole: {
      // 中间角色
      type: Object,
      default: () => {},
    },
    choosedFilterFileds: {
      type: Array,
      default: () => [],
    },
    screenCondition: {
      type: String,
      default: "",
    },
    timeRanges: {
      type: Array,
      default: () => [],
    },
    // 选择的四级数据源
    allObjects: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        person:
          this.allObjects.objecta === "201603100108754gRole" ||
          this.allObjects.objectb === "201603100108754gRole" ||
          this.allObjects.objectc === "201603100108754gRole" ||
          this.allObjects.objectd === "201603100108754gRole"
            ? "all"
            : "user", // 向我显示// 四级数据源中是否包含联系人角色，包含的话person:all，禁选user
        timeRange: "", // 选择的时间范围字段
        durationList: "", // 持续时间
        durationListLabel: this.$i18n.t("label.cumstom"), // 持续时间对应的label
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        filterFiled: "", // 选择的过滤条件字段
      },
      screeningTo: false,
      selecArr: [], // 过滤条件选择字段的下拉内容
      imgFlag: true, //svg图片切换
      // 向我显示数据
      scopeData:[{
        label:this.$i18n.t("label_tabpage_myz"),
        value:"user"
      },{
        label:this.$i18n.t("label_tabpage_allz"),
        value:"all"
      }]
    };
  },
  created() {},
  computed: {
    // 四级数据源中是否包含联系人角色，包含的话person:all，禁选user
    isTaskEventFiled: function () {
      if (
        this.allObjects.objecta === "201603100108754gRole" ||
        this.allObjects.objectb === "201603100108754gRole" ||
        this.allObjects.objectc === "201603100108754gRole" ||
        this.allObjects.objectd === "201603100108754gRole"
      ) {
        return true;
      }else{
        return false
      }
    },
  },
  methods: {
    //svg鼠标进入和移除事件切换图标
    changeDeleteMouse() {
      this.imgFlag = !this.imgFlag;
    },
    // 选择的选项列表字段的选项列表值
    async selectes(val) {
      this.selecArr = [];
      if (val.type === "R") {
        this.getRecordTypes(val.prefix);
      } else if (val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH") {
        this.getCountrySelectValue();
      } else if (val.type == "ct") {
        this.conditionsValue(val);
      } else {
        if (val.fieldid === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldid,
        };
        let res = await SetViewApi.GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codevalue,
              value: item.codekey,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      }
    },
    // 获取记录类型字段的选项列表值
    getRecordTypes(prefix) {
      let arr = [];
      let params = {
        prefix: prefix,
      };
      CommonObjApi.getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            let obj = {
              label: item.name,
              value: item.name,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      });
    },
    // 获取国家或地区的选项列表值
    getCountrySelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selecArr = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取币种的选项列表值
    async conditionsValue() {
      let res = await SetViewApi.GetCurrencyInfo();
      res.data.forEach((item) => {
        let obj = {
          label: item.currencyname,
          value: item.currencycode,
        };
        this.selecArr.push(obj);
      });
    },
    getRoleTreeAndDurationList() {
      this.$emit("getRoleTreeAndDurationList");
    },
    changePerson(data) {
      this.$emit("changePerson", data);
    },
    changeTimeRange(data) {
      this.$emit("changeTimeRange", data);
    },
    deleteTimeRange() {
      this.$emit("deleteTimeRange");
    },
    changeFilterFiled(data) {
      this.$emit("changeFilterFiled", data);
    },
    clickCondition(data) {
      this.$emit("clickCondition", data);
    },
    hideFilterPopver(item, index) {
      this.$emit("hideFilterPopver", item, index);
    },
    // 过滤条件弹框：value值设置，判断是否为多选字段，是否需要转换类型
    valueTypeConversion(inputType, value) {
      if (inputType === "multi-select" && value.value) {
        // 多选字段，item.value需为数组类型，转换一下
        return { value: echoValueTypeConversion(value.value) };
      } else {
        return value;
      }
    },
    applyCondition(item, index) {
      // 对个案编号做长度限制，前端最大长度为1500，后端2500
      if(item.labelname==this.$i18n.t("front-console-sort-field") && item?.value?.value?.length>1500){
        this.$message.error(this.$i18n.t("c2193"));
      }else{
        this.$emit("applyCondition", item, index);
      } 
    },
    deleteFilterFiled(data) {
      this.$emit("deleteFilterFiled", data);
    },
    // 监听时间范围气泡的显示操作
    showTimeRangePopver() {
      // 若默认选择的持续时间为自定义，则设置label
      if (this.form.durationList === "") {
        this.form.durationListLabel = this.$i18n.t("label.cumstom");
      }
    },
    hideTimeRangePopver() {
      this.$emit("hideTimeRangePopver");
    },
    changeDemonstration(data) {
      this.$emit("changeDemonstration", data);
    },
    advancedScreeningApply(data) {
      this.$emit("advancedScreeningApply", data);
    },
    // 隐藏高级筛选气泡
    hideSeniorPopoper() {
      this.screeningTo = false;
    },
    // 显示高级筛选气泡
    showSeniorPopoper() {
      this.screeningTo = true;
    },
    formulaCancel() {
      this.$emit("formulaCancel");
    },
    // 应用时间范围字段弹框
    applyTimeRange() {
      this.$emit("applyTimeRange");
    },
    getNextRoles(data) {
      this.$emit("getNextRoles", data);
    },
    handleTargetDrop(e) {
      let data = e.dataTransfer;
      let content = JSON.parse(data.getData("text/plain"));
      this.changeFilterFiled(content.id);
      e.preventDefault();
      // 通常不需要阻止冒泡，但是当出现容器嵌套时最好这么做
      // 它可以防止节点被添加到数组中两次
      e.stopPropagation();
    },
  },
};
</script>

<style lang='scss' >
.picker-report-endtime-box {
  z-index: 120001 !important;
}
</style>
<style lang='scss' scoped>
// 下拉框样式
::v-deep .el-select-group__title {
  padding-left: 15px;
  color: #6b6d70;
  font-weight: 600;
}

.edit-imgs {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.bottom_filter {
  height: calc(100% - 39px);
  overflow-y: auto;
  border-top: 1px solid #dcdcdc;
  text-align: left;
  .standard_filter {
    padding: 14px 20px;
    border-bottom: 1px solid #dcdcdc;
    .filter_title {
      margin-bottom: 15px;
      color: #6b6d70;
      font-size: 13px;
    }
    .filter_roles {
      color: #080707;
      .role_title {
        margin: 15px 0 10px 0;
      }
      .role_infos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        .each_role {
          display: flex;
          color: #006dcc;
        }
        .rolw_style {
          margin: 0 3px;
        }
        .current_role {
          color: #006dcc;
        }
      }
    }
    .filter_show_me {
      .show_me_title {
        color: #080707;
        margin: 15px 0 10px 0;
      }
    }
    .filter_time_range {
      margin-bottom: 29px;
      .time_range_title {
        color: #080707;
        margin: 15px 0 10px 0;
      }
    }
  }
  .filter_condition {
    padding: 14px 20px;
    .condition_title {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      color: #6b6d70;
      font-size: 13px;
      position: relative;
      img {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
    .number_info {
      background-color: #f3f1f2;
      padding: 5px;
      border-radius: 4px;
      margin-bottom: 5px;
      i {
        margin-right: 5px;
      }
    }
    .formula_div {
      margin-top: 10px;
      padding: 5px 10px;
      // background: #dae5f9;
      // border: 1px solid #dcdcdc;
      background: #d9dbdd;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: #17315c;
      div:last-child {
        img {
          width: 10px;
          height: 10px;
          cursor: pointer;
        }
        img:first-child {
          margin-right: 6px;
        }
      }
    }
    .condition_words {
      margin-top: 25px;
      color: #080707;
      div:first-child {
        margin-bottom: 14px;
      }
    }
  }
  .each_field_div {
    min-height: 20px;
  }
  .each_field {
    margin-top: 10px;
    position: relative;
    color: #17315c;
    .each_field_center {
      width: 100%;
      padding-right: 10px;
      // background: #f1f1f3;
      // border: 1px solid #e9eaec;
      background: #fff;
      border: 1px solid #d9dbdd;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      span {
        width: calc(100% - 15px);
      }
      .field_div {
        width: 100%;
        display: flex;
        .index_style {
          width: 20px;
          background-color: #d9dbdd;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .field_div_center {
          width: 100%;
          padding: 5px 10px;
          .choose_condition {
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;
            color: #080707;
            div:first-child {
              margin-right: 5px;
            }
            div:last-child {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .field_div_center_width {
          width: calc(100% - 20px) !important;
        }
      }
      .right_img {
        color: rgba(22, 32, 54, 1);
        font-size: 13px;
        font-weight: bold;
      }
      .deleted_img {
        width: 13px;
        height: 13px;
      }
    }
  }

  ::v-deep .el-select {
    width: 100%;
  }
}
.timeRange_details {
  .detail_center {
    padding: 10px;
    .select_style {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      ::v-deep .el-select {
        width: 200px;
        margin-left: 8px;
      }
    }
    .time_style {
      margin: 20px 0;
      display: flex;
      .start_time {
        display: flex;
        align-items: center;
        ::v-deep .el-date-editor.el-input {
          width: 164px;
          margin-left: 8px;
        }
      }
      .start_time:first-child {
        margin-right: 13px;
      }
    }
    .bottom_butts {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.condition_details {
  .condition_center {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top_forms {
      .center_top {
        padding-bottom: 10px;
        font-size: 14px;
        color: #080707;
        border-bottom: 1px solid #dcdcdc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          width: 12px;
          height: 12px;
          cursor: pointer;
        }
      }
      ::v-deep .el-form {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .el-form-item {
          margin-right: -10px;
          margin-bottom: 15px !important;
        }
        .el-form-item:last-child {
          width: calc(100% - 170px);
          .el-form-item__content {
            width: calc(100% - 35px);
            .el-input {
              width: 100%;
            }
          }
        }
        .el-input {
          width: 123px;
          height: 30px;
          .el-input__inner {
            height: 30px;
          }
          .el-input__icon {
            line-height: 30px;
          }
        }
      }
      .val {
        flex: 1.4;
      }
      ::v-deep .date_style {
        margin-left: 0;
        position: absolute;
        top: 0px;
      }
    }
    .date_style {
      margin-left: -133px !important;
    }
    .bottom_butts {
      display: flex;
      justify-content: flex-end;
    }
  }
}
::v-deep .el-select__tags {
  flex-wrap: unset;
  overflow-x: overlay;
  top: 66%;
  height: 55px;
  .el-tag {
    margin: 11px 7px 10px 5px;
  }
  .el-select__input {
    min-width: 50px !important;
  }
}
::v-deep .el-select-group__title {
  max-width: 200px !important;
}
::v-deep .el-select-dropdown__item {
  max-width: 200px !important;
}
</style>